import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useGetIdentity,
    WithRecord,
    usePermissions,
    TopToolbar,
    ExportButton, CreateButton,
    BooleanField
} from 'react-admin';

const ListActions = () => {
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            { permissions && permissions.includes('ROLE_SUPER_ADMIN') &&
                <CreateButton />
            }
            <ExportButton />
        </TopToolbar>
    );
}

export const AdminUserList = () => {

    return (
        <List actions={ <ListActions /> }
              >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="email" />
                <BooleanField source="active" />
                <EditButton />
            </Datagrid>
        </List>
    );
}