import React, { useState, forwardRef, useContext } from 'react';
import {
    AppBar,
    TitlePortal,
    UserMenu,
    useUserMenu,
    Logout,
    UrlField,
    useGetIdentity,
    useAuthProvider,
    usePermissions,
    useNotify,
    useRefresh,
    LoadingIndicator,
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Button,
    CircularProgress,
} from '@mui/material';
import SettingsIcon from "@mui/icons-material/Settings";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import { usePermissionsManager } from "../utils/permissionsManager";

const Logo = () => {
    return (
        <Box sx={ { paddingTop: '5px' } }>
            <Box
                sx={ { height: '35px' } }
                className="logo"
                component="img"
                src="/images/logo/logo.png"
            />
        </Box>
    );
}

const CustonMenuItem = forwardRef((props, ref) => {

    const { onClose } = useUserMenu();
    return (
        <MenuItem
            onClick={onClose}
            ref={ref}
            // It's important to pass the props to allow Material UI to manage the keyboard navigation
            {...props}
            >
            <ListItemIcon>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                <Link to="/my-profile/">My Profile</Link>
            </ListItemText>
        </MenuItem>
    );
});

export const CustomAppBar = () => {
    const context = useContext(AdminUserContext);
    const [flushingCache, setFlushingCache] = useState(false);
    const auth = useAuthProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    // if (auth) return null;
    const { id, fullName, avatar } = auth.getIdentity();
    const { permissions } = usePermissions();

    function getAvatar() {

        return (
            <Avatar
                sx={{
                    height: 30,
                    width: 30,
                }}
                src={ avatar ?? '' }
            />
        );
    }

    async function handleDefaultCacheFlush() {
        setFlushingCache(true);
        await context.flushDefaultCache()
            .then(response => {
                if (response === true) {
                    notify('Cache Cleared', { type: 'success' });
                } else {
                    notify('Cache Cleared Failed', { type: 'error' });
                }
                setFlushingCache(false);
                refresh();
            });
    }

    async function handleRedisCacheFlush() {
        setFlushingCache(true);
        await context.flushRedisCache()
            .then(response => {
                if (response === true) {
                    notify('Redis Cache Cleared', { type: 'success' });
                } else {
                    notify('Redis Cache Cleared Failed', { type: 'error' });
                }
                setFlushingCache(false);
                refresh();
            });
    }

    async function handleCachePoolFlush() {
        setFlushingCache(true);
        await context.flushPoolsCache()
            .then(response => {
                if (response === true) {
                    notify('Pools Cache Cleared', { type: 'success' });
                } else {
                    notify('Pools Cache Cleared Failed', { type: 'error' });
                }
                setFlushingCache(false);
                refresh();
            });
    }

    return (
        <AppBar
            sx={{
                background: '#24272A',
                '& .RaAppBar-toolbar': { padding: 0 },
            }}
            userMenu={
                <UserMenu
                    icon={ getAvatar() }
                >
                    <CustonMenuItem />
                    <Logout />
                </UserMenu>

            }
        >

            <TitlePortal />
            <Logo />

            <Box flex="1" >
                { usePermissionsManager({  accessToRoles: ['ROLE_SUPER_ADMIN'] }) &&
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained"
                            disabled={ flushingCache }
                            onClick={ handleDefaultCacheFlush }
                                sx={ { width: '130px' }}
                        >
                            { flushingCache &&
                                <CircularProgress size={20}  sx={ { width: '20px', height: '20px' } } />
                            }
                            { !flushingCache &&
                                <span>{"Flush Cache"}</span>
                            }
                        </Button>
                        <Button onClick={ handleRedisCacheFlush }
                            disabled={ flushingCache }
                            variant="contained" sx={ { marginLeft: '10px', width: '172px' } }>
                            { flushingCache &&
                                <CircularProgress size={20} sx={ { width: '20px', height: '20px' } } />
                            }
                            { !flushingCache &&
                                <span>{"Flush Redis Cache"}</span>
                            }
                        </Button>
                        <Button onClick={ handleCachePoolFlush }
                            disabled={ flushingCache }
                            variant="contained" sx={ { marginLeft: '10px', width: '172px' } }>
                            { flushingCache &&
                                <CircularProgress size={20}  sx={ { width: '20px', height: '20px' } } />
                            }
                            { !flushingCache &&
                                <span>{"Flush Cache Pool"}</span>
                            }
                        </Button>
                    </Box>
                }
            </Box>
        </AppBar>
    );
}