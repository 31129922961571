import {Confirm, useNotify, useUpdate} from "react-admin";
import React, {useContext, useState} from "react";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import {Box, Button, CircularProgress} from "@mui/material";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

export const PauseAction = (props) => {
    const notify = useNotify();
    const context = useContext(AdminUserContext);
    const [openPause, setOpenPause] = useState(false);
    const record = props.record;
    const newRecord = structuredClone(record);
    newRecord.state = 'paused';
    const [update] = useUpdate(
        'sales_subscriptions',
        { id: record.id, data: newRecord, previousData: record },
        {
            onError: (error) => {
                notify(error, { type: 'error', autoHideDuration: 5000 });
            }
        }
    );
    const handlePauseClick = () => setOpenPause(true);
    const handlePauseDialogClose = () => setOpenPause(false);
    const handlePauseConfirm = async () => {
        let updated = await context.pauseSubscription(record.stripe_subscription_id);
        if (typeof updated === "string") {
            notify(updated, { type: 'error', autoHideDuration: 5000 });
        }
        if (updated === true) {
            await update();
            notify('Subscription Paused')
        }

        setOpenPause(false);
    };

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
        >
            <Button
                variant="contained"
                color="warning"
                title="Pause Subscription" onClick={ handlePauseClick }
                sx={ { height: '40px' } }
            >
                { !openPause &&
                    <>
                        <span style={{ marginRight: '10px' }}>{ "Pause Subscription" }</span>
                        <PauseCircleIcon/>
                    </>
                }
                { openPause &&
                    <CircularProgress size={20} color="secondary" />
                }
            </Button>
            <Confirm
                isOpen={ openPause }
                title="Pause Subscription"
                content="Are you sure you want to pause this subscription?"
                onConfirm={ handlePauseConfirm }
                onClose={ handlePauseDialogClose }
            />
        </Box>
    );
}
