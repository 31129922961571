import {apiBase, baseUrl} from '../../config.js'
import {useUrlManagerCleanUrl} from "../../utils/UrlManager";

const excludedRows = [
    '@id',
    '@type',
    "@context"
];

const relationalRows = {
    'product': '/api/catalog_product_entities/'
}

function isExcludedRow(key) {
    return excludedRows.includes(key);
}

function cleanParams(params, resource) {
    let returnParams = {};

    for (let param in params) {
        if (isExcludedRow(param)) {
            continue;
        }
        returnParams[param] = params[param];
        if (typeof params[param] == 'string' && params[param].includes(apiBase) && !params[param].includes('HTTP/2')) {
            let cutOut = params[param].substring(0, params[param].lastIndexOf('/') + 1);;
            returnParams[param] = params[param].substring(params[param].lastIndexOf('/') + 1);
            storeResourceData(param, cutOut, resource);
        }
        if (Array.isArray(params[param])) {

            let newParams = [];
            for (let tmpParam in params[param]) {
                if (typeof params[param][tmpParam] == 'string' &&
                    params[param][tmpParam].includes(apiBase) &&
                    !params[param][tmpParam].includes('@type')) {
                    let cutOut = params[param][tmpParam].substring(0, params[param][tmpParam].lastIndexOf('/') + 1);
                    newParams[tmpParam] = params[param][tmpParam].substring(params[param][tmpParam].lastIndexOf('/') + 1);
                    storeResourceData(param, cutOut, resource);
                }
            }
            if (newParams.length > 0) {
                returnParams[param] = newParams;
            }
        }
        try {
            if (JSON.parse(params[param])) {
                returnParams[param] = JSON.parse(params[param]);
            }
        } catch (error) {
        }

        storeAttributeKeys(param, params[param], resource);
    }
    return returnParams;
}

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

function storeAttributeKeys(name, value, resource) {

    let storedKeys = localStorage.getItem('stored_k');
    if (!storedKeys) {
        storedKeys = {};
        storedKeys[resource] = [];
    } else {
        storedKeys = JSON.parse(storedKeys);
    }

    let type = (typeof value);

    if (type === 'string') {
        try {
            let testDate = new Date(value)
            if (testDate.getTime()) {
                type = "date";
            }
        } catch (error) {

        }
        if (parseFloat(value)) {
            type = 'number';
        }
        try {
            if (JSON.parse(value)) {
                type = 'json';
            }
        } catch(error) {

        }
    }

    if (type === 'object') {
        if (Array.isArray(value)) {
            type = 'array';
        }
    }

    let k = { name: name, type: type  };
    let tmp = storedKeys[resource];
    if (!tmp) {
        storedKeys[resource] = [];
        tmp = storedKeys[resource];
    }
    let searchResult = tmp ? tmp.find( o => o.name === name ) : false;
    if (!searchResult) {
        tmp.push(k);
    } else {
        let index = tmp.indexOf(searchResult);
        tmp.splice(index, 1)
        tmp.push(k);
    }
    storedKeys[resource] = tmp;
    let datum = JSON.stringify(storedKeys);
    localStorage.setItem('stored_k', datum);
}

function storeResourceData(key, value, resource) {

    let storedData = localStorage.getItem('stored_data');
    if (!storedData) {
        storedData = {};
        storedData[resource] = [];
    } else {
        storedData = JSON.parse(storedData);
    }
    if (!Array.isArray(storedData[resource])) {
        storedData[resource] = [];
    }
    let d = { key: key, value: value }
    let tmp = storedData[resource];

    let searchResult = tmp.find( o => o.key === key && o.value === value );

    if (!searchResult) {
        tmp.push(d);
    }
    storedData[resource] = tmp;
    let datum = JSON.stringify(storedData);
    localStorage.setItem('stored_data', datum);
}

function restoreParams(params, resource, restoreForUpdate = false) {
    let storedData = localStorage.getItem('stored_data');
    let attributeData = localStorage.getItem('stored_k');
    try {
        attributeData = JSON.parse(attributeData);
    } catch (error) {

    }
    for (let param in params) {
        
        if (storedData) {
            let datum = JSON.parse(storedData);
            if (datum[resource]) {
                let searchResult = datum[resource].find( o => o.key === param);
                if (searchResult && searchResult.value && (
                    // typeof params[param] === "string" &&
                    params[param].indexOf(searchResult.value) === -1
                )

                ) {
                    if (typeof params[param] === "string" &&
                        params[param].indexOf(searchResult.value) === -1) {
                        if (params[param].includes(searchResult.value)) {
                            params[param] = params[param].replace(searchResult.value, '');
                        }
                        params[param] = searchResult.value + params[param];
                    }

                    if (Array.isArray(params[param])) {
                        let newArrayParams = params[param];
                        params[param].forEach((item, index) => {
                            if (typeof item == 'string' && item.includes(searchResult.value)) {
                                item = item.replace(searchResult.value, '');
                            }
                            newArrayParams[index] = searchResult.value + item;
                        })
                        params[param] = newArrayParams;
                    }
                }
            }
        }
        if (restoreForUpdate && param.indexOf('_')) {
            let paramKeys = param.split('_');
            if (paramKeys.length > 0) {
                let newKey = paramKeys[0];
                if (paramKeys.length > 1) {
                    for (let j = 1; j < paramKeys.length; j++) {
                        let p = paramKeys[j];
                        let firstChar = p[0];
                        firstChar = firstChar.toUpperCase();
                        newKey += (firstChar + p.substring(1));
                    }
                }
                params[newKey] = params[param];
            }

        }
        if (attributeData && attributeData[resource]) {
            let isJson = false;
            for(let k = 0; k < attributeData[resource].length; k++) {
                let tmp = attributeData[resource][k];
                if (tmp.name === param && tmp.type === 'json') {
                    isJson = true;
                    break;
                }
                if (tmp.name === param && tmp.type === 'array') {

                    if (!Array.isArray(params[param])) {
                        params[param] = [params[param]];
                    }
                }
            }
            if (isJson) {
                params[param] = JSON.stringify(params[param]);
            }
        }
    }
    return params;
}

function storeSearchParams(params, resource) {
    let searchParams = {};
    searchParams[resource] = [];
    for (let param in params) {
        let p = params[param];
        searchParams[resource].push({
            variable: p.variable,
            property: p.property,
            required: p.required,
        });
    }
    let datum = JSON.stringify(searchParams);
    localStorage.setItem('stored_s', datum);
}

const defaultDataProvider = {

    getList: async (resource, params) => {

        let token = localStorage.getItem('auth');
        if (!token || !params) {
            return Promise.reject('login');
        }
        let url = baseUrl + resource;
        if (params.pagination?.page) {
            if (url.includes('?')) {
                url += '&page=' + params.pagination.page;
            } else {
                url += '?page=' + params.pagination.page;
            }
        }
        if (params.pagination?.perPage) {
            if (url.includes('?')) {
                url += '&itemsPerPage=' + params.pagination.perPage;
            } else {
                url += '?itemsPerPage=' + params.pagination.perPage;
            }
        }
        if (params.filter.q !== undefined) {
            url += '&q=' + params.filter.q;
        } else {
            url = processFilters(url, params);
            url = processSearchFilter(url, params, resource);
        }
        url = proocessSortOrder(url, params);
        url = useUrlManagerCleanUrl({ url: url });

        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                // console.log(response);
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('auth');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {

                let responseData = {
                    data: [],
                    total: 0
                }
                if (Array.isArray(data['hydra:member'])) {
                    if (data['hydra:search']) {
                        storeSearchParams(data['hydra:search']['hydra:mapping'], resource);
                    }

                    let items = data['hydra:member'];
                    for(let i = 0; i < items.length; i++ ) {
                        let cleanedParams = cleanParams(items[i], resource);
                        responseData.data.push(cleanedParams);
                    }
                    responseData.total = data['hydra:totalItems'];
                }
                return responseData;
            })
            .catch(error => {

                return Promise.reject('login' );
            });
    },
    getOne: async (resource, params) => {

        let token = localStorage.getItem('auth');
        if (!token) {
            return Promise.reject('login');
        }
        if(typeof params.id == 'string' && params.id.includes('/api/')) {
            params.id = params.id.substring(params.id.lastIndexOf('/') + 1)
        }
        let url = baseUrl + resource + '/' + params.id;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                // 'Authorization': 'Bearer ' + token
                'Token':  token,
                'Barbershop' : 'admin'
            })
        })
        return fetch(request)
            .then(response => {
                // console.log(response);
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('auth');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: {}
                }

                if (data.id) {
                    responseData.data = cleanParams(data, resource);
                }
                return responseData;
            })
            .catch(error => {
                 console.log(error);
                return Promise.reject('login' );
            })
    },
    getMany: async (resource, params) => {

        let token = localStorage.getItem('auth');
        if (!token) {
            return Promise.reject('login');
        }

        let url = baseUrl + resource;
        if (params.pagination?.page) {
            if (url.includes('?')) {
                url += '&page=' + params.pagination.page;
            } else {
                url += '?page=' + params.pagination.page;
            }
        }
        if (params.pagination?.perPage) {
            if (url.includes('?')) {
                url += '&itemsPerPage=' + params.pagination.perPage;
            } else {
                url += '?itemsPerPage=' + params.pagination.perPage;
            }
        } else {
            if (url.includes('?')) {
                url += '&itemsPerPage=50';
            } else {
                url += '?itemsPerPage=50';
            }
        }
        url = processGetManyFilter(url, params);
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token':  token,
                'Barbershop' : 'admin'
            })
        })
        return fetch(request)
            .then(response => {

                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('auth');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: [],
                    total: 0
                }

                if (Array.isArray(data['hydra:member'])) {
                    let items = data['hydra:member'];
                    for(let i = 0; i < items.length; i++ ) {
                        let cleanedParams = cleanParams(items[i], resource);
                        responseData.data.push(cleanedParams);
                    }
                    responseData.total = data['hydra:totalItems'];
                }
                return responseData;
            })
            .catch(error => {

                return Promise.reject('login' );
            })
    },
    getManyReference: async (resource, params) => {
        let token = localStorage.getItem('auth');
        if (!token) {
            return Promise.reject('login');
        }

        if(params.id.length <= 0) {
            return {
                data: [],
                total: 0
            }
        }

        let url = baseUrl + resource;
        url = processGetManyByReferenceFilter(url, params);
        url = proocessSortOrder(url, params);
        if (params.pagination?.page) {
            if (url.includes('?')) {
                url += '&page=' + params.pagination.page;
            } else {
                url += '?page=' + params.pagination.page;
            }
        }
        if (params.pagination?.perPage) {
            if (url.includes('?')) {
                url += '&itemsPerPage=' + params.pagination.perPage;
            } else {
                url += '?itemsPerPage=' + params.pagination.perPage;
            }
        }
        url = useUrlManagerCleanUrl({ url: url });

        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token':  token,
                'Barbershop' : 'admin'
            })
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('auth');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: [],
                    total: 0
                }
                if (Array.isArray(data['hydra:member'])) {
                    let items = data['hydra:member'];
                    for(let i = 0; i < items.length; i++ ) {
                        let cleanedParams = cleanParams(items[i], resource);
                        responseData.data.push(cleanedParams);
                    }
                    responseData.total = data['hydra:totalItems'];
                }
                return responseData;
            })
            .catch(error => {
                return Promise.reject('login' );
            })
    },
    create: async (resource, params) => {
        let token = localStorage.getItem('auth');
        if (!token) {
            return Promise.reject('login');
        }
        let url = baseUrl + resource;
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify(params['data']),
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('auth');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: {}
                }
                if (data.id) {
                    responseData.data = data;
                }
                return responseData;
            })
            .catch(error => {
                return Promise.reject('login' );
            })
    },
    update: async (resource, params) => {

        let id = params.data.id;
        if (resource === 'sales_subscriptions') {
            let tmpParams = params;
            if (tmpParams.data.stripeFrequency !== tmpParams.previousData.stripeFrequency) {
                params.data = {
                    products: tmpParams.data.products,
                    // stripeFrequency: tmpParams.data.stripeFrequency
                }
                params.previousData = undefined;
            }
        }

        let token = localStorage.getItem('auth');
        if (!token) {
            return Promise.reject( { redirectTo: '/login' });
        }
        let url = baseUrl + resource + '/' + id;
        url = useUrlManagerCleanUrl({ url: url });
        let restoredParams = restoreParams(params['data'], resource, true);

        const request = new Request(url, {
            method: 'PATCH',
            body: JSON.stringify(restoredParams),
            headers: new Headers({
                'Content-Type' : 'application/merge-patch+json',
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {

                if (response.status < 200 || response.status >= 300 &&
                    response.status < 500 && response.status !== 404 && response.status !== 400) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('auth');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {

                if (data?.error && data?.error?.code ===  400) {
                    return Promise.reject( data.error.message )
                }
                let responseData = {
                    data: {}
                }
                if (data.id) {
                    responseData.data = cleanParams(data, resource);
                }
                return responseData;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            })
    },
    updateMany: async (resource, params) => {
        return Promise.reject();
    },
    delete: async (resource, params) => {
        let token = localStorage.getItem('auth');
        if (!token) {
            return Promise.reject( { redirectTo: '/login' });
        }
        let url = baseUrl + resource + '/' + params.id;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                console.log(response);
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('token');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {
                return data;
            })
            .catch( error => {
                return Promise.reject();
            });
    },
    deleteMany: async (resource, params) => {
        return Promise.reject();
    },
    search: (value) => {
        let token = localStorage.getItem('token');
        let url = '';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('token');
                    return Promise.reject( { redirectTo: '/login' });
                }
                return response.json();
            })
            .then(data => {
                // console.log(data);
                if (data) {
                }
            })
            .catch( error => {
                console.log(error);
                return Promise.reject();
            });
    }
}

const processGetManyFilter = (url, params, resource) => {
    // debugger;
    let string = '';
    for (let i = 0; i < params['ids'].length; i++) {

        let param = params['ids'][i];
        if (param.length <= 0) {
            continue;
        }
        if (typeof parseInt(param) === "number") {
            if (string === '') {
                string += 'id[]=' + param;
            } else {
                string += '&id[]=' + param;
            }
        } else {
            let temp = param.replace('/' + resource + '/', '');
            let values = temp.split('/');
            if (values.length === 2) {
                if (string === '') {
                    string += values[0] + '[]=' + values[1];
                } else {
                    string += '&' + values[0] + '[]=' + values[1];
                }
            }
        }
    }
    if (string.length > 0) {
        if (url.indexOf('?') > -1) {
            url += '&' + string;
        } else {
            url += '?' + string;
        }
    }
    return url;
}

const proocessSortOrder = (url, params) => {
    if (typeof params.sort !== "undefined") {
        if (url.indexOf('?')) {
            url += '&sortOrder[' + params.sort.field + ']=' + params.sort.order ;
        } else {
            url += '?sortOrder[' + params.sort.field + ']=' + params.sort.order ;
        }
    }

    return url;
}

const processFilters = (url, params) => {
    let string = '';
    if (typeof params.filter !== "undefined") {
        for (const key in params.filter) {
            if (params.filter.hasOwnProperty(key)) {
                let keyValue = key;
                if (keyValue.endsWith('eq')) {
                    keyValue = keyValue.replace('_eq', '');
                }
                if (typeof params.filter[key] == 'object') {
                    for (const value in params.filter[key]) {
                        let val = params.filter[key][value];
                        if (val === true) {
                            val = 1;
                        } else if (val === false) {
                            val = 0;
                        }
                        if (string === '') {
                            string += (keyValue.toLowerCase() + '.' + value  ) + '=' + val;
                        } else {
                            string += '&' + (keyValue.toLowerCase() + '.' + value) + '=' + val;
                        }
                    }
                } else {
                    let value = params.filter[key];
                    if (value === true) {
                        value = 1;
                    } else if (value === false) {
                        value = 0;
                    }
                    if (string === '') {
                        string += keyValue.toLowerCase() + '=' +value;
                    } else {
                        string += '&' + keyValue.toLowerCase() + '=' + value;
                    }
                }
            }
        }
        if (string.length > 0) {
            if (url.indexOf('?')) {
                url += '&' + string;
            } else {
                url += '?' + string;
            }
        }
    }
    return url;
};

const processSearchFilter = (url, params, resource) => {
    if (!params.filter?.search_input) {
        return url;
    }

    let searchParam = params.filter.search_input;
    delete params.filter.search_input;

    let string = '';
    let attributes = localStorage.getItem('stored_s');
    let attributeData = localStorage.getItem('stored_k');
    if (attributes) {
        attributes = JSON.parse(attributes);
        if (attributes[resource] && attributeData) {
            attributeData = JSON.parse(attributeData);
            if (attributeData[resource]) {
                // console.log(params);

                for(let j = 0; j < attributes[resource].length; j++) {
                    let param = attributes[resource][j];
                    if (param.variable.includes('[')) {
                        continue;
                    }
                    let attributeDatum = "string";
                    for(let k = 0; k < attributeData[resource].length; k++) {
                        let tmp = attributeData[resource][k];
                        if (tmp.name == param.property) {
                            attributeDatum = tmp.type;
                            break;
                        }
                    }
                    if (url.indexOf('?') === -1) {
                        string += '?' + param.variable + '=' + searchParam;
                    } else {
                        string += '&' + param.variable + '=' + searchParam;
                    }
                }
            }
        }
    }
    return encodeURI(url + string);
}

const processGlobalSearchFilter = (url, resource, searchValue) => {
    // debugger;
    let string = '';
    let attributes = localStorage.getItem('stored_s');
    let attributeData = localStorage.getItem('stored_k');
    if (attributes) {
        attributes = JSON.parse(attributes);
        if (attributes[resource] && attributeData) {
            attributeData = JSON.parse(attributeData);
            if (attributeData[resource]) {
                // console.log(params);

                for(let j = 0; j < attributes[resource].length; j++) {
                    let param = attributes[resource][j];
                    if (param.variable.includes('[')) {
                        continue;
                    }

                    if (url.indexOf('?') === -1) {
                        string += '?' + param.variable + '=' + searchValue;
                    } else {
                        string += '&' + param.variable + '=' + searchValue;
                    }

                }
            }
        }
    }
    return encodeURI(url + string);
}

const processGetManyByReferenceFilter = (url, params) => {
   // debugger;
    let string = '';
    if (Array.isArray(params['id'])) {
        for (let i = 0; i < params['id'].length; i++) {

            let param = params['id'][i];
            if (typeof parseInt(param) === "number") {
                if (string === '') {
                    string += 'id[]=' + param;
                } else {
                    string += '&id[]=' + param;
                }
            }
        }
    }

    if (typeof params['id'] == 'string' || typeof params['id'] == 'number') {
        if (string === '') {
            string += 'id=' + params['id'];
        } else {
            string += '&id=' + params['id'];
        }
    }


    if (string.length == 0 && params.target == 'id') {
        url += '&id='
    }

    if (string.length > 0) {
        if (url.indexOf('?') > -1) {
            url += '&' + string;
        } else {
            url += '?' + string;
        }
    }

    if (params.filter) {
        let keys = Object.keys(params.filter);
        for (let i = 0; i < keys.length; i++) {
            if (url.indexOf('?') > -1) {
                url += '&' + keys[i] + '=' + params.filter[keys[i]];
            } else {
                url += '?' + keys[i] + '=' + params.filter[keys[i]];
            }
        }


    }
    return url;
}

export default defaultDataProvider;