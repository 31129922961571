import {feBase} from "../../config";
import React, {useContext, useEffect, useState, useRef } from "react";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {
    AutocompleteArrayInput,
    BooleanField,
    Confirm,
    DateField, DateInput, FormTab,
    ReferenceField,
    SaveButton,
    SelectField,
    SelectInput,
    SimpleForm,
    TextField,
    Toolbar,
    useDelete,
    usePermissions,
    useRecordContext,
    useUpdate,
    WithRecord,
    useNotify,
    useRefresh,
    Show
} from 'react-admin';
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    LinearProgress,
    Grid,
    Stack,
    Tab,
    Tabs,
    Link,
    Dialog,
    DialogContent,
    DialogTitle
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {
    useGetCouponOptions,
    useGetSubscriptionFrequency,
    useSubscriptionCustomer,
    useGetStripeMode
} from "../components/hooks/EditSubscriptionModalHooks";
import { useStripeDataSync } from "./components/subscription/dialog/tabs/hooks/DeveloperActionHooks";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import { usePermissionsManager } from "../../utils/permissionsManager";

//Tabs
import { GeneralTab } from './components/subscription/dialog/tabs/GeneralTab';
import { ProductsTab } from './components/subscription/dialog/tabs/ProductsTab';
import { SubscriptionTab } from './components/subscription/dialog/tabs/SubscriptionTab';
import { PauseCancelReasonsTab } from "./components/subscription/dialog/tabs/PauseCancelReasonsTab";
import { SubscriptionActivityTab } from "./components/subscription/dialog/tabs/SubscriptionActivityTab";
import { SubscriptionModalUpdateControlTab } from "./components/subscription/dialog/tabs/SubscriptionModalUpdateControlTab";
import { OrderHistoryTab } from "./components/subscription/dialog/tabs/OrderHistoryTab";


/**
 * Control to load and append customer name to main subscription control modal
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const EditSubscriptionTitle = (props) => {
    const [customerName, setCustomerName] = useState("");
    // const record = useRecordContext();
    const record = props.record;
    useEffect( () => {
        const loadCustomerName = async () => {
            if (!record) {
                return;
            }
            return await useSubscriptionCustomer({ id: record.id })
        }
        loadCustomerName()
            .then(result => {
                if (result && result.status === 'success') {
                    setCustomerName(result.data);
                }

            })
    }, [])

    if (!record) return null;
    return (
        <span>
            <span>{ "Subscription (" + record.id + ") - "}</span>
            <span> { customerName.name } </span>
        </span>
    )
}

/**
 * Main components for the subscription control modal
 * @returns {Element}
 * @constructor
 */
export const SubscriptionMainDialogEdit = (props) => {
    const record = props.record;
    if (!record) return null;
    console.log(record);
    const context = useContext(AdminUserContext);
    const [loading, setLoading] = useState(true);
    const [frequencyData, setFrequencyData] = useState({});
    const selectedFrequency = useRef([]);
    const [couponData, setCouponData] = useState([]);

    const [update, { isPending, error }] = useUpdate();
    const [value, setValue] = useState(0);
    const [recordId, setRecordId] = useState(record.id);
    const [syncing, setSyncing] = useState(true);
    const notify = useNotify();
    const refresh = useRefresh();
    const [maxWidth, setMaxWidth] = useState('lg');
    const hasAccess = usePermissionsManager( { accessToRoles: ['ROLE_SUPER_ADMIN'] } );

    const loadFrequencies = async () => {
        if (!record) {
            return;
        }
        await useGetSubscriptionFrequency({ id: record.id })
            .then(result => {
                if (result && result.status === 'success') {
                    setFrequencyData(result.data)
                }
            })
    }

    const loadCoupons = async () => {
        await useGetCouponOptions()
            .then(data => {
                if(data.status === 'success') {
                    setCouponData(data.data)
                }
            });
    }

    useEffect(() => {
        ( async () => {
                if (props.openDialog === true) {
                    let self = this;
                    let data = {
                        all: true,
                        cycle: false,
                        status: false,
                        frequency: false,
                        products: false,
                        discount: false,
                        scheduler: false,
                        subscription: record.id
                    }
                    const response = useStripeDataSync( { data: data } )
                        .then(response => {
                            if (response.error) {
                                notify('Failed to sync subscription with Stripe');
                            } else {
                                refresh();
                            }
                            setSyncing(false);
                        });

                    if (loading && record) {
                        await loadFrequencies();
                        await loadCoupons();
                        setLoading(false);
                    }
                }
            }
        )();
    }, [props.openDialog]);

    function handleRadioSelect(event) {
        let newFrequency = event.target.value;
        let newFrequencyProducts = [];
        if (frequencyData[newFrequency]) {
            for(const freq of frequencyData[newFrequency]) {
                newFrequencyProducts.push(freq.id);
            }
        }
        selectedFrequency.current = newFrequencyProducts;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function allyProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    function Panel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={ value !== index }
                id={ `setting-tabpanel-${ index }` }
                className={ `setting-tabpanel-${ index }` }
                aria-labelledby={ `simple-tab-${ index }` }
                { ...other } >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        { children }
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <Dialog
            open={ props.openDialog }
            onClose={ props.handleDialogClose }
            fullWidth={true}
            maxWidth={ 'xl' }
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" >
                    <EditSubscriptionTitle record={ record } />
                    <IconButton onClick={ props.handleDialogClose }>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                { syncing &&
                    <Box display="flex"
                         flexDirection="column"
                         justifyContent="center"
                         alignItems="center"
                         sx={ { width: '100%', height: '300px' } }
                    >
                        <span style={ { marginBottom: '15px' } } >{ "Synchronizing Subscription" }</span>
                        <LinearProgress sx={ { width: '90%' } } />
                    </Box>
                }

                { !syncing &&

                    <Show
                        id={ record.id }
                        resource="sales_subscriptions"
                        title=" "
                        redirect={ false }
                        actions={ false }
                    >
                        <Box className="tab-tiles"   sx={ { width: '100%' } }>
                            <Tabs value={ value } onChange={ handleChange } aria-label="company tabs">
                                <Tab label="General" { ...allyProps(0) } />
                                <Tab label="Products" { ...allyProps(1) } />
                                <Tab label="Subscription" { ...allyProps(2) } />
                                <Tab label="History" { ...allyProps(3) } />
                                <Tab label="Pause/Cancel Reasons" { ...allyProps(4) } />
                                <Tab label="Order History" { ...allyProps(5) } />
                                { hasAccess &&
                                    <Tab label="Developer" { ...allyProps(6) } />
                                }
                            </Tabs>
                        </Box>
                        <Panel value={ value } index={ 0 }>
                            { /* GENERAL TAB */ }
                            <GeneralTab />
                        </Panel>
                        { /* END OF GENERAL TAB */ }

                        { /* PRODUCT TAB */ }
                        <Panel value={ value } index={ 1 }>
                            <ProductsTab
                                setLoading={ setLoading }
                                setRecordId={ setRecordId }
                                loadFrequencies={ loadFrequencies }
                                loadCoupons={ loadCoupons }
                                setFrequencyData={ setFrequencyData }
                                setCouponData={ setCouponData }
                                plans={ props.plans }
                                openDialog={ props.openDialog }
                                record={ record }
                            />
                        </Panel>
                        { /* END OF PRODUCT TAB */ }

                        { /* SUBSCRIPTION TAB */ }
                        <Panel value={ value } index={ 2 }>
                            <SubscriptionTab
                                handleRadioSelect={ handleRadioSelect }
                                frequencyData={ frequencyData }
                                couponData={ couponData }
                                selectedFrequency={ selectedFrequency }
                                loadFrequencies={ loadFrequencies }
                                loadCoupons={ loadCoupons }
                            />
                        </Panel>
                        { /* END OF SUBSCRIPTION TAB */ }

                        { /* ACTIVITY TAB */ }
                        <Panel value={ value } index={ 3 }>
                            <SubscriptionActivityTab />
                        </Panel>
                        { /* END OF ACTIVITY TAB */ }

                        { /* PAUSE CANCEL REASONS TAB */ }
                        <Panel value={ value } index={ 4 }>
                            <PauseCancelReasonsTab />
                        </Panel>
                        { /* END OF PAUSE CANCEL REASONS TAB */ }

                        <Panel value={ value } index={ 5 }>
                            <OrderHistoryTab />
                        </Panel>

                        { /* DEVELOPER TAB */ }
                        { hasAccess &&
                            <Panel value={ value } index={ 6 }>
                                <SubscriptionModalUpdateControlTab />
                            </Panel>
                        }
                        { /* END OF DEVELOPER TAB */ }
                    </Show>
                }
            </DialogContent>
        </Dialog>
    );
}