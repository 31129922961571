import React, { useContext, useEffect, useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    WithRecord,
    ReferenceManyField,
    Datagrid,
    TextField,
    ReferenceField,
    DateInput,
    List,
    Show,
    DateField,
    Labeled,
    SearchInput,
    SimpleShowLayout,
    SelectField,
    WithListContext,
    useRefresh,
    useUpdate,
    useNotify,
    ShowButton,
    SelectInput,
    Pagination, BooleanField, AutocompleteArrayInput, Toolbar, SaveButton, Confirm, useDelete
} from 'react-admin';
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    LinearProgress,
    Grid,
    Stack,
    Tab,
    Tabs,
    Link,
    Dialog,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useFormContext } from "react-hook-form"
import { PauseAction } from "../../../../../../Defaults/Subscriptions/PauseAction";
import { ResumeAction } from "../../../../../../Defaults/Subscriptions/ResumeAction";
import { CancelAction } from "../../../../../../Defaults/Subscriptions/CancelAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AdminUserContext } from "../../../../../../contexts/AdminUserContextProvider";

const fieldItemStyles = {
    display: "flex",
    justifyContent: "space-between",
};

/**
 * Handle change to coupons
 * @param props
 * @returns {Element}
 * @constructor
 */
const CouponSelector = (props) => {
    const couponData = props.couponData;
    const { setValue} = useFormContext();

    const handleCouponChange = (value, records) => {
        //restrict the coupons to only the first one selected
        let vals = [];
        if (value.length > 1) {
            vals.push(value[0])
        } else {
            vals = value;
        }
        setValue(
            'coupons',
            vals
        );
    }
    return <AutocompleteArrayInput
        name="coupons"
        label={ false }
        source="coupons"
        choices={ couponData }
        onChange={ handleCouponChange } />
}

/**
 * Control for modal forms
 * @returns {Element}
 * @constructor
 */
const EditToolBar = (props) => {
    const context = useContext(AdminUserContext);
    const [open, setOpen] = useState(false);
    const record = useRecordContext();
    const [deleteOne] = useDelete();



    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = async () => {
        let updated = await context.cancelSubscription(record.stripe_subscription_id);
        if (updated) {
            await deleteOne('sales_subscriptions', {
                id: record.id,
                previousData: record
            });
        }
        setOpen(false);
    };
    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
                { !props.processing &&
                    <SaveButton label="Save" />
                }
                { props.processing &&
                    <Box display="flex">
                        <CircularProgress size={20} color="secondary" />
                        <span style={ { marginLeft: '10px' } }>{ "Please wait...." }</span>
                    </Box>
                }
            </Box>

            <Box>
                { !props.processing &&
                    <>
                        <Button
                            title="Delete"
                            onClick={ handleClick }
                            sx={ {
                                color: 'red',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            } }>
                            <>
                                { !open &&
                                    <>
                                        <DeleteForeverIcon  sx={ { fill: 'red', marginTop: '5px', marginRight: '5px' } } /> <span>{ "Delete" }</span>
                                    </>
                                }
                                { open &&
                                    <CircularProgress size={20} color="secondary" />
                                }
                            </>
                        </Button>
                        <Confirm isOpen={ open }
                                 title="Confirm Delete"
                                 content="Deleting a subscription will also cancel if applicable, are you sure?"
                                 onConfirm={ handleConfirm }
                                 onClose={ handleDialogClose }
                        />
                    </>
                }
            </Box>
        </Toolbar>
    );
}

export const SubscriptionTab = (props) => {
    const notify = useNotify();
    const [update, { isPending, error }] = useUpdate();
    const record = useRecordContext();
    const [processing, setProcessing] = useState(false);

    let choices = Object.keys(props.frequencyData).map((item) => (
        { id: item, name: item }
    ));

    async function handleSubmit(data) {
        setProcessing(true);
        if (props.selectedFrequency.current.length > 0) {
            data.products = props.selectedFrequency.current;
        }

        let updated = await update(
            'sales_subscriptions',
            { id: data.id, data: data, previousData: record },
            {
                onError: data => {
                    notify(data, { type: 'error', autoHideDuration: 5000 });
                    setProcessing(false);
                },
                onSuccess: async data => {
                    props.loadFrequencies();
                    props.loadCoupons();
                    setProcessing(false);
                }
            }
        )
        //
        // setTimeout(function() {
        //     loadFrequencies(recordId)
        //         .then(result => {
        //             if (result && result.status === 'success') {
        //                 setFrequencyData(result.data)
        //             }
        //         })
        //     loadCoupons()
        //         .then(data => {
        //             if(data.status === 'success') {
        //                 setCouponData(data.data)
        //             }
        //         });
        // },2000);
    }

    return (
        <SimpleForm
            toolbar={  <EditToolBar processing={ processing } />  }
            onSubmit={ handleSubmit }>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack spacing={2}>
                        <Box sx={ {
                            ...fieldItemStyles,
                            width: '100%'
                        }}>
                            <span style={ { width: '100%' } } >{ "Frequency:" }</span>
                            <SelectInput
                                name="stripeFrequency"
                                source="stripeFrequency"
                                choices={choices}
                                label={ false }
                                onChange={ (e) => props.handleRadioSelect(e) }
                            />

                        </Box>
                        <Box sx={ {
                            ...fieldItemStyles,
                            width: '100%'
                        }}>
                            <span>{ "Discount:" }</span>
                            <Box display="flex" flexDirection="column">
                                <CouponSelector couponData={ props.couponData } />
                                <span style={ { fontSize: '14px', color: 'red' } } >
                                            {"Note: Only one coupon code can be applied at a time."}
                                        </span>
                            </Box>
                        </Box>
                        <Box sx={ {
                            ...fieldItemStyles,
                            width: '100%'
                        }}>
                            <span>{ "Next Cycle Date:" }</span>
                            <DateInput
                                source="next_cycle_date"
                                name="next_cycle_date"
                                label={ false } />
                        </Box>

                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={2}>
                        <Box sx={ {
                            ...fieldItemStyles
                        } }>
                            <WithRecord render={
                                record => {
                                    return (
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            sx={ { width: '100%'  } }
                                        >
                                            <Stack spacing={2}>
                                                { (record.state === 'active' || record.state === 'past_due') &&
                                                    <PauseAction
                                                        record={ record }
                                                        fullButton={true}/>
                                                }
                                                { record.state === 'paused' &&
                                                    <ResumeAction
                                                        record={ record }
                                                        fullButton={true} />
                                                }
                                                <CancelAction
                                                    record={ record }
                                                    fullButton={true} />

                                            </Stack>
                                        </Box>
                                    );
                                }
                            } />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </SimpleForm>

    );
}