import React, { useState } from 'react';
import {
    BooleanField,
    Confirm,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    Toolbar,
    useDelete,
    useRecordContext,
    List,
    Datagrid,
    TextInput,
    required, DateField, SearchInput, EditButton
} from 'react-admin';
import { SettingsEditDialog } from "../Edits/Dialog/SettingsEditDialog";


const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];

export const SurveyArchiveList = () => {

    return (
        <List
            filters={ customerFilters }
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid  bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="email_address" />
                <TextField source="reference_id" />
                <TextField source="store_view" />
                <EditButton />
            </Datagrid>
        </List>
    );
}