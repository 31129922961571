import {hostUrl} from "../../../config";
import {useUrlManagerCleanUrl} from "../../../utils/UrlManager";

/**
 * Load the frequency options for the subscription
 * @param id
 * @returns {Promise<any>}
 */
export const useGetSubscriptionFrequency = async ({ id }) => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/subscription/frequency/lookup';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    });
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

/**
 * Load all coupon details for searchable input
 * @returns {Promise<any>}
 */
export const useGetCouponOptions = async () => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/subscription/coupon/lookup';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "GET",
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    })
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

/**
 * Load subscription customer name and ID for modal title and link
 * @param id
 * @returns {Promise<any>}
 */
export const useSubscriptionCustomer = async ({ id }) => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/subscription/customer/lookup';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    });
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const useGetProductPlanOptions = async () => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/subscription/product/loader';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "GET",
        // body: JSON.stringify({ id: id }),
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    });
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const useGetStripeMode = async () => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/settings/stripe/mode';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "GET",
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    });
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}