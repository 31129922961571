import {feBase} from "../../config";
import React, {useContext, useEffect, useState} from "react";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {
    AutocompleteArrayInput,
    BooleanField,
    Confirm,
    DateField, DateInput, FormTab,
    ReferenceField,
    SaveButton,
    SelectField,
    SelectInput,
    SimpleForm,
    TextField,
    Toolbar,
    useDelete,
    usePermissions,
    useRecordContext,
    useUpdate,
    WithRecord,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    Grid,
    Stack,
    Tab,
    Tabs,
    Link
} from '@mui/material';
import {
    useGetCouponOptions,
    useGetSubscriptionFrequency,
    useSubscriptionCustomer,
    useGetStripeMode
} from "../components/hooks/EditSubscriptionModalHooks";
import { useStripeDataSync } from "../../Developer/hooks/DeveloperActionHooks";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import {PauseAction} from "../../Defaults/Subscriptions/PauseAction";
import {CancelAction} from "../../Defaults/Subscriptions/CancelAction";
import {ResumeAction} from "../../Defaults/Subscriptions/ResumeAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {SubscriptionActivity} from "../components/SubscriptionActivity";
import { SubscriptionModalUpdateControl } from "../../Developer/SubscriptionModalUpdateControl";
import { useFormContext } from "react-hook-form"
import { ProductControl } from "./components/ProductControl";


const fieldItemStyles = {
    display: "flex",
    justifyContent: "space-between",
};

/**
 * Control for modal forms
 * @returns {Element}
 * @constructor
 */
const EditToolBar = () => {
    const context = useContext(AdminUserContext);
    const [open, setOpen] = useState(false);
    const record = useRecordContext();
    const [deleteOne] = useDelete();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = async () => {
        let updated = await context.cancelSubscription(record.stripe_subscription_id);
        if (updated) {
            await deleteOne('sales_subscriptions', {
                id: record.id,
                previousData: record
            });
        }
        setOpen(false);
    };
    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton label="Save" />
            <>
                <Button
                    title="Delete"
                    onClick={ handleClick }
                    sx={ {
                        color: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    } }>
                    <>
                        { !open &&
                            <>
                                <DeleteForeverIcon  sx={ { fill: 'red', marginTop: '5px', marginRight: '5px' } } /> <span>{ "Delete" }</span>
                            </>
                        }
                        { open &&
                            <CircularProgress size={20} color="secondary" />
                        }
                    </>
                </Button>
                <Confirm isOpen={open}
                         title="Confirm Delete"
                         content="Deleting a subscription will also cancel if applicable, are you sure?"
                         onConfirm={ handleConfirm }
                         onClose={ handleDialogClose }
                />
            </>
        </Toolbar>
    );
}

/**
 * Handle change to coupons
 * @param props
 * @returns {Element}
 * @constructor
 */
const CouponSelector = (props) => {
    const couponData = props.couponData;
    const { setValue} = useFormContext();

    const handleCouponChange = (value, records) => {
        //restrict the coupons to only the first one selected
        let vals = [];
        if (value.length > 1) {
            vals.push(value[0])
        } else {
            vals = value;
        }
        setValue(
            'coupons',
            vals
        );
    }
    return <AutocompleteArrayInput
        name="coupons"
        label={ false }
        source="coupons"
        choices={couponData}
        onChange={handleCouponChange} />
}

/**
 * Generate Stripe dashboard link based on stripe_mode setting
 * @param props
 * @returns {Element}
 * @constructor
 */
const SubscriptionLink = (props) => {
    const record = props.record;
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("test");
    useEffect(() => {
        ( async function () {
                let modeLoad = await useGetStripeMode();
                if (modeLoad?.success) {
                    setMode(modeLoad.mode);
                    setLoading(false);
                }
                if (modeLoad?.error) {
                    setMode('error')
                }
            }
        )();
    }, []);

    if (loading) {
        return <LinearProgress sx={ { width: '50px' } } />;
    }

    if (mode === 'error') {
        return <span>'Failed to generate Stripe link</span>
    }

    return (
        <Link href={ "https://dashboard.stripe.com/" + mode + "/subscriptions/" + record.stripeSubscriptionId } target="_blank" >{ record.stripeSubscriptionId }</Link>
    );
}

/**
 * Control to load and append customer name to main subscription control modal
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const EditSubscriptionTitle = () => {
    const [customerName, setCustomerName] = useState("");
    const record = useRecordContext();
    useEffect( () => {
        const loadCustomerName = async () => {
            if (!record) {
                return;
            }
            return await useSubscriptionCustomer({ id: record.id })
        }
        loadCustomerName()
            .then(result => {
                if (result && result.status === 'success') {
                    setCustomerName(result.data);
                }

            })
    }, [])

    if (!record) return null;
    return (
        <span>
            <span>{ "Subscription (" + record.id + ") - "}</span>
            <span> { customerName.name } </span>
        </span>
    )
}

/**
 * Main components for the subscription control modal
 * @returns {Element}
 * @constructor
 */
export const SubscriptionMainDialogEdit = (props) => {
    const context = useContext(AdminUserContext);
    const [loading, setLoading] = useState(true);
    const [frequencyData, setFrequencyData] = useState({});
    const [selectedFrequency, setSelectedFrequency] = useState([]);
    const [couponData, setCouponData] = useState([]);
    const record = useRecordContext();
    const [update, { isPending, error }] = useUpdate();
    const [value, setValue] = useState(0);
    const { permissions } = usePermissions();
    const [recordId, setRecordId] = useState(record.id);
    const [syncing, setSyncing] = useState(true);
    const notify = useNotify();
    const refresh = useRefresh();

    const loadFrequencies = async (recordId) => {
        return await useGetSubscriptionFrequency({ id: recordId })
    }

    const loadCoupons = async () => {
        return await useGetCouponOptions();
    }

    useEffect(() => {
        let self = this;
        let data = {
            all: true,
            cycle: false,
            status: false,
            frequency: false,
            products: false,
            discount: false,
            scheduler: false,
            subscription: record.id
        }
        const response = useStripeDataSync( { data: data } );
        if (response.error) {
            notify('Failed to sync subscription with Stripe');
        } else {
            refresh();
        }
        setSyncing(false);
        if (loading && record) {
            loadFrequencies(recordId)
                .then(result => {
                    if (result && result.status === 'success') {
                        setFrequencyData(result.data)
                    }
                })
            loadCoupons()
                .then(data => {
                    if(data.status === 'success') {
                        setCouponData(data.data)
                    }
                });
            setLoading(false);
        }
    }, []);

    function handleRadioSelect(event) {
        let newFrequency = event.target.value;

        let newFrequencyProducts = [];
        if (frequencyData[newFrequency]) {
            for(const freq of frequencyData[newFrequency]) {
                newFrequencyProducts.push(freq.id);
            }
        }
        setSelectedFrequency(newFrequencyProducts);
    }

    function getCurrencySymbol(id) {
        let symbol = '£';
        context.currencies.forEach((item) => {
            if (item.id == id) {
                symbol = item.symbol;
            }
        })
        return symbol;
    }

    const handleSubmit = async (data) => {
        if (selectedFrequency.length > 0) {
            data.products = selectedFrequency;
        }
        await update(
            'sales_subscriptions',
            { id: data.id, data: data, previousData: record }
        )
        setTimeout(function() {
            loadFrequencies(recordId)
                .then(result => {
                    if (result && result.status === 'success') {
                        setFrequencyData(result.data)
                    }
                })
            loadCoupons()
                .then(data => {
                    if(data.status === 'success') {
                        setCouponData(data.data)
                    }
                });
        },2000);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function allyProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    let choices = Object.keys(frequencyData).map((item) => (
        { id: item, name: item }
    ));

    function Panel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={ value !== index }
                id={ `setting-tabpanel-${ index }` }
                className={ `setting-tabpanel-${ index }` }
                aria-labelledby={ `simple-tab-${ index }` }
                { ...other } >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        { children }
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <EditInDialogButton
            title={ <EditSubscriptionTitle /> }
            fullWidth={true}
            maxWidth="xl"
            label={"View"}
            sx={ {
                '& .MuiDialog-paper': {
                    height: '90%'
                },
                '& form': {
                    height: '83%',
                },
                '& .MuiCardContent-root': {
                    height: '100%'
                }
            } } >
            { syncing &&
                <Box display="flex"
                     flexDirection="column"
                     justifyContent="center"
                     alignItems="center"
                     sx={ { width: '100%' } }
                >
                    <span style={ { marginBottom: '15px' } } >{ "Fetching Subscription" }</span>
                    <LinearProgress sx={ { width: '90%' } } />
                </Box>

            }
            { !syncing &&
                <SimpleForm
                    toolbar={  ( value == 2 ? <EditToolBar/> : null )  }
                    onSubmit={handleSubmit}>
                    <Box  sx={ { width: '100%' } }>
                        <Box className="tab-tiles"   sx={ { width: '100%' } }>
                            <Tabs value={ value } onChange={ handleChange } aria-label="company tabs">
                                <Tab label="General" { ...allyProps(0) } />
                                <Tab label="Products" { ...allyProps(1) } />
                                <Tab label="Subscription" { ...allyProps(2) } />
                                <Tab label="History" { ...allyProps(3) } />
                                { (permissions && permissions.includes('ROLE_SUPER_ADMIN')) &&
                                    <Tab label="Developer" { ...allyProps(4) } />
                                }
                            </Tabs>
                        </Box>
                        <Box sx={ { width: '100%' } }>
                            <Panel value={ value } index={ 0 }>
                                { /* GENERAL TAB */ }
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <Box sx={ fieldItemStyles }>
                                                <span>Id:</span>
                                                <TextField label="Id" source="id" />
                                            </Box>
                                            <Box display="flex"
                                                 justifyContent="space-between"
                                            >
                                                <span>{ "Customer:" }</span>
                                                <ReferenceField
                                                    label="Customer"
                                                    source="customer"
                                                    reference="customer_entities"
                                                    link={ false }
                                                >
                                                    <TextField source="full_name" />
                                                </ReferenceField>
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Next Cycle Date:" }</span>
                                                <DateField label="" name="next_cycle_date" source="next_cycle_date" />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Shipping Method:" }</span>
                                                <ReferenceField label="Shipping Method"
                                                                source="shipping_method"
                                                                reference="sales_shipping_methods"
                                                                link={false}
                                                >
                                                    <TextField source="shipping_method_name" />
                                                </ReferenceField>
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Status:" }</span>
                                                <WithRecord label="" render={
                                                    record => {
                                                        let colour = '#000';
                                                        if (record.state === 'paused') {
                                                            colour = '#f36907';
                                                        }
                                                        if (record.state === 'active') {
                                                            colour = '#419d06';
                                                        }

                                                        if (record.resumes && record.state == 'paused') {
                                                            return <span style={ {
                                                                fontsize: '20px',
                                                                fontWeight: 'bold',
                                                                background: '#74ee15',
                                                                padding: '10px',
                                                                color: '#000',
                                                                borderRadius: '10px'
                                                            } } >{ "Processing...." }</span>
                                                        }

                                                        return (
                                                            <TextField label="Subscription Status"
                                                                       source="state"
                                                                       sx={ {
                                                                           textTransform: 'capitalize',
                                                                           color: colour
                                                                       } }/>
                                                        );
                                                    }
                                                } />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Is Trial:" }</span>
                                                <BooleanField source="is_trial" name='is_trial' />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Currency:" }</span>
                                                <SelectField label=""
                                                             name="currency"
                                                             source="currency"
                                                             choices={ context.currencies } />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Upcoming Invoice amount:" }</span>
                                                <WithRecord label="" render={
                                                    record => {
                                                        return (
                                                            <span>{ getCurrencySymbol(record.currency) + parseFloat(record.upcomingPrice).toFixed(2) }</span>
                                                        );
                                                    }
                                                } />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "One Off Total:" }</span>
                                                <WithRecord label="One Off Total" render={
                                                    record => (
                                                        <span>{ getCurrencySymbol(record.currency) + parseFloat(record.one_off_total).toFixed(2) }</span>
                                                    )
                                                } />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Total Order Amount:" }</span>
                                                <WithRecord label="Total Order Value" render={
                                                    record => (
                                                        <span>{ getCurrencySymbol(record.currency) + parseFloat(record.total_order_value).toFixed(2) }</span>
                                                    )
                                                } />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Subscription Frequency:" }</span>
                                                <TextField source="stripeFrequency" name="stripeFrequency"/>
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Created:" }</span>
                                                <DateField source="created_at" />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Updated:" }</span>
                                                <DateField source="updated_at" />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Scheduler Paused:" }</span>
                                                <BooleanField source="schedularPaused" />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Subscription Resumes:" }</span>
                                                <BooleanField source="resumes"
                                                              sx={ {
                                                                  background: '#74ee15',
                                                                  padding: '5px',
                                                                  borderRadius: '100%'
                                                              } }
                                                />
                                            </Box>
                                            <Box sx={ fieldItemStyles }>
                                                <span>{ "Subscription Link:" }</span>
                                                <WithRecord label="" render={
                                                    record => {
                                                        return (
                                                            <SubscriptionLink record={record} />
                                                        );
                                                    }
                                                } />

                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Panel>
                            { /* END OF GENERAL TAB */ }

                            { /* PRODUCT TAB */ }
                            <Panel value={ value } index={ 1 }>
                                <WithRecord label=" " render={
                                    record => {
                                        return(
                                            <ProductControl
                                                record={record}
                                                setLoading={setLoading}
                                                setRecordId={setRecordId}
                                                loadFrequencies={loadFrequencies}
                                                loadCoupons={loadCoupons}
                                                setFrequencyData={setFrequencyData}
                                                setCouponData={setCouponData}
                                                plans={props.plans}
                                            />
                                        );
                                    }
                                } />

                            </Panel>
                            { /* END OF PRODUCT TAB */ }

                            { /* SUBSCRIPTION TAB */ }
                            <Panel value={ value } index={ 2 }>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <Box sx={ {
                                                ...fieldItemStyles,
                                                width: '100%'
                                            }}>
                                                <span style={ { width: '100%' } } >{ "Frequency:" }</span>
                                                <SelectInput
                                                    name="stripeFrequency"
                                                    source="stripeFrequency"
                                                    choices={choices}
                                                    label={ false }
                                                    onChange={ handleRadioSelect }
                                                />

                                            </Box>
                                            <Box sx={ {
                                                ...fieldItemStyles,
                                                width: '100%'
                                            }}>
                                                <span>{ "Discount:" }</span>
                                                <Box display="flex" flexDirection="column">
                                                    <CouponSelector couponData={ couponData } />
                                                    <span style={ { fontSize: '14px', color: 'red' } } >
                                            {"Note: Only one coupon code can be applied at a time."}
                                        </span>
                                                </Box>
                                            </Box>
                                            <Box sx={ {
                                                ...fieldItemStyles,
                                                width: '100%'
                                            }}>
                                                <span>{ "Next Cycle Date:" }</span>
                                                <DateInput
                                                    source="next_cycle_date"
                                                    name="next_cycle_date"
                                                    label={ false } />
                                            </Box>

                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <Box sx={ {
                                                ...fieldItemStyles
                                            } }>
                                                <WithRecord render={
                                                    record => {
                                                        return (
                                                            <Box
                                                                display="flex"
                                                                flexDirection="column"
                                                                justifyContent="flex-end"
                                                                sx={ { width: '100%'  } }
                                                            >
                                                                <Stack spacing={2}>
                                                                    { (record.state === 'active' || record.state === 'past_due') &&
                                                                        <PauseAction
                                                                            record={ record }
                                                                            fullButton={true}/>
                                                                    }
                                                                    { record.state === 'paused' &&
                                                                        <ResumeAction
                                                                            record={ record }
                                                                            fullButton={true} />
                                                                    }
                                                                    <CancelAction
                                                                        record={ record }
                                                                        fullButton={true} />

                                                                </Stack>
                                                                {/*<PaymentLinkAction record={ record } fullButton={true} />*/}
                                                            </Box>
                                                        );
                                                    }
                                                } />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Panel>
                            { /* END OF SUBSCRIPTION TAB */ }

                            { /* ACTIVITY TAB */ }
                            <Panel value={ value } index={ 3 }>
                                <SubscriptionActivity />
                            </Panel>
                            { /* END OF ACTIVITY TAB */ }

                            { /* DEVELOPER TAB */ }
                            { (permissions && permissions.includes('ROLE_SUPER_ADMIN')) &&
                                <Panel value={ value } index={ 4 }>
                                    <SubscriptionModalUpdateControl />
                                </Panel>
                            }
                            { /* END OF DEVELOPER TAB */ }

                        </Box>
                    </Box>
                </SimpleForm>
            }
        </EditInDialogButton>
    );
}