import { apiBase, baseUrl } from '../../config.js'
import { useUrlManagerCleanUrl } from "../../utils/UrlManager";

const excludedRows = [
    '@id',
    '@type',
    "@context"
];

function addUrlToUri(values, url) {
    // debugger;
    if (values === undefined) return;
    let returnVaule = values;
    let tmp = [];
    returnVaule.forEach(itemValue => {
        tmp.push((url + itemValue));
    });
    if (tmp.length > 0) {
        returnVaule = tmp;
    }

    return returnVaule
}

function removeUrlFromUri(values, url) {
    // debugger;
    if (values === undefined) return;
    let returnVaule = values;
    let tmp = [];
    returnVaule.forEach(itemValue => {
        tmp.push((itemValue.replace(url, '')));
    });
    if (tmp.length > 0) {
        returnVaule = tmp;
    }

    return returnVaule
}

function cleanEntityString(string, url) {
    return string.replace(url, '');
}

function restoreEntityString(string, url) {
    return url + string;
}

function cleanParams(item) {
    return {
        addresses: removeUrlFromUri(item['addresses'], '/api/sales_order_addresses/'),
        boldOrderHistories: removeUrlFromUri(item['boldOrderHistories'], '/api/bold_order_histories/'),
        createdAt: item['createdAt'],
        created_at: item['created_at'],
        currency: item['currency'],
        id: item['id'],
        isTrial: item['isTrial'],
        is_trial: item['is_trial'],
        nextCycleDate: item['nextCycleDate'],
        next_cycle_date: item['next_cycle_date'],
        oneOffTotal: item['oneOffTotal'],
        one_off_total: item['one_off_total'],
        products: removeUrlFromUri(item['products'], '/api/catalog_product_entities/'),
        shippingMethod: cleanEntityString(item['shippingMethod'], '/api/sales_shipping_methods/'),
        shipping_method: cleanEntityString(item['shipping_method'], '/api/sales_shipping_methods/'),
        state: item['state'],
        stripeSubscriptionId: item['stripeSubscriptionId'],
        stripe_subscription_id: item['stripe_subscription_id'],
        totalOrderValue: item['totalOrderValue'],
        total_order_value: item['total_order_value'],
        updatedAt: item['updatedAt'],
        updated_at: item['updated_at'],
        customer: cleanEntityString(item['customer'], '/api/customer_entities/'),
        stripe_interval: item['stripe_interval'],
        stripe_interval_count: item['stripe_interval_count'],
    }
}

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

function storeAttributeKeys(name, value, resource) {
    // debugger;
    let storedKeys = localStorage.getItem('stored_k');
    if (!storedKeys) {
        storedKeys = {};
        storedKeys[resource] = [];
    } else {
        storedKeys = JSON.parse(storedKeys);
    }

    let type = (typeof value);

    if (type === 'string') {
        try {
            let testDate = new Date(value)
            if (testDate.getTime()) {
                type = "date";
            }
        } catch (error) {

        }
        if (parseFloat(value)) {
            type = 'number';
        }
        try {
            if (JSON.parse(value)) {
                type = 'json';
            }
        } catch(error) {

        }
    }

    if (type === 'object') {
        if (Array.isArray(value)) {
            type = 'array';
        }
    }

    let k = { name: name, type: type  };
    let tmp = storedKeys[resource];
    if (!tmp) {
        storedKeys[resource] = [];
        tmp = storedKeys[resource];
    }
    let searchResult = tmp ? tmp.find( o => o.name === name ) : false;
    if (!searchResult) {
        tmp.push(k);
    } else {
        let index = tmp.indexOf(searchResult);
        tmp.splice(index, 1)
        tmp.push(k);
    }
    storedKeys[resource] = tmp;
    let datum = JSON.stringify(storedKeys);
    localStorage.setItem('stored_k', datum);
}

function storeResourceData(key, value, resource) {
    // debugger;
    let storedData = localStorage.getItem('stored_data');
    if (!storedData) {
        storedData = {};
        storedData[resource] = [];
    } else {
        storedData = JSON.parse(storedData);
    }
    if (!Array.isArray(storedData[resource])) {
        storedData[resource] = [];
    }
    // if (!storedData) {
    //     storedData[resource]
    // }
    let d = { key: key, value: value }
    let tmp = storedData[resource];

    let searchResult = tmp.find( o => o.key === key && o.value === value );

    if (!searchResult) {
        tmp.push(d);
    }
    storedData[resource] = tmp;
    let datum = JSON.stringify(storedData);
    localStorage.setItem('stored_data', datum);
}

function restoreParams(params, resource, restoreForUpdate = false) {
    // debugger;
    let storedData = localStorage.getItem('stored_data');
    let attributeData = localStorage.getItem('stored_k');
    try {
        attributeData = JSON.parse(attributeData);
    } catch (error) {

    }
    for (let param in params) {
        // // console.log(param);
        // // console.log(params[param]);

        if (storedData) {
            let datum = JSON.parse(storedData);
            if (datum[resource]) {
                let searchResult = datum[resource].find( o => o.key === param);
                if (searchResult && searchResult.value && (
                    // typeof params[param] === "string" &&
                    params[param].indexOf(searchResult.value) === -1
                )

                ) {
                    if (typeof params[param] === "string" &&
                        params[param].indexOf(searchResult.value) === -1) {
                        params[param] = searchResult.value + params[param];
                    }

                    if (Array.isArray(params[param])) {
                        let newArrayParams = params[param];
                        params[param].forEach((item, index) => {
                            newArrayParams[index] = searchResult.value + item;
                        })
                        params[param] = newArrayParams;
                    }
                }
            }
        }
        if (restoreForUpdate && param.indexOf('_')) {
            let paramKeys = param.split('_');
            if (paramKeys.length > 0) {
                let newKey = paramKeys[0];
                if (paramKeys.length > 1) {
                    for (let j = 1; j < paramKeys.length; j++) {
                        let p = paramKeys[j];
                        let firstChar = p[0];
                        firstChar = firstChar.toUpperCase();
                        newKey += (firstChar + p.substring(1));
                    }
                }
                params[newKey] = params[param];
            }

        }
        if (attributeData && attributeData[resource]) {
            let isJson = false;
            for(let k = 0; k < attributeData[resource].length; k++) {
                let tmp = attributeData[resource][k];
                if (tmp.name === param && tmp.type === 'json') {
                    isJson = true;
                    break;
                }
                if (tmp.name === param && tmp.type === 'array') {
                    if (!Array.isArray(params[param])) {
                        params[param] = [params[param]];
                    }
                }
            }
            if (isJson) {
                params[param] = JSON.stringify(params[param]);
            }
        }
    }
    return params;
}

function storeSearchParams(params, resource) {
    // debugger;
    let searchParams = {};
    searchParams[resource] = [];
    for (let param in params) {
        let p = params[param];
        searchParams[resource].push({
            variable: p.variable,
            property: p.property,
            required: p.required,
        });
    }
    let datum = JSON.stringify(searchParams);
    localStorage.setItem('stored_s', datum);
}



const subscriptionDataProvider = {

    getList: async (resource, params) => {
        // console.log(params);
        // debugger;
        let token = localStorage.getItem('token');
        if (!token || !params) {
            return Promise.reject('login');
        }
        let url = baseUrl + resource;
        if (params.pagination?.page) {
            if (url.includes('?')) {
                url += '&page=' + params.pagination.page;
            } else {
                url += '?page=' + params.pagination.page;
            }
        }
        if (params.pagination?.perPage) {
            if (url.includes('?')) {
                url += '&itemsPerPage=' + params.pagination.perPage;
            } else {
                url += '?itemsPerPage=' + params.pagination.perPage;
            }
        }
        if (params.filter.q !== undefined) {
            //url = processGlobalSearchFilter(url, resource, params.filter.q);
            url += '&q=' + params.filter.q;
        } else {
            url = processFilters(url, params);
            url = processSearchFilter(url, params, resource);
        }
        url = proocessSortOrder(url, params);
        url = useUrlManagerCleanUrl({ url: url });

        //url = encodeURI(url);
        // console.log(url);
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + token
            })
        });
        return fetch(request)
            .then(response => {
                // console.log(response);
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    // localStorage.removeItem('profile');
                    // localStorage.removeItem('token');
                    // return Promise.reject('login' );
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                let responseData = {
                    data: [],
                    total: 0
                }
                // debugger;
                if (Array.isArray(data['hydra:member'])) {
                    if (data['hydra:search']) {
                        storeSearchParams(data['hydra:search']['hydra:mapping'], resource);
                    }

                    let items = data['hydra:member'];
                    for(let i = 0; i < items.length; i++ ) {
                        let item = items[i];
                        responseData.data.push(cleanParams(item));
                    }
                    responseData.total = data['hydra:totalItems'];
                }
                // console.log(responseData);
                return responseData;
            })
            .catch(error => {
                // console.log(error);
                return Promise.reject('login' );
            });
    },
    getOne: async (resource, params) => {
        // debugger;
        let token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject('login');
        }
        let url = baseUrl + resource + '/' + params.id;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + token
            })
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('token');
                    return Promise.reject('login' );
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: {}
                }
                if (data.id) {
                    console.log(data);
                    responseData.data = cleanParams(data);
                    console.log(responseData.data);
                }
                return responseData;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject('login' );
            })
    },
    getMany: async (resource, params) => {
        let token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject('login');
        }
        let url = baseUrl + resource + '/';
        url = processGetManyFilter(url, params);
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + token
            })
        })
        return fetch(request)
            .then(response => {
                // console.log(response);
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('token');
                    return Promise.reject('login' );
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: [],
                    total: 0
                }
                // console.log(data);
                if (Array.isArray(data['hydra:member'])) {

                    let items = data['hydra:member'];
                    for(let i = 0; i < items.length; i++ ) {
                        let item = items[i];
                        responseData.data.push(cleanParams(item));
                    }
                    responseData.total = data['hydra:totalItems'];
                }
                return responseData;
            })
            .catch(error => {
                // console.log(error);
                return Promise.reject('login' );
            })
    },
    getManyReference: async (resource, params) => {
        let token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject('login');
        }
        // console.log(params);
        if(params.id.length <= 0) {
            return {
                data: [],
                total: 0
            }
        }
        //debugger;
        //// console.log('here');
        let url = baseUrl + resource;
        url = processGetManyByReferenceFilter(url, params);
        // console.log(url);
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + token
            })
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('token');
                    return Promise.reject('login' );
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: [],
                    total: 0
                }
                // console.log(data);
                if (Array.isArray(data['hydra:member'])) {
                    let items = data['hydra:member'];
                    for(let i = 0; i < items.length; i++ ) {
                        let item = items[i];
                        responseData.data.push(cleanParams(item));
                    }
                    responseData.total = data['hydra:totalItems'];
                }
                // console.log(responseData);
                return responseData;
            })
            .catch(error => {
                // console.log(error);
                return Promise.reject('login' );
            })
    },
    create: async (resource, params) => {
        let token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject('login');
        }

        params.data.products = addUrlToUri(params.data.products, '/api/catalog_product_entities/');
        params.data.addresses = addUrlToUri(params.data.addresses, '/api/sales_order_addresses/');
        params.data.boldOrderHistories = addUrlToUri(params.data.boldOrderHistories, '/api/bold_order_histories/');
        params.data.shippingMethod = restoreEntityString(params.data.shippingMethod, '/api/sales_shipping_methods/');
        params.data.shipping_method = restoreEntityString(params.data.shipping_method, '/api/sales_shipping_methods/');
        params.data.customer = restoreEntityString(params.data.customer, '/api/customer_entities/');

        let url = baseUrl + resource;
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify(params['data']),
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + token
            })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('token');
                    return Promise.reject('login' );
                }
                return response.json();
            })
            .then(data => {
                let responseData = {
                    data: {}
                }
                if (data.id) {
                    responseData.data = cleanParams(data);
                }
                return responseData;
            })
            .catch(error => {
                // console.log(error);
                return Promise.reject('login' );
            })
    },
    update: async (resource, params) => {
        console.log(params);
        // debugger;
        let token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject('login');
        }
        let url = baseUrl + resource + '/' + params.data.id;
        url = useUrlManagerCleanUrl({ url: url });
        params.data.products = addUrlToUri(params.data.products, '/api/catalog_product_entities/');
        params.data.addresses = addUrlToUri(params.data.addresses, '/api/sales_order_addresses/');
        params.data.boldOrderHistories = addUrlToUri(params.data.boldOrderHistories, '/api/bold_order_histories/');
        params.data.shippingMethod = restoreEntityString(params.data.shippingMethod, '/api/sales_shipping_methods/');
        params.data.shipping_method = restoreEntityString(params.data.shipping_method, '/api/sales_shipping_methods/');
        params.data.customer = restoreEntityString(params.data.customer, '/api/customer_entities/');

        console.log(params);
        // console.log(JSON.stringify(restoredParams));
        const request = new Request(url, {
            method: 'PATCH',
            body: JSON.stringify(params),
            headers: new Headers({
                'Content-Type' : 'application/merge-patch+json',
                'Authorization': 'Bearer ' + token
            })
        });
        return fetch(request)
            .then(response => {
                // debugger;
                console.log(response);

                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    // localStorage.removeItem('profile');
                    // localStorage.removeItem('token');
                    // return Promise.reject('login' );
                }
                return response.json();
            })
            .then(data => {
                // debugger;
                console.log(data);
                if (data.error && data.error.code === "400" &&
                    (
                       data.error.message == 'Failed to update subscription frequency'
                    )
                ) {
                    return Promise.reject( data.error.message)
                }
                let responseData = {
                    data: {}
                }
                if (data.id) {
                    responseData.data = cleanParams(data);
                }
                return responseData;
            })
            .catch(error => {
                // console.log(error);
                return Promise.reject(error);
            })
    },
    updateMany: async (resource, params) => {
        return Promise.reject();
    },
    delete: async (resource, params) => {
        let token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject('login');
        }
        let url = baseUrl + resource + '/' + params.id;
        const request = new Request(url, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + token
            })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    localStorage.removeItem('profile');
                    localStorage.removeItem('token');
                    return Promise.reject('login' );
                }
                return Promise.resolve();
            })
            .catch( error => {
                // console.log(error);
                return Promise.reject();
            });
    },
    deleteMany: async (resource, params) => {
        return Promise.reject();
    },
    search: (value) => {
        // console.log(value);
        let token = localStorage.getItem('token');
        let url = '';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + token
            })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                    // localStorage.removeItem('profile');
                    // localStorage.removeItem('token');
                    // return Promise.reject('login' );
                }
                return response.json();
            })
            .then(data => {
                // console.log(data);
                if (data) {
                }
            })
            .catch( error => {
                // console.log(error);
                // console.log('CompaniesHouseFiling');
                return Promise.reject();
            });
    }
}

const processGetManyFilter = (url, params, resource) => {
    let string = '';
    for (let i = 0; i < params['ids'].length; i++) {

        let param = params['ids'][i];
        if (typeof parseInt(param) === "number") {
            if (string === '') {
                string += 'id[]=' + param;
            } else {
                string += '&id[]=' + param;
            }
        } else {
            let temp = param.replace('/' + resource + '/', '');
            let values = temp.split('/');
            if (values.length === 2) {
                if (string === '') {
                    string += values[0] + '[]=' + values[1];
                } else {
                    string += '&' + values[0] + '[]=' + values[1];
                }
            }
        }
    }
    if (string.length > 0) {
        if (url.indexOf('?') > -1) {
            url += '&' + string;
        } else {
            url += '?' + string;
        }
    }
    return url;
}

const proocessSortOrder = (url, params) => {

    if (typeof params.sort !== "undefined") {
        if (url.indexOf('?')) {
            url += '&sortOrder[' + params.sort.field + ']=' + params.sort.order ;
        } else {
            url += '?sortOrder[' + params.sort.field + ']=' + params.sort.order ;
        }
    }

    return url;
}

const processFilters = (url, params) => {
    let string = '';

    // return url + '&customerId=/api/customer_entities/67610';
    if (typeof params.filter !== "undefined") {

        for (const key in params.filter) {

            if (params.filter.hasOwnProperty(key)) {
                let keyValue = key;
                if (keyValue.endsWith('eq')) {
                    keyValue = keyValue.replace('_eq', '');
                }
                if (typeof params.filter[key] == 'object') {
                    for (const value in params.filter[key]) {
                        let val = params.filter[key][value];
                        if (val === true) {
                            val = 1;
                        } else if (val === false) {
                            val = 0;
                        }
                        if (string === '') {
                            string += (keyValue.toLowerCase() + '.' + value  ) + '=' + val;
                        } else {
                            string += '&' + (keyValue.toLowerCase() + '.' + value) + '=' + val;
                        }
                    }
                } else {
                    let value = params.filter[key];
                    if (value === true) {
                        value = 1;
                    } else if (value === false) {
                        value = 0;
                    }
                    if (string === '') {
                        string += keyValue.toLowerCase() + '=' +value;
                    } else {
                        string += '&' + keyValue.toLowerCase() + '=' + value;
                    }
                }


            }
        }
        if (string.length > 0) {
            if (url.indexOf('?')) {
                url += '&' + string;
            } else {
                url += '?' + string;
            }
        }
    }
    return url;
};

const processSearchFilter = (url, params, resource) => {
    if (!params.filter?.search_input) {
        return url;
    }
    // debugger;
    let searchParam = params.filter.search_input;
    delete params.filter.search_input;

    let string = '';
    let attributes = localStorage.getItem('stored_s');
    let attributeData = localStorage.getItem('stored_k');
    if (attributes) {
        attributes = JSON.parse(attributes);
        if (attributes[resource] && attributeData) {
            attributeData = JSON.parse(attributeData);
            if (attributeData[resource]) {
                // console.log(params);

                for(let j = 0; j < attributes[resource].length; j++) {
                    let param = attributes[resource][j];
                    if (param.variable.includes('[')) {
                        continue;
                    }
                    let attributeDatum = "string";
                    for(let k = 0; k < attributeData[resource].length; k++) {
                        let tmp = attributeData[resource][k];
                        if (tmp.name == param.property) {
                            attributeDatum = tmp.type;
                            break;
                        }
                    }
                    if (url.indexOf('?') === -1) {
                        string += '?' + param.variable + '=' + searchParam;
                    } else {
                        string += '&' + param.variable + '=' + searchParam;
                    }

                }
            }


        }
    }
    return encodeURI(url + string);
}

const processGlobalSearchFilter = (url, resource, searchValue) => {
    // debugger;
    let string = '';
    let attributes = localStorage.getItem('stored_s');
    let attributeData = localStorage.getItem('stored_k');
    if (attributes) {
        attributes = JSON.parse(attributes);
        if (attributes[resource] && attributeData) {
            attributeData = JSON.parse(attributeData);
            if (attributeData[resource]) {
                // console.log(params);

                for(let j = 0; j < attributes[resource].length; j++) {
                    let param = attributes[resource][j];
                    if (param.variable.includes('[')) {
                        continue;
                    }

                    if (url.indexOf('?') === -1) {
                        string += '?' + param.variable + '=' + searchValue;
                    } else {
                        string += '&' + param.variable + '=' + searchValue;
                    }

                }
            }


        }
    }
    // console.log(encodeURI(url + string));
    // url += '&first_name=liam&email_address=liam&phone_number=liam'
    // return url;
    return encodeURI(url + string);
}

const processGetManyByReferenceFilter = (url, params) => {
    // debugger;
    let string = '';
    if (Array.isArray(params['id'])) {
        for (let i = 0; i < params['id'].length; i++) {

            let param = params['id'][i];
            if (typeof parseInt(param) === "number") {
                if (string === '') {
                    string += 'id[]=' + param;
                } else {
                    string += '&id[]=' + param;
                }
            }
        }
    }

    if (typeof params['id'] == 'string' || typeof params['id'] == 'number') {
        if (string === '') {
            string += 'id=' + params['id'];
        } else {
            string += '&id=' + params['id'];
        }
    }


    if (string.length == 0 && params.target == 'id') {
        url += '&id='
    }

    if (string.length > 0) {
        if (url.indexOf('?') > -1) {
            url += '&' + string;
        } else {
            url += '?' + string;
        }
    }
    return url;
}

export default subscriptionDataProvider;