
import React, { useContext, useEffect, useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    WithRecord,
    ReferenceManyField,
    Datagrid,
    TextField,
    ReferenceField,
    DateInput,
    List,
    Show,
    DateField,
    Labeled,
    SearchInput,
    SimpleShowLayout,
    SelectField,
    WithListContext,
    useRefresh,
    useUpdate,
    useNotify,
    ShowButton,
    SelectInput,
    Pagination, BooleanField
} from 'react-admin';
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    LinearProgress,
    Grid,
    Stack,
    Tab,
    Tabs,
    Link,
    Dialog,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { AdminUserContext } from "../../../../../../contexts/AdminUserContextProvider";
import { useGetStripeMode } from "../../../../../components/hooks/EditSubscriptionModalHooks";

const fieldItemStyles = {
    display: "flex",
    justifyContent: "space-between",
};

/**
 * Generate Stripe dashboard link based on stripe_mode setting
 * @param props
 * @returns {Element}
 * @constructor
 */
const SubscriptionLink = (props) => {
    const record = props.record;
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("test");
    useEffect(() => {
        ( async function () {
                let modeLoad = await useGetStripeMode();
                if (modeLoad?.success) {
                    setMode(modeLoad.mode);
                    setLoading(false);
                }
                if (modeLoad?.error) {
                    setMode('error')
                }
            }
        )();
    }, []);

    if (loading) {
        return <LinearProgress sx={ { width: '50px' } } />;
    }

    if (mode === 'error') {
        return <span>'Failed to generate Stripe link</span>
    }
    if (mode === 'test') {
        return (
            <Link href={ "https://dashboard.stripe.com/" + mode + "/subscriptions/" + record.stripeSubscriptionId } target="_blank" >{ record.stripeSubscriptionId }</Link>
        );
    }

    return (
        <Link href={ "https://dashboard.stripe.com/subscriptions/" + record.stripeSubscriptionId } target="_blank" >{ record.stripeSubscriptionId }</Link>
    );
}

export const GeneralTab = (props) => {
    const context = useContext(AdminUserContext);

    function getCurrencySymbol(id) {
        let symbol = '£';
        context.currencies.forEach((item) => {
            if (item.id == id) {
                symbol = item.symbol;
            }
        })
        return symbol;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Stack spacing={2}>
                    <Box sx={ fieldItemStyles }>
                        <span>Id:</span>
                        <TextField label="Id" source="id" />
                    </Box>
                    <Box display="flex"
                         justifyContent="space-between"
                    >
                        <span>{ "Customer:" }</span>
                        <ReferenceField
                            label="Customer"
                            source="customer"
                            reference="customer_entities"
                            link={ false }
                        >
                            <TextField source="full_name" />
                        </ReferenceField>
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Next Cycle Date:" }</span>
                        <DateField label="" name="next_cycle_date" source="next_cycle_date" />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Shipping Method:" }</span>
                        <ReferenceField label="Shipping Method"
                                        source="shipping_method"
                                        reference="sales_shipping_methods"
                                        link={false}
                        >
                            <TextField source="shipping_method_name" />
                        </ReferenceField>
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Status:" }</span>
                        <WithRecord label="" render={
                            record => {
                                let colour = '#000';
                                if (record.state === 'paused') {
                                    colour = '#f36907';
                                }
                                if (record.state === 'active') {
                                    colour = '#419d06';
                                }

                                if (record.state === 'cancelled') {
                                    colour = '#ef1b04';
                                }

                                // if (record.resumes && record.state == 'paused') {
                                //     return <span style={ {
                                //         fontsize: '20px',
                                //         fontWeight: 'bold',
                                //         background: '#74ee15',
                                //         padding: '10px',
                                //         color: '#000',
                                //         borderRadius: '10px'
                                //     } } >{ "Processing...." }</span>
                                // }

                                return (
                                    <TextField label="Subscription Status"
                                               source="state"
                                               sx={ {
                                                   textTransform: 'capitalize',
                                                   color: colour
                                               } } />
                                );
                            }
                        } />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Is Trial:" }</span>
                        <BooleanField source="is_trial" name='is_trial' />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Currency:" }</span>
                        <SelectField label=""
                                     name="currency"
                                     source="currency"
                                     choices={ context.currencies } />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Upcoming Invoice amount:" }</span>
                        <WithRecord label="" render={
                            record => {
                                return (
                                    <span>{ getCurrencySymbol(record.currency) + parseFloat(record.upcomingPrice).toFixed(2) }</span>
                                );
                            }
                        } />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack spacing={2}>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "One Off Total:" }</span>
                        <WithRecord label="One Off Total" render={
                            record => (
                                <span>{ getCurrencySymbol(record.currency) + parseFloat(record.one_off_total).toFixed(2) }</span>
                            )
                        } />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Total Order Amount:" }</span>
                        <WithRecord label="Total Order Value" render={
                            record => (
                                <span>{ getCurrencySymbol(record.currency) + parseFloat(record.total_order_value).toFixed(2) }</span>
                            )
                        } />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Subscription Frequency:" }</span>
                        <TextField source="stripeFrequency" name="stripeFrequency"/>
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Created:" }</span>
                        <DateField source="created_at" />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Updated:" }</span>
                        <DateField source="updated_at" />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Scheduler Paused:" }</span>
                        <BooleanField source="schedularPaused" />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Subscription Resumes:" }</span>
                        <BooleanField source="resumes"
                                      sx={ {
                                          background: '#74ee15',
                                          padding: '5px',
                                          borderRadius: '100%'
                                      } }
                        />
                    </Box>
                    <Box sx={ fieldItemStyles }>
                        <span>{ "Subscription Link:" }</span>
                        <WithRecord label="" render={
                            record => {
                                return (
                                    <SubscriptionLink record={record} />
                                );
                            }
                        } />

                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}