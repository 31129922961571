import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    useRecordContext,
    useUpdate,
    SelectInput,
    required,
    CheckForApplicationUpdate
} from 'react-admin';
import {
    Grid,
} from '@mui/material';
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { setLicenseKey } from "survey-core";
import { usePermissionsManager } from "../utils/permissionsManager";

setLicenseKey(
    "MjY1ZWFmYzMtZmY2OC00NTcyLTk2YWItYTMzZjY1MDJmNGY5OzE9MjAyNS0wOS0wOQ=="
);


const choices = [
    { id: 'pause', name: 'Pause' },
    { id: 'cancel', name: 'Cancel' },
    { id: 'consultation', name: 'Consultation' },
];


const SurveyForm = (props) => {
    const record = useRecordContext();
    const [update] = useUpdate();
    const creatorOptions = {
        showLogicTab: true,
        isAutoSave: false,
        readOnly: usePermissionsManager({  accessToRoles: ['ROLE_COMMERCE'] })
    };
    const creator = new SurveyCreator(creatorOptions);
    creator.text = JSON.stringify(record.data);
    creator.saveSurveyFunc = async () => {
        await update(
            'surveys',
            { id: record.id, data: { id: record.id, data: creator.JSON  } }
        );
    };
    return (
        <SurveyCreatorComponent style={ { height: '80vh' } } creator={creator} />
    );
}

export const SurveysEdit = () => {

   return (
       <Grid container spacing={2}>
           <Grid item xs={12}>
               <Edit redirect="false"
                     queryOptions={ {
                         refetchInterval: false,
                         refetchIntervalInBackground: false,
                         refetchOnWindowFocus: false,
                         refetchOnMount: false,
                         refetchOnReconnect: false
                     } }
               >
                   <CheckForApplicationUpdate disabled={true} />
                   <SurveyForm/>
               </Edit>
           </Grid>
           <Grid item xs={12} >
               <Edit
                   redirect="false" title=" "
                     queryOptions={ {
                         refetchInterval: false,
                         refetchIntervalInBackground: false,
                         refetchOnWindowFocus: false,
                         refetchOnMount: false,
                         refetchOnReconnect: false
                     } }>
                   <CheckForApplicationUpdate disabled={true} />
                   <SimpleForm
                       toolbar={ (usePermissionsManager({  accessToRoles: ['ROLE_COMMERCE'] }) ?  false : undefined) }
                   >
                       <TextInput readOnly={ usePermissionsManager({  accessToRoles: ['ROLE_COMMERCE'] }) }
                           source="name" name="name" validate={required()}/>
                       <TextInput
                           readOnly={ usePermissionsManager({  accessToRoles: ['ROLE_COMMERCE'] }) }
                           source="code" name="code" validate={required()} />
                       <SelectInput
                           readOnly={ usePermissionsManager({  accessToRoles: ['ROLE_COMMERCE'] }) }
                           name="type" source="type" choices={ choices } validate={required()} />
                       <BooleanInput
                           readOnly={ usePermissionsManager({  accessToRoles: ['ROLE_COMMERCE'] }) }
                           source="enabled" name="enabled" validate={required()} />
                   </SimpleForm>
               </Edit>
           </Grid>
       </Grid>
   );
}