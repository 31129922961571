import React, { useState } from 'react';
import {
    BooleanField,
    Confirm,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    Toolbar,
    useDelete,
    useRecordContext,
    List,
    Datagrid,
    TextInput,
    required,
} from 'react-admin';
import { SettingsEditDialog } from "../Edits/Dialog/SettingsEditDialog";


export const SettingsList = () => {

    return (
        <List
            resource="settings"
        >
            <Datagrid  bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="code" />
                <BooleanField source="isSystem" />
               <SettingsEditDialog />
            </Datagrid>
        </List>
    );
}