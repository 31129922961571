import React from 'react';
import {
    Grid
} from '@mui/material';

import { CustomerSubscriptions } from "./components/customer/CustomerSubscriptions";
import { CustomerAddress } from "./components/customer/CustomerAddress";
import { CustomerDetails } from "./components/customer/CustomerDetails";
import { CustomerPayment } from "./components/customer/CustomerPayment";
import { CustomerOrders } from "./components/customer/CustomerOrders";
import { CustomerSurvey } from "./components/customer/CustomerSurvey";
import { CustomerActivity } from "./components/customer/CustomerActivity";
import { GDPRDelete } from "./components/customer/GDPRDelete";

export const CustomerEdit = () => {

    return (
        <>
            <Grid container spacing={ 2 } >
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                    { /* Customer subscriptions*/ }
                    <CustomerSubscriptions />
                    { /* End of Customer subscriptions */ }

                    { /* Customer and address */ }
                    <CustomerAddress />
                    { /* End of Customer and address */ }

                    { /* Customer Details  */  }
                    <CustomerDetails />
                    { /* End of Customer Details  */  }

                </Grid>
            </Grid>

            <Grid container spacing={ 2 } >
                <Grid item xs={ 12 } >
                    { /* Payment methods */ }
                    <CustomerPayment />
                    { /* End of Payment methods */ }
                </Grid>
            </Grid>

            <Grid container spacing={ 2 } >
                <Grid item xs={ 12 } >
                    { /* Orders */ }
                    <CustomerOrders />
                    { /* End of Orders */ }
                </Grid>
            </Grid>

            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } >
                    { /* Customer Survey Data */ }
                    <CustomerSurvey />
                    { /* End of Customer Survey Data */ }
                </Grid>
            </Grid>

            <Grid container spacing={ 2 } sx={ { marginBottom: '40px' } }>
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                    { /* Customer Activity */ }
                    <CustomerActivity />
                    { /* End of Customer Activity */ }
                </Grid>
            </Grid>

            <Grid container spacing={ 2 }  sx={ { marginBottom: '80px' } }>
                <Grid item xs={ 12 } >
                    { /* Customer GDPR Delete */ }
                    <GDPRDelete />
                    { /* End of Customer GDPR Delete */ }
                </Grid>
            </Grid>
        </>
    );
}