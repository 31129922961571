import {Confirm, useNotify, useUpdate, useEfect} from "react-admin";
import React, {useContext, useEffect, useState} from "react";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import {
    Box,
    Button,
    CircularProgress,
    Select,
    MenuItem, LinearProgress
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDataProvider } from "react-admin";

function getMonthFromNumber(num) {
    let months = {
        1 : 'January',
        2 : 'February',
        3 : 'March',
        4 : 'April',
        5 : 'May',
        6 : 'June',
        7 : 'July',
        8 : 'August',
        9 : 'September',
        10 : 'October',
        11 : 'November',
        12 : 'December'
    };
    return months[num];
}

function daysInMonth(month, year) {
    let days = {
        'January' : 31,
        'February' : 28,
        'March' : 31,
        'April' : 30,
        'May' : 31,
        'June' : 30,
        'July' : 31,
        'August' : 31,
        'September' : 30,
        'October' : 31,
        'November' : 30,
        'December' : 31
    };
    if (isLeapYear(year)) {
        days['February'] = 29;
    }
    return days[month];
}

function isLeapYear(year) {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}


export const ResumeAction = (props) => {
    const notify = useNotify();
    const context = useContext(AdminUserContext);
    const [update] = useUpdate();
    const [openResume, setOpenResume] = useState(false);
    const [loadingDate, setLoadingDate] = useState(true);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    let loadYear = tomorrow.getFullYear();
    let loadDate = tomorrow.getDate();
    let loadMonth = tomorrow.getMonth() + 1;
    const [resumesDate, setResumesDate] = useState(loadDate);
    const [resumesMonth, setResumesMonth] = useState(loadMonth);
    const [resumesYear, setResumesYear] = useState(loadYear);
    const [resumesDates, setResumesDates] = useState([]);
    const [resumesMonths, setResumesMonths] = useState([]);
    const [resumesYears, setResumesYears] = useState([]);
    const dataProvider = useDataProvider();


    const record = props.record;


    useEffect(() => {
        let loadYears = [];
        for (let y = today.getFullYear(); y <= (today.getFullYear() + 3); y++) {
            let year = {
                value: y,
            };
            loadYears.push(year);
        }
        setResumesYears(loadYears);

        let loadMonths = []
        for (let m = 1; m <= 12; m++) {
            let month = getMonthFromNumber(m);
            let disabled = false;
            let mustDisable = (
                resumesYear === tomorrow.getFullYear()
            );
            let defaultDisable = (
                m < (tomorrow.getMonth() + 1) &&
                resumesYear === tomorrow.getFullYear()
            );
            if (defaultDisable) {
                disabled = true
            }
            let mo = {
                value: m,
                label: month,
                disabled: disabled
            };
            loadMonths.push(mo);
        }
        setResumesMonths(loadMonths);


        let loadDates = [];
        let daysPerMonth = daysInMonth(getMonthFromNumber(resumesMonth), resumesYear);
        if (resumesMonth !== today.getMonth() + 1) {
            // debugger;
        }

        for (let d = 1; d <= 31; d++) {

            let disabled = false;
            let tm = tomorrow.getMonth() + 1;
            let mustDisable = (
                resumesMonth === tm &&
                resumesYear === tomorrow.getFullYear() &&
                resumesDate < tomorrow.getDate()
            );
            let defaultDisable = (
                d > daysPerMonth ||
                (d < tomorrow.getDate() &&
                resumesMonth === (tomorrow.getMonth() + 1) &&
                resumesYear === tomorrow.getFullYear())
            );
            if (mustDisable || defaultDisable) {
                disabled = true;
            }
            let date = {
                value: d,
                disabled: disabled
            }
            loadDates.push(date);
        }
        setResumesDates(loadDates);

        setLoadingDate(false);
    },[resumesDate, resumesMonth, resumesYear])

    function handleDateChange(value) {
        setResumesDate(value)
    }

    function handleMonthChange(value) {
        setResumesMonth(value)
    }

    function handleYearChange(value) {
        setResumesYear(value)
    }


    const handleResumeClick = () => setOpenResume(true);
    const handleResumeDialogClose = () => setOpenResume(false);
    const handleResumeConfirm = async () => {
        let date = resumesDate + '-' + resumesMonth + '-' + resumesYear;
        let testDate = resumesYear + '-' + resumesMonth + '-' + resumesDate;
        // let updated = await context.resumeSubscription(record.stripe_subscription_id, date)
        //     .then(response => {
        //         return response.json();
        //     })
        //     .then(data => {
        //         console.log(data);
        //         if (Array.isArray(data) && data.success) {
        //
        //         }
        //         if (data.error) {
        //             notify(data.error_message);
        //         }
        //     });

        const newRecord = structuredClone(record);
        newRecord.state = 'active';
        newRecord.next_cycle_date = testDate;
        // debugger;
        await update(
            'sales_subscriptions',
            {id: record.id, data: newRecord, previousData: record}
        );
        notify("Subscription Resumed")
        setOpenResume(false);
    };
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
        >
            <Box sx={ { marginRight: '30px' } }>
                <span>{ "Resumes Date" }</span>
                <Box sx={
                    {
                        display: 'flex',
                        justifyContent: 'space-between'
                    }
                } >
                    { loadingDate &&
                        <LinearProgress sx={ { width: '40px' } } />
                    }
                    { !loadingDate &&
                        <>
                            <Select name="date" value={resumesDate}
                                    onChange={ (e) => handleDateChange(e.target.value) }
                                    variant="filled">
                                { resumesDates.map((date, index) => (
                                    <MenuItem
                                        key={ 'renewal_date_date_' + index }
                                        value={date.value} disabled={date.disabled}>
                                        {date.value}
                                    </MenuItem>
                                )) }
                            </Select>
                            <Select name="month" value={resumesMonth}
                                    onChange={ (e) => handleMonthChange(e.target.value) }
                                    variant="filled"
                                    sx={ { marginLeft: '15px' } }
                            >
                                { resumesMonths.map((month, index) => (
                                    <MenuItem
                                        key={ "renewal_date_month_" + index }
                                        value={ month.value } disabled={ month.disabled }>
                                        { month.label }
                                    </MenuItem>
                                ))  }
                            </Select>
                            <Select name="year" value={resumesYear}
                                    onChange={(e) => handleYearChange(e.target.value) }
                                    variant="filled"
                                    sx={ { marginLeft: '15px' } }
                            >
                                { resumesYears.map((year, index) => (
                                    <MenuItem
                                        key={ "renewal_date_year" + index }
                                        value={ year.value }>
                                        { year.value }
                                    </MenuItem>
                                )) }
                            </Select>
                        </>
                    }

                </Box>
            </Box>
            <Button
                variant="contained"
                color="success"
                title="Resume Subscription" onClick={ handleResumeClick }
                sx={ { height: '40px' } }
            >
                { !openResume &&
                    <>
                        <span style={{ marginRight: '10px' }}>{ "Resume Subscription" }</span>
                        <RestartAltIcon/>
                    </>

                }
                { openResume &&
                    <CircularProgress size={20} color="secondary" />
                }
            </Button>
            <Confirm
                isOpen={ openResume }
                title="Resume Subscription"
                content="Are you sure you want to resume this subscription?"
                onConfirm={ handleResumeConfirm }
                onClose={ handleResumeDialogClose }
            />
        </Box>
    );
}