import React, { useState, useContext, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required,
    useCreate,
    CreateBase,
} from 'react-admin';
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import {
    Box,
    CircularProgress,
} from '@mui/material';

export const SalesCouponCreate = (props) => {
    const context = useContext(AdminUserContext);
    const [loading, setLoading] = useState(true);
    const [couponProducts, setCouponProducts] = useState([]);
    const [freeSkus, setFreeSkus] = useState( [])
    const [create] = useCreate();

    let discountTypes = [];
    for (const discountType of context.subscriptionDiscountTypes) {
        discountTypes.push({ const: discountType.id, title: discountType.name });
    }

    const durationOptions = [
        { const: 'once', title: 'Once' },
        { const: 'repeating', title: 'Repeating' },
        { const: 'forever', title: 'Forever' },
    ]

    function submitFormAction(values) {
        let datum = values.formData;
        create(
            'sales_coupons',
            {
                data: {
                    'couponCode': datum.coupon_code,
                    'active': datum.active,
                    'oneTime': datum.one_time,
                    'value': datum.value,
                    'couponRule': JSON.stringify(datum.coupon_rule),
                    'discountType': datum.discount_type,
                    'duration': datum.duration,
                    'free_gift_sku': datum.free_gift_sku,
                    'usage_limit': datum.usage_limit,
                }
            }
        );
    }

    useEffect( () => {
        ( async () => {
            if (couponProducts.length === 0) {
                await context.loadProductsForCouponCreator()
                    .then(response => {
                        if (response && response.success === true) {
                            setCouponProducts(response.data.lookup);
                            setFreeSkus(response.data.sku);
                        }
                        setLoading(false);
                    })
            }
        } )();
    }, [] );

    const transform = (data) => ({
        ...data,
        couponRule: JSON.stringify(data.couponRule),
        oneTime: false,
        usageLimit: 0
    })

    return (
        <Create redirect="list" transform={ transform }>
            { loading &&
                <Box sx={ {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '20px',
                } }>
                    <span style={ { marginTop: '20px' } } >{ "Loading..." }</span>
                    <CircularProgress size={ 40 }/>
                </Box>
            }
            { !loading &&
                <>
                    <span style={ { color: "red", fontSize: '12px', paddingLeft: '20px' } } >{ "Only 2.0 values are editable after coupon creation" }</span>
                    <JsonSchemaForm
                        // onSubmit={ submitFormAction }
                        schema={ {
                            type: "object",
                            "required": [
                                "couponCode",
                                "value",
                                "couponRule",
                                "discountType",
                                "duration",
                            ],
                            properties: {
                                'couponCode': {
                                    type: "string",
                                    title: "Code",
                                    description: 'Coupon Code for customer use (2.0 only)'
                                },
                                'active': {
                                    type: "boolean",
                                    title: "Active",
                                    default: true,
                                    description: 'Enable/Disable a coupon (2.0 only)'
                                },
                                // 'oneTime': {
                                //     type: 'boolean',
                                //     title: 'One Time',
                                //     default: false
                                // },
                                'value': {
                                    type: "string",
                                    title: "Value",
                                    description: 'Value of the coupon'
                                },
                                'couponRule': {
                                    type: 'object',
                                    title: 'Coupon Rule',
                                    description: 'Products to be included or excluded for the coupon (2.0 only)',
                                    properties: {
                                        "rule_operand": {
                                            type: 'string',
                                            title: 'Product List',
                                            oneOf: [
                                                { const: 'all', title: 'All' },
                                                { const: 'eligible_products', title: 'Eligible Products' },
                                                { const: 'excluded_products', title: 'Excluded Products' },
                                                { const: 'entitled', title: 'Entitled' },
                                            ]
                                        }
                                    },
                                    required: [
                                      'rule_operand',
                                    ],
                                    dependencies: {
                                        'rule_operand': {
                                            'oneOf': [
                                                {
                                                    'properties': {
                                                        'rule_operand': {
                                                            'const': 'eligible_products'
                                                        },
                                                        'eligible_products': {
                                                            type: 'array',
                                                            title: 'Eligible Products',
                                                            // uniqueItems: true,
                                                            description: 'Products the coupon code applies to',
                                                            items: {
                                                                type: 'array',
                                                                title: 'Eligible Products',
                                                                uniqueItems: true,
                                                                items: {
                                                                    oneOf: couponProducts
                                                                }
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    'properties': {
                                                        'rule_operand': {
                                                            'const': 'excluded_products'
                                                        },
                                                        'excluded_products': {
                                                            type: 'array',
                                                            title: 'Excluded Products',
                                                            description: 'Products the coupon doesn\'t apply to',
                                                            uniqueItems: true,
                                                            items: {
                                                                oneOf: couponProducts
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    'properties': {
                                                        'rule_operand': {
                                                            'const' : 'all'
                                                        }
                                                    }
                                                },
                                                {
                                                    'properties': {
                                                        'rule_operand': {
                                                            'const' : 'entitled'
                                                        }
                                                    }
                                                }
                                            ]
                                        },

                                    }
                                },
                                'discountType': {
                                    type: 'string',
                                    title: 'Discount Type',
                                    oneOf: discountTypes,
                                    description: 'Discount Type, most common would be Fixed or Percentage'
                                },
                                'duration': {
                                    type: 'string',
                                    title: 'Duration',
                                    oneOf: durationOptions,
                                    description: 'Duration of the coupon'
                                },
                                'freeGiftSku': {
                                    type: 'string',
                                    title: 'Free Gift SKU',
                                    oneOf: freeSkus,
                                    description: 'Free product added to the basket on use of the coupon in checkout'
                                },
                                // 'usageLimit': {
                                //     type: 'boolean',
                                //     title: 'Usage Limit'
                                // },
                                'redeemBy': {
                                    type: 'string',
                                    title: 'Expires On',
                                    format: 'date',
                                    description: 'Date coupon expires'
                                }
                            },
                            dependencies: {
                                "duration": {
                                    'oneOf': [
                                        {
                                            properties: {
                                                'duration': {
                                                    "const" : "repeating"
                                                },
                                                'durationInMonths': {
                                                    type: 'number',
                                                    title: 'How many months to repeat',
                                                    description: 'How many consecutive months the coupon will apply',
                                                }
                                            },
                                            required: [
                                                'durationInMonths',
                                            ],
                                        },
                                        {
                                            'properties': {
                                                'duration': {
                                                    'const' : 'once'
                                                }
                                            }
                                        },
                                        {
                                            'properties': {
                                                'duration': {
                                                    'const' : 'forever'
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        } }
                        uiSchema={ {
                            active: { 'ui:widget': 'radio' },
                            // oneTime: { 'ui:widget': 'radio' },
                            couponRule: {
                                "ui:help": "Entitled is Bold legacy and can be ignored in most cases"
                            }
                        } }
                    />
                </>

            }
        </Create>
    );
}