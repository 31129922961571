import {Confirm, useNotify, useUpdate} from "react-admin";
import React, {useContext, useState} from "react";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import {Box, Button, CircularProgress} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export const CancelAction = (props) => {
    const notify = useNotify();
    const context = useContext(AdminUserContext);
    const [openCancel, setOpenCancel] = useState(false);
    const record = props.record;
    const newRecord = structuredClone(record);
    newRecord.state = 'cancelled';
    newRecord.next_cyle_date = null;
    const [update] = useUpdate(
        'sales_subscriptions',
        { id: record.id, data: newRecord, previousData: record }
    );
    const handleCancelClick = () => setOpenCancel(true);
    const handleCancelDialogClose = () => setOpenCancel(false);
    const handleCancelConfirm = async () => {
        if (!record.stripe_subscription_id) {
            notify('Failed to cancel subscription');
        } else {
            let updated = await context.cancelSubscription(record.stripe_subscription_id);
            if (updated == true) {
                await update();
                notify("Subscription Cancelled")
            }
            if (Array.isArray(updated)) {
                notify(updated.error_message);
            }
        }
        setOpenCancel(false);
    };

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            sx={ { marginBottom: '20px' } }
        >
            <Button
                variant="contained"
                color="error"
                title="Cancel Subscription" onClick={ handleCancelClick }
                sx={ { height: '40px' } }
            >
                { !openCancel &&
                    <>
                        <span style={{ marginRight: '10px' }}>{ "Cancel Subscription" }</span>
                        <CancelIcon />
                    </>
                }
                {openCancel &&
                    <CircularProgress size={20} color="secondary" />
                }
            </Button>
            <Confirm
                isOpen={ openCancel }
                title="Cancel Subscription"
                content="Are you sure you want to cancel this subscription?"
                onConfirm={ handleCancelConfirm }
                onClose={ handleCancelDialogClose }
            />
        </Box>
    );
}
