import {Confirm, useNotify, useUpdate, useEfect} from "react-admin";
import React, {useContext, useEffect, useState} from "react";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import {
    Box,
    Button,
    CircularProgress,
    Select,
    MenuItem, LinearProgress
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDataProvider } from "react-admin";

function getMonthFromNumber(num) {
    let months = {
        1 : 'January',
        2 : 'February',
        3 : 'March',
        4 : 'April',
        5 : 'May',
        6 : 'June',
        7 : 'July',
        8 : 'August',
        9 : 'September',
        10 : 'October',
        11 : 'November',
        12 : 'December'
    };
    return months[num];
}

function daysInMonth(month, year) {
    let days = {
        'January' : 31,
        'February' : 28,
        'March' : 31,
        'April' : 30,
        'May' : 31,
        'June' : 30,
        'July' : 31,
        'August' : 31,
        'September' : 30,
        'October' : 31,
        'November' : 30,
        'December' : 31
    };
    if (isLeapYear(year)) {
        days['February'] = 29;
    }
    return days[month];
}

function isLeapYear(year) {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}


export const ResumeAction = (props) => {
    const notify = useNotify();
    const context = useContext(AdminUserContext);
    const [update] = useUpdate();
    const [openResume, setOpenResume] = useState(false);
    const [loadingDate, setLoadingDate] = useState(true);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    let loadYear = tomorrow.getFullYear();
    let loadDate = tomorrow.getDate();
    let loadMonth = tomorrow.getMonth() + 1;
    const [resumesDate, setResumesDate] = useState(loadDate);
    const [resumesMonth, setResumesMonth] = useState(loadMonth);
    const [resumesYear, setResumesYear] = useState(loadYear);
    const [resumesDates, setResumesDates] = useState([]);
    const [resumesMonths, setResumesMonths] = useState([]);
    const [resumesYears, setResumesYears] = useState([]);
    const dataProvider = useDataProvider();


    const record = props.record;


    function handleDateChange(value) {
        setResumesDate(value)
    }

    function handleMonthChange(value) {
        setResumesMonth(value)
    }

    function handleYearChange(value) {
        setResumesYear(value)
    }


    const handleResumeClick = () => setOpenResume(true);
    const handleResumeDialogClose = () => setOpenResume(false);
    const handleResumeConfirm = async () => {
        let date = resumesDate + '-' + resumesMonth + '-' + resumesYear;
        let testDate = resumesYear + '-' + resumesMonth + '-' + resumesDate;
        let updated = await context.resumeSubscription(record.stripe_subscription_id, date);
        if (typeof updated === "string") {
            notify(updated, { type: 'error', autoHideDuration: 5000 });
        }

        const newRecord = structuredClone(record);
        newRecord.state = 'active';
        newRecord.next_cycle_date = testDate;
        // debugger;
        await update(
            'sales_subscriptions',
            {id: record.id, data: newRecord, previousData: record}
        );
        notify("Subscription Resumed")
        setOpenResume(false);
    };
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
        >
            <Button
                variant="contained"
                color="success"
                title="Resume Subscription" onClick={ handleResumeClick }
                sx={ { height: '40px' } }
            >
                { !openResume &&
                    <>
                        <span style={{ marginRight: '10px' }}>{ "Resume Subscription" }</span>
                        <RestartAltIcon/>
                    </>

                }
                { openResume &&
                    <CircularProgress size={20} color="secondary" />
                }
            </Button>
            <Confirm
                isOpen={ openResume }
                title="Resume Subscription"
                content="Are you sure you want to resume this subscription?"
                onConfirm={ handleResumeConfirm }
                onClose={ handleResumeDialogClose }
            />
        </Box>
    );
}