import React, { useContext } from 'react';
import {
    SimpleForm,
    TextInput,
    SelectInput,
    TextField,
    BooleanInput, useRefresh, useNotify, useUpdate, useRecordContext
} from 'react-admin';
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import {
    Box,
    Grid,
    Stack
} from '@mui/material';
import { AdminUserContext } from "../../contexts/AdminUserContextProvider";

const fieldItemStyles = {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: '10px'
};

export const AddressEditDialog = (props) => {
    const context = useContext(AdminUserContext);
    const refresh = useRefresh();
    const notify = useNotify();
    const [update] = useUpdate();
    const record = useRecordContext();

    const handleSubmit = async (data) => {
        await update(
            'sales_order_addresses',
            { id: data.id, data: data, previousData: record },
            {
                onSuccess: (data) => {
                    notify("Address Updated");
                    refresh();
                },
                onError: (error) => {
                    notify("Unable to update address", { type: 'error' });
                }
            }
        );
    }

    return (
        <EditInDialogButton
            fullWidth={true}
            maxWidth="lg"
            record={ props.record ?? undefined }
        >
            <SimpleForm onSubmit={handleSubmit}>
                <Grid container spacing={ 2 } sx={ { padding: '20px' } }>
                    <Grid item xs={ 6 }>
                        <Stack spacing={2}>
                            <Box sx={ {
                                ... fieldItemStyles,
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            } }>
                                <span>Id:</span>
                                <TextField label="Id" source="id" />
                            </Box>
                            <Box sx={ fieldItemStyles }>
                                <span>Type:</span>
                                <SelectInput
                                    label={false}
                                    fullWidth={true}
                                    name="address_type"
                                    source="address_type"
                                    choices={context.addressTypes}
                                />
                            </Box>
                            <Box sx={ fieldItemStyles }>
                                <span>Street One:</span>
                                <TextInput
                                    label={false}
                                    fullWidth={true}
                                    name="street1"
                                    source="street1"/>
                            </Box>
                            <Box sx={ fieldItemStyles }>
                                <span>Street Two:</span>
                                <TextInput
                                    label={false}
                                    fullWidth={true}
                                    name="street2"
                                    source="street2"/>
                            </Box>
                            <Box sx={ fieldItemStyles }>
                                <span>Postcode:</span>
                                <TextInput
                                    label={false}
                                    fullWidth={true}
                                    name="postcode"
                                    source="postcode"/>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <Box sx={ fieldItemStyles }>
                            <span>City:</span>
                            <TextInput
                                label={false}
                                name="city" source="city"/>
                        </Box>
                        <Box sx={ fieldItemStyles }>
                            <span>County:</span>
                            <TextInput
                                fullWidth={true}
                                label={false}
                                name="county" source="county"/>
                        </Box>
                        <Box sx={ fieldItemStyles }>
                            <span>Country:</span>
                            <SelectInput
                                fullWidth={true}
                                label={false}
                                name="country"
                                source="country"
                                choices={ context.countryList }
                            />
                        </Box>
                        <Box sx={ fieldItemStyles }>
                            <span>Company:</span>
                            <TextInput
                                label={false}
                                fullWidth={true}
                                name="company"
                                source="company"/>
                        </Box>
                        <Box sx={ fieldItemStyles }>
                            <span>Is Default:</span>
                            <BooleanInput
                                fullWidth={true}
                                label={false}
                                name="isDefault"
                                source="isDefault"
                                helperText="Only Default Addresses Update Stripe"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </SimpleForm>
        </EditInDialogButton>
    );
}