import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React,{ useContext } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField,
    ReferenceInput,
    SelectInput,
    BooleanInput, SelectColumnsButton
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import {
    Stack,
    Box
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import { usePermissionsManager } from "../utils/permissionsManager";

const ListActions = () => {
    const { permissions } = usePermissions();
    const context = useContext(AdminUserContext);
    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        active: booleanFilter({ operators: [ 'eq' ] }),
        value: textFilter({ operators: [ 'eq' ] }),
        coupon_code: textFilter({ operators: [ 'eq' ] }),
        coupon_rule: textFilter({ operators: [ 'eq' ] }),
        discount_type: choicesFilter({
            operators: [ 'eq' ],
            choices: context.subscriptionDiscountTypes
        }),
        one_time: booleanFilter(),
        stripe_id: textFilter({ operators: [ 'eq' ] }),
    };

    return(
        <TopToolbar>
            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_COMMERCE', 'ROLE_SALES_LEAD'] }) &&
                <CreateButton />
            }
            <SelectColumnsButton />
            <StackedFilters config={ filterConfig } />
            <ExportButton />
        </TopToolbar>
    );
}

const SalesCouponCodes = () => {
    const record = useRecordContext();
    const context = useContext(AdminUserContext);

    return (
        <Box
            sx={ { width: '100%', paddingBottom: '10px' } }
        >
            <Box component="h3" style={ { width: '100%' } }>
                <span>Sales Coupon Codes</span>
            </Box>
            <Stack
                sx={ { maxHeight: '600px', overflowY: 'scroll' } }
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                spacing={2}>
                { record?.salesCouponCodes?.map((code, index) => (
                    <Stack
                        sx={ {
                            padding: '10px',
                            margin: '5px',
                            borderBottom: '1px solid lightgray',
                            width: '10%' } }
                        key={ "sales_coupon_codes_" + index }
                        spacing={2}>
                        <Box sx={ { display: 'flex', flexDirection: 'column' } }>
                            <span style={ { fontFamily: 'Aeroport-bold' } }  >{ "Coupon Code" }</span>
                            <span>{ code.couponCode }</span>
                        </Box>
                        <Box sx={ { display: 'flex', flexDirection: 'column', marginTop: '10px' } }>
                            <span style={ { fontFamily: 'Aeroport-bold' } } >{ "Shopify Discount Id" }</span>
                            <span>{ code.shopifyDiscountId }</span>
                        </Box>
                    </Stack>
                )) }
            </Stack>

        </Box>
    );
}

export const SalesCoupon = (prop) => {
    const context = useContext(AdminUserContext);
    return (
        <DefaultList
            resource="sales_coupons"
            actions={ <ListActions/> }
            // expand={ SalesCouponCodes } expandSingle={ true }
            configurableList={true}
            sort={ { field: 'id', order: 'DESC' } }
        >
            <TextField source="id" />
            <BooleanField source="active" />
            <TextField source="value" />
            <TextField source="coupon_code" />
            <SelectField source="discount_type" choices={ context.subscriptionDiscountTypes } />
            <TextField source="duration" sx={ { textTransform: 'capitalize' } } />
            {/*<BooleanField source="one_time" />*/}
            <TextField source="stripe_id" />
            <DateField source="updated_at" />
            <DateField source="created_at" />
            <DateField source="redeemBy" label={ "Expires on" }/>
            <EditButton />
        </DefaultList>
    );

};