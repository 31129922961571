
import React from 'react';
import {
    useRecordContext,
    WithRecord,
} from 'react-admin';
import { ProductControl } from './components/ProductControl';

export const ProductsTab = (props) => {
    // const record = useRecordContext();

    return (
        // <WithRecord label=" " render={
        //     record => {
        //         return(
        //
        //         );
        //     }
        // } />
        <ProductControl
            record={ props.record }
            setLoading={ props.setLoading }
            setRecordId={ props.setRecordId }
            loadFrequencies={ props.loadFrequencies }
            loadCoupons={ props.loadCoupons }
            setFrequencyData={ props.setFrequencyData }
            setCouponData={ props.setCouponData }
            plans={ props.plans }
            openDialog={ props.openDialog }
        />
    );
}