import React, { useState, useEffect, useContext } from 'react';
import 'survey-analytics/survey.analytics.min.css';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics'
import { AdminUserContext } from "../../contexts/AdminUserContextProvider";
import {
    Box,
    CircularProgress,
    Button
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import {
    useNotify,
} from 'react-admin';

const loadingStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
};

const analyticsContainerStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}

const toolbarStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #ccc',
    marginBottom: '10px',
}

const filterStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '10px',
    minHeight: '60px'
}

export const Analytics = (props) => {
    const surveyId = props.id;
    const [loading, setLoading] = useState(true);
    let divId = "surveyVizPanel-" + surveyId;
    const context = useContext(AdminUserContext);
    const [survey, setSurvey] = useState(null);
    const [surveyResults, setSurveyResults] = useState([]);
    const [vizPanel, setVizPanel] = useState(null);
    const today = new Date();
    let todayString = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let fromKey = surveyId + "_fromDate";
    let toKey = surveyId + "_toDate";
    let fromStart = undefined;
    let toStart = undefined;
    let showResetStart = false;
    let startDates = {};
    if (typeof localStorage !== "undefined") {
        let fromD = localStorage.getItem(fromKey);
        if (fromD !== null) {
            fromStart = dayjs(fromD);
            showResetStart = true;
        }
        let toD = localStorage.getItem(toKey);
        if (toD !== null) {
            toStart = dayjs(toD);
        }
        if (fromD !== null && toD !== null) {
            startDates = {
                from: fromD,
                to: toD,
            };
        }

    }

    const [fromDate, setFromDate] = useState(fromStart);
    const [toDate, setToDate] = useState(toStart);
    const [showDateReset, setShowDateReset] = useState(showResetStart);
    const notify = useNotify();

    let surveyModel ;

    function setToDateAction(value) {
        setToDate(value);
    }

    function setFromDateAction(value) {
        setFromDate(value);
    }

    function fetchSurveyAnalytics(dates = {}) {
        context.fetchSurveyAnalytics(surveyId, dates)
            .then(response => {
                // console.log(JSON.parse(response));
                surveyModel = new Model(response.survey);
                setSurvey(surveyModel);
                setSurveyResults(response.data);

                // panel.render(divId);
                setLoading(false);
            });
    }

    function createPanel() {
        const panel = new VisualizationPanel(
            survey.getAllQuestions(),
            surveyResults,
            {
                allowHideQuestions: true,
                allowDynamicText: true,
            }
        );
        panel.showToolbar = false;
        setVizPanel(panel);
    }


    function handleDateFilterAction() {
        if ( toDate === '' || toDate === undefined &&  fromDate === '' || fromDate === undefined ) {
            notify('Please select a from and to date', { type: 'error' });
        } else  if (fromDate === '' || fromDate === undefined) {
            notify('Please select a from date', { type: 'error' });
        } else if (toDate === '' || toDate === undefined) {
            notify('Please select a to date', { type: 'error' });
        }
        if (fromDate !== "" && fromDate !== undefined && toDate !== "" && toDate !== undefined) {
            // debugger;
            let dates = {
                from: fromDate.format('YYYY-MM-DD'),
                to: toDate.format('YYYY-MM-DD'),
            };
            setLoading(true);
            context.fetchSurveyAnalytics(surveyId, dates)
                .then(response => {
                    vizPanel.updateData(response.data);
                    setLoading(false);
                    setShowDateReset(true);
                });
        }
    }

    function handleDateResetAction() {
        context.fetchSurveyAnalytics(surveyId, {})
            .then(response => {
                vizPanel.updateData(response.data);
                setLoading(false);
                setShowDateReset(false);
                setToDate(undefined);
                setFromDate(undefined);
            });
    }


    if (!survey) {
        fetchSurveyAnalytics(startDates);
    }

    if (!!survey && !vizPanel) {
        createPanel();

    }

    useEffect( () => {
        if (typeof localStorage !== "undefined") {

            if (typeof fromDate === "undefined" || typeof toDate === "undefined") {
                localStorage.removeItem(fromKey);
                localStorage.removeItem(toKey);
            } else {
                localStorage.setItem(fromKey, fromDate.format('YYYY-MM-DD'));
                localStorage.setItem(toKey, toDate.format('YYYY-MM-DD'));
            }

        }
    }, [toDate, fromDate] );

    useEffect(() => {
        if (vizPanel) {
            vizPanel.render(divId);
        }
        return () => {
            if (document.getElementById(divId)) {
                document.getElementById(divId).innerHTML = "";
            }
        }

    }, [vizPanel]);

    return (
        <Box>
            { loading &&
                <Box sx={ loadingStyles } >
                    <span>{ "Loading Analytics..." }</span>
                    <CircularProgress />
                </Box>

            }
            <Box sx={ analyticsContainerStyles }>
                { !loading &&
                    <Box sx={ toolbarStyles }>
                        <Box display="flex">
                            { !showDateReset &&
                                <>
                                    <Box sx={ filterStyles }>
                                        <span>{ "From:" }</span>
                                        <LocalizationProvider  dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                // value={ fromDate }
                                                onChange={ (value) => setFromDateAction(value) }
                                                format="DD-MM-YYYY"
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box sx={ filterStyles }>
                                        <span>{ "To:" }</span>
                                        <LocalizationProvider  dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                // value={ toDate }
                                                onChange={ setToDateAction }
                                                format="DD-MM-YYYY"
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box sx={ filterStyles }>
                                        <Button
                                            onClick={ (value) => handleDateFilterAction(value) }
                                            variant="contained"> { "Update" } </Button>
                                    </Box>
                                </>
                            }


                            { ( showDateReset ) &&
                                <Box sx={ filterStyles } >
                                    <span style={ { marginRight: '10px' } }> { "From: " + fromDate.format('DD-MM-YYYY') } </span>
                                    <span> { "To: " + toDate.format('DD-MM-YYYY') } </span>
                                    <Button
                                        onClick={ handleDateResetAction }
                                    >
                                        { "X" }
                                    </Button>
                                </Box>

                            }
                        </Box>
                    </Box>
                }

                <div id={divId}/>
            </Box>



        </Box>
    );
}