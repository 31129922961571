import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {
    BooleanField, Confirm,
    required, SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useDelete,
    useRecordContext,
    SimpleFormIterator, ArrayInput, NumberInput
} from "react-admin";
import React, {useState} from "react";
import {Button, CircularProgress} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';


const choices = [
    { id: 'input', name: 'input' },
    { id: 'select', name: 'select' },
];

const EditToolBar = () => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext();
    const [deleteOne] = useDelete();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = async () => {
        await deleteOne('settings', {
            id: record.id,
            previousData: record
        });
        setOpen(false);
    };
    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton label="Save" />
            { !record.isSystem &&
                <>

                    {/*<DeleteWithConfirmButton*/}
                    {/*    confirmContent=""*/}
                    <Button
                        title="Delete"
                        onClick={ handleClick }
                        sx={ {
                            color: 'red',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        } }>
                        <>
                            { !open &&
                                <>
                                    <DeleteForeverIcon
                                        sx={ { fill: 'red', marginTop: '5px', marginRight: '5px' } } />
                                    <span>{ "Delete" }</span>
                                </>
                            }
                            { open &&
                                <CircularProgress size={20} color="secondary" />
                            }
                        </>
                    </Button>
                    <Confirm isOpen={open}
                             title="Confirm Delete"
                             content="Deleting a Setting could cause an error, are you sure?"
                             onConfirm={ handleConfirm }
                             onClose={ handleDialogClose }
                    />
                </>
            }
        </Toolbar>
    );
}


export const SettingsEditDialog = (props) => {
    const record = useRecordContext();

    return (
        <EditInDialogButton
            fullWidth={true}
            maxWidth="md"
            label={"Edit"}
            sx={ {
                '& .MuiDialog-paper': {
                    height: '90%'
                },
                '& form': {
                    height: '83%',
                },
                '& .MuiCardContent-root': {
                    height: '100%'
                }
            } }
        >
            { record.code !== 'webhook_delays' &&
                <SimpleForm
                    toolbar={  <EditToolBar/>  }
                >
                    <TextInput name="name" source="name"  validate={required()} />
                    <TextInput name="code" source="code"  validate={required()}  />
                    <TextInput name="value" source="value" validate={required()}  />
                    <SelectInput name="type" source="type" choices={ choices }  validate={required()} />
                    <BooleanField source="isSystem" />

                </SimpleForm>
            }

            { record.code === 'webhook_delays' &&
                <JsonSchemaForm
                    schema={{
                        type: 'object',
                        properties: {
                            name: { type: 'string', title: 'Name' },
                            code: { type: 'string', title: 'Code', readOnly: true },
                            type: {
                                type: 'string',
                                title: 'Type',
                                enum: ['input', 'select'],
                            },
                            value: {
                                title: '',
                                type: 'array',
                                items: {
                                    type: 'object',
                                    properties: {
                                        event: { type: 'string', title: 'Event' },
                                        delay: { type: 'number', title: 'Delay' },
                                    }
                                }
                            }
                        }
                    }}
                    />
            }
        </EditInDialogButton>
    );
}