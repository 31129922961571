
// Add XDebug value to local URLs
export const useUrlManagerCleanUrl = ({ url }) => {
    if (typeof url == 'undefined') {
        return url;
    }
    if (window.location.href.includes('local')) {

        if (url.includes('?')) {
            url += '&XDEBUG_SESSION_START=PHPSTORM';
        } else {
            url += '?XDEBUG_SESSION_START=PHPSTORM';
        }

    }

    return url;
}