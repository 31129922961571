import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
} from '@mui/material';
import {
    Confirm,
    useRecordContext,
    useRedirect,
    useNotify, Show, WithRecord
} from 'react-admin';
import { AdminUserContext } from "../../../contexts/AdminUserContextProvider";

const overlayStyles = {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99999,
};

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

const DeleteAction = (props) => {
    const context = useContext(AdminUserContext);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [processing, setProcessing] = useState(false);
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();

    function handleConfirmOpen() {
        setOpenConfirm(true);
    }

    function handleConfirmClose() {
        setOpenConfirm(false);
    }

    async function handleConfirm() {
        setProcessing(true);
        handleConfirmClose();
        await context.gdprDelete(record.id)
            .then(response => {
                if (response === true) {
                    notify('GDPR Delete Successful', { type: 'success' });
                    redirect('list', 'customer_entities');
                } else {
                    setProcessing(false);
                    notify('GDPR Delete Failed', { type: 'error' });
                }
            });
    }

    return (
        <Box>
            <Box sx={ { width: '100%', display: 'flex', justifyContent: 'flex-end', paddingBottom: '20px' } } >
                <Button
                    variant="contained"
                    color="error"
                    onClick={ handleConfirmOpen }
                >
                    <span>GDPR Delete</span>
                </Button>
                <Confirm
                    isOpen={ openConfirm }
                    onClose={ handleConfirmClose }
                    onConfirm={ handleConfirm }
                    title={ "GDPR Data Delete" }
                    content={ "Are you sure you want to permanently delete all data and cancel any subscriptions, this action is irreversible?" }
                />
            </Box>

            { processing &&
                <Box sx={ overlayStyles } >
                    <Box sx={ { display: 'flex', flexDirection: 'column', background: '#fff', padding: '10px', borderRadius: '25px;', alignItems: 'center' } }>
                        <span style={ { marginBottom: '15px', fontSize: '20px' } } >{ "Deleting..." }</span>
                        <CircularProgress />
                    </Box>
                </Box>
            }
        </Box>
    );
}




export const GDPRDelete = (props) => {

    return (
        <Show title=" " actions={false}>
            <span style={sectionTitleStyles}>{"GDPR Data Cleanse"}</span>
            <DeleteAction  />
        </Show>
    );
}