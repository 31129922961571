import React, { useEffect, useState, useContext } from "react";
import { useDataProvider } from 'react-admin';
import {
    useUpdate,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    Stack
} from '@mui/material';
import {
    useGetProductPlanOptions,
} from "../../components/hooks/EditSubscriptionModalHooks";
import { useGetProductPlan } from "../../../Lists/components/hooks/SubscriptionListColumnHooks";
import { ProductTile } from "./ProductTile";
import { AdminUserContext } from "../../../contexts/AdminUserContextProvider";
import {useStripeDataSync} from "../../../Developer/hooks/DeveloperActionHooks";

const itemStyles = {
    display: "flex",
    flexDirection: "column",
}

const titleStyles = {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '10px'
}

/**
 * Main controls for subscription modal plan changes
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ProductControl = (props) => {
    const context = useContext(AdminUserContext);
    const record = props.record;
    const dataProvider = useDataProvider();
    const loadedPlans = props.plans?.data ?? [];
    const loadedSinglePlans = props.plans?.singleItems ?? [];
    const [update, { isPending, error }] = useUpdate();
    const [productPlans, setProductPlans] = useState(loadedPlans);
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [renderPlan, setRenderPlan] = useState(null);
    const [activePlanIds, setActivePlanIds] = useState([]);
    const [activeComponentIds, setActiveComponentIds] = useState([]);
    const [showSinglePanel, setShowSinglePanel] = useState(false);
    const [singleOrderItems, setSingleOrderItems] = useState(loadedSinglePlans);
    const [selectedSingleOrderItems, setSelectedSingleOrderItems] = useState([]);
    const [updatingPlans, setUpdatingPlans] = useState(false);
    const [selectedPlanPrices, setSelectedPlanPrices] = useState(0);
    const setLoadingParent = props.setLoading;
    const setRecordId = props.setRecordId;
    const notify = useNotify();
    const refresh = useRefresh();
    // console.log(record);

    function updateActiveComponentIds() {
        let ids = activeComponentIds;
        let tmpIds = [];
        for(const sPlan of selectedPlan) {
            let id = sPlan.id;
            let allIds = activePlanIds;
            if (!allIds.includes(id)) {
                allIds.push(id);
                setActivePlanIds(allIds);
            }

            let components = sPlan.components;
            for (let component of components) {
                let name = component.name;
                if (name.includes('Spray')) {
                    name = name.replace('Spray', '').trim();
                }
                if (name.includes('Oral')) {
                    name = name.replace('Oral', '').trim();
                }

                if (name === 'Combo') {
                    if (!tmpIds.includes('Minoxidil')) {
                        tmpIds.push('Minoxidil');
                    }
                    if (!tmpIds.includes('Finasteride')) {
                        tmpIds.push('Finasteride');
                    }
                } else if(!tmpIds.includes(name)) {
                    tmpIds.push(name);
                }
            }
        }
        if (ids.toString() !== tmpIds.toString() ) {
            setActiveComponentIds(tmpIds);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            if (props.openDialog === true) {
                let plans = props.plans ?? [];
                if (productPlans.length <= 0) {

                    // plans =  await useGetProductPlanOptions();
                    plans = context.productsData;
                    // console.log(plans);
                    if (plans.length > 0) {
                        setProductPlans(plans);
                        setSingleOrderItems(plans.singles);
                    }
                }

                let usePlan = await useGetProductPlan({ id: record.id });
                if (usePlan && usePlan.status === 'success') {
                    setSelectedSingleOrderItems(usePlan.singles)
                    if (usePlan.data.length) {
                        setSelectedPlan(usePlan.data);
                        let totalPlanPrice = 0;
                        for(const p of usePlan.data) {
                            for (const child of p.children) {
                                if (child.name === record.stripeFrequency) {
                                    totalPlanPrice += child.price;
                                }
                            }
                        }
                        setSelectedPlanPrices(totalPlanPrice);
                        let plan = plans.find((plan) => plan.id === usePlan.data[0].id);
                        if (plan) {
                            setRenderPlan(plan);
                        }
                    }
                }
            }
        }
        loadData()
            .then(result => {
                setLoading(false);
            });

    }, [props.openDialog]);

    if (loading) {
        return (
            <Box sx={
                {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center' ,
                    flexDirection: 'column'
                }
            }>
                <span style={ { marginBottom: '20px' } } >{ "Loading Products..." }</span>
                <LinearProgress sx={ { width: '90%' } } />
            </Box>
        );
    }
    updateActiveComponentIds();

    async function handleChangePlan() {
        setUpdatingPlans(true);
        let data = structuredClone(record);
        let stripeFrequency = record.stripeFrequency;
        let newIds = [];
        for(const sPlan of selectedPlan) {
            for(const child of sPlan.children) {
                if (child.name === stripeFrequency) {
                    newIds.push(child.id);
                }
            }
        }

        let ids = [];
        for(const single of selectedSingleOrderItems) {
            ids.push(single.id);
        }
        newIds = newIds.concat(ids);

        if (newIds.length > 0) {
            data.products = newIds;
        }

        try {
            let result = await dataProvider.update(
                'sales_subscriptions',
                { id: data.id, data: data, previousData: record }

            ).then(async function(response) {
                setLoadingParent(true);
                setUpdatingPlans(false);
                props.loadFrequencies(data.id)
                    .then(result => {
                        if (result && result.status === 'success') {
                            props.setFrequencyData(result.data)
                        }
                    })
                props.loadCoupons()
                    .then(data => {
                        if (data.status === 'success') {
                            props.setCouponData(data.data)
                        }
                    });
                setRecordId(data.id);
            });
        } catch (error) {
            notify(error, { type: 'error', autoHideDuration: 5000 });
            setLoadingParent(true);
            setUpdatingPlans(false);
            setRecordId(data.id);
        }

    }

    function handleSelectedPlan(id) {
        // debugger;
        let plan = productPlans.find((plan) => plan.id === id);
        let currentPlans = selectedPlan;
        let removed = false;
        let activeIds = activePlanIds;
        for(let i = 0; i < currentPlans.length; i++) {
            let pl = currentPlans[i];
            if (pl.id === id) {
                currentPlans.splice(i, 1);
                removed = true;
            }
        }

        if (removed) {
            for (let j = 0; j < activeIds.length; j++) {
                let plan = activeIds[j];
                if (plan === id) {
                    activeIds.splice(j, 1);
                }
            }
            if (currentPlans.length > 0) {
                setRenderPlan(currentPlans[0]);
            } else {
                setRenderPlan(null);
            }
        }
        if (!removed) {
            currentPlans.push(plan);
            if (!activeIds.includes(plan.id)) {
                activeIds.push(plan.id);
            }
            setRenderPlan(plan);
        }
        let totalPlanPrice = 0;
        for(const p of currentPlans) {
            for (const child of p.children) {
                if (child.name === record.stripeFrequency) {
                    totalPlanPrice += child.price;
                }
            }
        }
        setSelectedPlanPrices(totalPlanPrice);
        setSelectedPlan(currentPlans);
        setActivePlanIds(activeIds);
        updateActiveComponentIds();
        return activeIds;
    }

    function handleSingleItem(item) {
        let singleItems = selectedSingleOrderItems;
        let removed = false;
        for(let i = 0; i < singleItems.length; i++) {
            if (singleItems[i] === item.id) {
                singleItems.splice(i, 1);
                removed = true;
            }
        }

        if (!removed && !singleItems.includes(item)) {
            singleItems.push(item);
        }
        setSelectedSingleOrderItems(singleItems);
    }

    function hideShowSinglePanel() {
        setShowSinglePanel(!showSinglePanel);
    }

    return (
        <Box>
            { productPlans.length <= 0 &&
                <Stack spacing={2}>
                    <span>{ "No Products found" }</span>
                </Stack>
            }
            { productPlans.length > 0 &&
                <Box
                    display="flex"
                >
                    <Box
                        sx={ {
                                width: '75%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                height: '100%',
                                maxHeight: '775px',
                                overflowY: 'auto'
                            } }  >
                        { productPlans.map((plan, index) => {


                            return (
                                <ProductTile
                                    key={ "subscription_plan_tile_" + index }
                                    plan={ plan }
                                    renderPlan={renderPlan}
                                    activePlanIds={activePlanIds}
                                    handleChangePlan={handleChangePlan}
                                    handleSelectedPlan={handleSelectedPlan}
                                    activeComponentIds={activeComponentIds}
                                    selectedPlan={selectedPlan}
                                    record={ record }
                                />
                            );
                        })}
                    </Box>
                    <Box
                        sx={ {
                                width: '25%',
                            } }  >
                        <span style={ {
                                width: '100%',
                                marginBottom: '16px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                display: 'block'
                            } } >
                            { "Details" }
                        </span>
                        <Box
                            sx={ { width: "100%", textAlign: 'center' } }
                        >
                            <span style={ {
                                width: '100%',
                                marginBottom: '16px',
                                fontWeight: '400',
                                display: 'block'
                            } }>
                                { "Estimated plan(s) price: £" + selectedPlanPrices.toFixed(2) + ' for frequency ' + record.stripeFrequency }
                            </span>
                        </Box>
                        { (renderPlan !== null && showSinglePanel === false) &&
                            <Stack spacing={2} sx={ { padding: '10px', height: 'calc(100% - 40px)' } }>
                                <Box sx={ itemStyles }>
                                    <span style={ titleStyles }>{ "Name:" }</span>
                                    <span>{ renderPlan.name }</span>
                                </Box>
                                <Box sx={ itemStyles }>
                                    <span style={ titleStyles }>{ "Efficacy:" }</span>
                                    <span>{ renderPlan.efficacy + '%' }</span>
                                </Box>
                                <Box sx={ itemStyles }>
                                    <span style={ titleStyles }>{ "Base Type:" }</span>
                                    <span>{ renderPlan.base }</span>
                                </Box>
                                <Box sx={ itemStyles }>
                                    <span style={ titleStyles }>{ "From Price:" }</span>
                                    <span>{ '£' + renderPlan.price }</span>
                                </Box>
                                { renderPlan.components.length > 0 &&
                                    <Box sx={itemStyles}>
                                        <span style={titleStyles}>{"Plan Products:"}</span>
                                        <ul>
                                            {renderPlan.components.map(comp => {
                                                return (
                                                    <li key={ "plan_component_" + comp.id }>
                                                        <span>{ comp.name }</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                    </Box>
                                }
                                { (activePlanIds.includes(renderPlan.id)) &&
                                    <Box sx={ itemStyles }>
                                        <span
                                            style={ {
                                                    width: '100%',
                                                    height: '50px',
                                                    color: '#133132',
                                                    padding: '8px',
                                                    background: '#eefad5',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '5px',
                                                    border: '1px solid #133132',
                                                    fontSize: '20px'
                                                } } >
                                            { "Active Plan" }
                                        </span>
                                    </Box>
                                }
                                <Box sx={ {
                                    ...itemStyles,
                                    marginTop: 'auto !important'
                                } }>
                                    {/*<Button*/}
                                    {/*    onClick={ hideShowSinglePanel }*/}
                                    {/*    variant="outlined"*/}
                                    {/*    color="warning"*/}
                                    {/*    sx={ { marginBottom: '10px;' } }*/}
                                    {/*>*/}
                                    {/*    <span>{ 'Show Single Items' }</span>*/}
                                    {/*</Button>*/}
                                    <Button
                                        onClick={ handleChangePlan }
                                        variant="contained"
                                        color="success"
                                        disabled={ updatingPlans }
                                    >
                                        { updatingPlans &&
                                            <CircularProgress sx={ { width: '20px', marginRight: '10px' } } />
                                        }
                                        <span>{ 'Update' }</span>
                                    </Button>
                                </Box>

                            </Stack>
                        }
                        { showSinglePanel  &&
                            <Stack spacing={2} sx={ { padding: '10px 0 0 10px', height: '80%' } }>
                                <span style={ { width: '100%', textAlign: 'center' } } >{ "Add single purchase item" }</span>
                                <Box sx={ {
                                    ...itemStyles
                                } }>
                                    <Stack spacing={2} sx={ { height: '80%', overflowY: 'auto' } }>
                                        { singleOrderItems.map((item, index) => {

                                            return (
                                                <SingleProductTile
                                                    key={ "single_order_item_" + index }
                                                    item={item}
                                                    handleSingleItem={handleSingleItem}
                                                    selectedSingleOrderItems={ selectedSingleOrderItems }
                                                />
                                            );
                                        })  }
                                    </Stack>
                                </Box>
                                <Box sx={ {
                                    ...itemStyles,
                                    marginTop: 'auto !important'
                                } }>
                                    {/*<Button*/}
                                    {/*    onClick={ hideShowSinglePanel }*/}
                                    {/*    variant="outlined"*/}
                                    {/*    color="warning"*/}
                                    {/*    sx={ { marginBottom: '10px;' } }*/}
                                    {/*>*/}
                                    {/*    <span>{ 'Hide Single Items' }</span>*/}
                                    {/*</Button>*/}
                                    <Button
                                        onClick={ handleChangePlan }
                                        variant="contained"
                                        color="success" >
                                        <span>{ 'Update' }</span>
                                    </Button>
                                </Box>
                            </Stack>
                        }
                    </Box>
                </Box>
            }
        </Box>
    );
}

