import React, { useState, useEffect, useContext } from 'react';
import {
    Edit,
    Show,
    required,
    SimpleForm,
    TextInput,
    useRecordContext,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    DateField,
    ReferenceField,
    BooleanField,
    WithRecord,
    ReferenceManyField,
    Datagrid,
    Form,
    SaveButton,
    useGetRecordId,
    useGetOne,
    NumberInput,
    useRefresh,
    useNotify,
    SelectInput
} from 'react-admin';
import {
    Box,
    CircularProgress,
    Grid,
    Stack,
    Link,
    LinearProgress,
    Select,
    TextFueld,
    Input,
    Button
} from '@mui/material';
import { AdminUserContext } from "../../../contexts/AdminUserContextProvider";
import { useForm, FormProvider, useFormContext } from "react-hook-form";


const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    padding: '20px',
    marginTop: '16px',
    borderRadius: '4px',
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
};

const processingBoxStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '35.6px',
    background: '#eee',
    width: '100%'
};

const refundedBoxStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '35.6px',
    width: '100%',
    fontSize: '18px',
};

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

const validateRefundForm = (values) => {
    const errors = {};
    if (values.refundAmount <= 0) {
        errors.refundAmount = 'Refund amount must be greater than 0';
    }
    if (values.reason === '' || values.reason === null) {
        errors.reason = 'Reason is required';
    }
    return errors;
}

export const RefundForm = (props) => {
    const context = useContext(AdminUserContext);
    const notify = useNotify();
    const [processing, setProcessing] = useState(false);
    const [orderRefunded, setOrderRefunded] = useState(false);
    const [refundMessage, setRefundMessage] = useState("This order's balance has been fully refunded");
    const refresh = useRefresh();
    const {
        formState: { errors, dirtyFields, isLoading },
        methods,
        register,
        handleSubmit,
        reset,
        setValue,
        getValues
    } = useForm({
        defaultValues: {
            reason: '', // Default initial value for "reason" field
            refundAmount: 0, // Default initial value for "refundAmount" field
            notes: ''
        },
        shouldUnregister: true
    });

    const recordId = useGetRecordId();
    const { data: record, isPending, error } = useGetOne('sales_orders', { id: recordId });

    async function handleFormSubmit(values) {
        setProcessing(true);
        await context.processOrderRefund(recordId, values)
            .then(response => {
                console.log(response);;
                if (response.success === true) {
                    notify(response.message, { type: 'success' });
                    reset();
                    refresh();
                }

                if (response.error === true) {
                    notify(response.message, { type: 'error' });
                }

                setProcessing(false);
            });
    }


    let maxAmount = undefined;
    if (record) {
        maxAmount = record.grand_total - (record.refundAmount ?? 0);
        maxAmount = maxAmount.toFixed(2);
        if (getValues("refundAmount") <= 0) {
            setValue('refundAmount', maxAmount);
        }

    }

    useEffect( () => {
        if (record) {
            if (record.refundAmount === record.grand_total || record.grand_total === 0) {
                if (record.grand_total === 0) {
                    setRefundMessage("Free order - ineligible for refund");
                }
                setOrderRefunded(true);
            }

        }
    }, [record] );

    return (
        <Box
            sx={ formStyles }
        >
            { orderRefunded &&
                <Box sx={ refundedBoxStyles } >
                    <span>{ refundMessage }</span>
                </Box>
            }

            { !orderRefunded &&
                <FormProvider { ...methods }>
                    <form
                        onSubmit={handleSubmit(handleFormSubmit) }

                    >
                        <Stack spacing={ 2 }>
                            <span style={ sectionTitleStyles }>{ "Apply a refund" }</span>
                            <Box
                                sx={ props.labelStyles } >
                                <span>{ "Reason *" }</span>
                                <Box sx={ { display: 'flex', flexDirection: 'column' } }>
                                    <select
                                        style={ { fontFamily: 'Aeroport', fontSize: '16px' } }
                                        { ...register('reason', { required: true }) }>
                                        <option value="">{ "Please select an option..." }</option>
                                        { props.refundChoices.map( (choice, index) => {

                                            return (
                                                <option key={ index } value={ choice.id }>{ choice.name }</option>
                                            )
                                        } ) }
                                    </select>
                                    { errors.reason && <p style={ { color: 'red' } }>Please select a reason</p> }
                                </Box>

                            </Box>
                            <Box
                                sx={ props.labelStyles } >
                                <span>{ "Refund Amount (£) *" }</span>
                                <Box sx={ { display: 'flex', flexDirection: 'column' } }>
                                    <input
                                        style={ { fontFamily: 'Aeroport', fontSize: '16px' } }
                                        { ...register('refundAmount', {
                                            required: true,
                                            min: 0,
                                            max: maxAmount,
                                            validate: (value) => parseFloat(value) > 0
                                        }) }
                                        step="0.01"
                                        type="number" />
                                    { errors.refundAmount && <p style={ { color: 'red' } }>Please set an amount above 0</p> }
                                </Box>

                            </Box>
                            <Box
                                sx={ {
                                    ...props.labelStyles,
                                    flexDirection: 'column'
                                } } >
                                <span>{ "Notes" }</span>
                                <textarea
                                    { ...register('notes', { required: false }) }
                                    name="notes"
                                    rows="8"
                                    style={ { resize: 'none', fontFamily: 'Aeroport', fontSize: '16px' } }
                                />
                            </Box>

                            { !processing &&
                                <Button
                                    style={ { fontFamily: 'Aeroport', fontSize: '16px' } }
                                    disabled={ processing }
                                    type="submit"
                                    variant="contained"
                                >
                                    <span>{ "Refund" }</span>
                                </Button>
                            }

                            { processing &&
                                <Box sx={ processingBoxStyles } >
                                    <CircularProgress size={ 25 } />
                                </Box>
                            }
                        </Stack>
                    </form>
                </FormProvider>
            }
        </Box>
        );
    }
