import { jwtDecode } from "jwt-decode";
import { hostUrl } from "../config";
import { useUrlManagerCleanUrl } from "../utils/UrlManager";

export const storeToken = (token) => {
    const decodedToken = jwtDecode(token);
    localStorage.setItem("token", token);
    localStorage.setItem("groups", JSON.stringify(decodedToken.roles));
    return decodedToken.id;
};

export const getUser = async (id) => {
    return dataProvider.getOne("users", { id: `/api/users/${id}` });
};

const isAuthenticated = () => {
    const token = localStorage.getItem("token");

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp && typeof decodedToken.exp === "number") {
            if (Date.now() < decodedToken.exp * 1000) {
                return decodedToken;
            }
        }
    }

    return false;
};

const removeStoredData = () => {
    if (!localStorage) {
        return;
    }
    localStorage.removeItem("auth");
    localStorage.removeItem("profile");
    // localStorage.removeItem("groups");
    localStorage.removeItem('stored_k');
    localStorage.removeItem('stored_s');
    localStorage.removeItem('stored_data');
};

export const authProvider = {


    login: async ({ username, password }) => {
        let url = hostUrl + "admin/user/login";
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });

        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                //TODO - do something
                return Promise.reject(response);
            }
            const data = await response.json();
            const token = data.token;
            if (typeof token == 'string' && token.startsWith('bsa_')) {
                localStorage.setItem("auth", token);
                // localStorage.setItem("groups", JSON.stringify(data.roles));
                localStorage.setItem('profile', JSON.stringify({
                    id: data.id,
                    fullName: data.name,
                    avatar:  data.avatar !== "" ? hostUrl + data.avatar : undefined,
                }));
            }
        } catch (error) {
            console.error(error);
        }
        return Promise.resolve();

    },
    getIdentity: () => {
        try {
            const profile = localStorage.getItem("profile");
            if (profile) {
                // const ava
                return JSON.parse(profile)
            } else {
                return Promise.reject()
                    .catch(error => {
                        console.log(error);
                    });
            }
        } catch (error) {
            return Promise.reject( );
        }
    },
    logout:  async () => {
        removeStoredData();
        return Promise.resolve();
    },
    checkAuth: async () => {
        let auth = localStorage.getItem("auth") ?? null;
        if (auth == null) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkError: async (error) => {
        // debugger;
        // const status = error?.status || error?.response?.status;
        // if (status === 401 || status === 403) {
        //     removeStoredData();
        // }
        const status = error?.status || error?.response?.status;
        if (status >= 400) {
            return Promise.reject( { redirectTo: '/login' } );
        }
        return Promise.resolve();
    },
    getPermissions: async () => {
        const {id, fullName, avatar} = JSON.parse(localStorage.getItem('profile'));
        const token = localStorage.getItem("auth");
        let url = hostUrl + "barber/admin/user/permissions";
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token' : token,
                'Barbershop' : 'admin',
            })
        });
        try {
            const response = await fetch(request);
            return await response.json();
        } catch (error) {
            console.log(error);
        }
        return Promise.reject(  );
    }
};
