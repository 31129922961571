import React, { useState } from 'react';
import {
    Grid,
} from '@mui/material';
import {
    Title,
    useAuthProvider
} from 'react-admin';
import { DashboardSubscriptionList } from "../Lists/DashboardSubscriptionList";
import { DashboardInfoTab } from "./components/DashboardInfoTab";

export const Dashboard = () => {
    const auth = useAuthProvider();
    // if (auth) return null;
    const { id, fullName, avatar } = auth.getIdentity();

    let titleName = 'Dashboard';
    if (fullName) {
        titleName += ' - Hello ' + fullName;
    }

    return (
        <>
            <Title title={ titleName } />
            <Grid container sx={ { padding: '40px', background: '#fff', width: "calc(100% - 40px)" } }>
                <Grid item xs={12} >
                    <DashboardSubscriptionList />
                </Grid>
            </Grid>
        </>


    );
}