import {
    usePermissions
} from 'react-admin';

export const usePermissionsManager = ({ accessToRoles }) => {
    const { permissions } = usePermissions();
    let canAccess = false;
    if (Array.isArray(accessToRoles))  {
        for (const permission of accessToRoles) {
            if (permissions && permissions.includes(permission)) {
                canAccess = true;
                break;
            }
        }
    } else {
        canAccess = (permissions && permissions.includes(accessToRoles))
    }

    return canAccess;
}