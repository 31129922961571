import React from "react";
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    TextField,
    useRecordContext,
    WithRecord,
    Show,

} from "react-admin";
import { SubscriptionListProductColumn } from "../../../Lists/components/SubscriptionListProductColumn";
import { SubscriptionMainDialogEdit } from "../../Dialog/SubscriptionMainDialogEdit";
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import { AddressEditDialog } from "../../Dialog/AddressEditDialog";

import {
    Box,
    Grid,
    Stack
} from "@mui/material";

const fieldItemStyles = {
    display: "flex",
    justifyContent: "space-between",
};

const PreStyles = {
    minHeight: '40px',
    border: 'none',
    maxWidth: '100%'
};

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

const ActivityMonitorList = (props) => {
    const record = useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <List
            title=" "
            actions={ false }
            storeKey={"customer_edit_activity_monitor_events"}
            perPage={ 5 }
            resource="activity_monitors"
            filter={ { "customer.id": record.id } }
            sort={ { field: 'id', order: 'DESC' } } >
            <Datagrid
                bulkActionButtons={false}
            >
                <TextField source="id" />
                <DateField showTime={ true } label="Date" source="createdAt"/>
                <TextField label="Action" source="eventType"/>
                <WithRecord label="Type" render={
                    rec => {
                        let type = 'Customer';
                        if (rec.subscription) {
                            type = 'Subscription';
                        } else if(rec.salesOrderAddress) {
                            type = 'Address';
                        }
                        return (
                            <span>{ type }</span>
                        );
                    }
                } />
                <ReferenceField
                    label="Plans"
                    source="subscription"
                    reference="sales_subscriptions"
                    link={false}
                >
                    <WithRecord label=" " render={
                        rec => {
                            return (
                                <SubscriptionListProductColumn record={rec}/>
                            );
                        }
                    }/>
                </ReferenceField>

                <ReferenceField
                    label="Frequency"
                    source="subscription"
                    reference="sales_subscriptions"
                    link={false}
                >
                    <TextField source="stripeFrequency" />
                </ReferenceField>

                <ReferenceField
                    label="Address"
                    source="salesOrderAddress"
                    reference="sales_order_addresses"
                    link={false}
                >
                    <WithRecord label=" " render={
                        record => {

                            return (
                                <AddressEditDialog record={record} />
                            );
                        }
                    } />
                </ReferenceField>

                <ReferenceField
                    label="Subscription"
                    source="subscription"
                    reference="sales_subscriptions"
                    link={false}
                >
                    <SubscriptionMainDialogEdit />
                </ReferenceField>
                <WithRecord label="Changed By" render={
                    rec => {
                        if(rec.systemChange) {
                            return (
                                <span>System</span>
                            );
                        }

                        if (rec.adminUser) {
                            return (
                                <ReferenceField
                                    label=""
                                    source="adminUser"
                                    reference="admin_users"
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                            );
                        }
                        return (
                            <span>Customer</span>
                        );
                    }
                } />

                <ShowInDialogButton
                    title=" "
                    label="View More"
                    fullWidth={true}
                    maxWidth="lg"
                >
                    <Grid container spacing={2} sx={ { padding: '16px' } }>
                        <Grid item xs={6}>
                            <Stack spacing={2}>
                                <Box sx={ fieldItemStyles }>
                                    <span>Id:</span>
                                    <TextField label="Id" source="id" />
                                </Box>
                                <Box sx={ fieldItemStyles }>
                                    <span>Date:</span>
                                    <DateField label="Date" source="createdAt"/>
                                </Box>
                                <Box sx={ fieldItemStyles }>
                                    <span>Action:</span>
                                    <TextField label="Action" source="eventType"/>
                                </Box>
                                <Box sx={ fieldItemStyles }>
                                    <span>Message:</span>
                                    <TextField label="Message" source="message"/>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={2}>
                                <Box sx={ fieldItemStyles }>
                                    <span>Data:</span>
                                    <WithRecord label=" " render={
                                        record => {
                                            let val = record.data ?? record.message;
                                            val = JSON.stringify(val, null, 2);
                                            return(
                                                <pre
                                                    style={ PreStyles }
                                                >
                                                { val }
                                            </pre>
                                            );
                                        }
                                    } />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </ShowInDialogButton>

            </Datagrid>
        </List>
    )
}

export const CustomerActivity = (props) => {
    return(
        <Show title=" " actions={false}>
            <span style={sectionTitleStyles}>{"Customer's Action History"}</span>
            <ActivityMonitorList />
        </Show>
    );
}