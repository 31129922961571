import { hostUrl } from "../../../config";
import { useUrlManagerCleanUrl } from "../../../utils/UrlManager";

export const useGetProductPlan = async({ id }) => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/product/plan/lookup';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    });
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}