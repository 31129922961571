import { hostUrl } from "../../../config";
import { useUrlManagerCleanUrl } from "../../../utils/UrlManager";

export const useTodaysChartData = async () => {
    let returnData = [];
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/dashboard/charts/today';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "GET",
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',

        })
    });

    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }

}


export const useWeekChartData = async () => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/dashboard/charts/week';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "GET",
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    })
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const useMonthChartData = async () => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/dashboard/charts/month';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "GET",
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    })
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}