// import { feBase } from "../../../config";
import React, { useEffect, useState} from "react";
import {
    Box
} from '@mui/material';
import { getFeBaseUrl } from "../../../../../../../utils/UrlManager";



/**
 * Component to control and render subscription plan tiles
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ProductTile = (props) => {
    const [selectedPlanItem, setSelectedPlanItem] = useState(false);
    const [disabledTile, setDisabledTile] = useState(false);
    const [tileMessage, setTileMessage] = useState('');
    const plan = props.plan;
    const selectedPlan = props.selectedPlan;
    const activePlanIds = props.activePlanIds;
    const activeComponentIds = props.activeComponentIds;
    const record = props.record;
    let disabledMessage ;
    let feBase = getFeBaseUrl()

    if (selectedPlan && selectedPlanItem == false) {
        for(const p of selectedPlan) {
            if (p.id === plan.id) {
                setSelectedPlanItem(true);
            }
        }
    }

    useEffect(() => {
        let disabledItem = false;
        for (const planComps of plan.components) {
            let name = planComps.name;
            if (name.includes('Spray')) {
                name = name.replace('Spray', '').trim();
            }
            if (name.includes('Oral')) {
                name = name.replace('Oral', '').trim();
            }
            if (!activePlanIds.includes(plan.id) && activeComponentIds.includes(name)) {
                disabledItem = true;
            }
            if (name === 'Combo' && !activePlanIds.includes(plan.id) &&
                (activeComponentIds.includes('Minoxidil') ||
                    activeComponentIds.includes('Finasteride') )) {
                disabledItem = true;
            }
        }
        let validFrequency = false;
        for (const child of plan.children) {
            if (child.name === record.stripeFrequency) {
                validFrequency = true;
            }
        }

        if (!validFrequency) {
            disabledItem = true;
            setTileMessage('Unavailable For Frequency');
        }

        setDisabledTile(disabledItem);
    }, [ props.activeComponentIds ])

    function handlePlanClick() {
        if (disabledTile) {
            return;
        }
        let activeIds = props.handleSelectedPlan(plan.id);
        if (activeIds.includes(plan.id)) {
            setSelectedPlanItem(true);
        } else {
            setSelectedPlanItem(false);
        }

    }

    return (
        <Box
            sx={ {
                    width: '200px',
                    height: '200px'
                } } >
            <Box sx={ {
                    width: 'calc(100% - 2px)',
                    height: 'calc(100% - 2px)',
                    border: selectedPlanItem ? '1px solid #ed6c02' : '1px solid #eee',
                    '&:hover' : {
                        border: (disabledTile ? '' : '1px solid #ed6c02')
                    },
                    position: 'relative'
                } }
                 onClick={ handlePlanClick } >
                <Box sx={ {
                        width: 'calc(100% - 10px)',
                        height: 'calc(80% - 10px)',
                        padding: '5px',

                    } }>
                    <img
                        style={
                            {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }
                        }
                        src={ feBase + plan.image.file_path } alt={plan.name}/>
                </Box>
                <Box sx={ {
                        width: '100%',
                        height: '20%',
                        background: 'rgba(255,255,255,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '12px'
                    } }>
                    <span> { plan.name } </span>
                </Box>
                { disabledTile &&
                    <Box
                        sx={ {
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                background: 'rgba(130,131,128,0.8)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            } } >
                        { (tileMessage !== '') &&
                            <span
                                style={ {
                                        color: '#133132',
                                        fontSize: '16px',
                                        background: '#fff',
                                        padding: '5px',
                                        border: '1px solid #000',
                                        borderRadius: '5px'
                                    } } >
                                { tileMessage }
                            </span>
                        }

                    </Box>
                }
                { (props.activePlanIds.includes(plan.id)) &&
                    <Box
                        sx={ {
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                background: 'rgba(238, 250, 213, 0.8)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            } } >
                        <span
                            style={ {
                                    color: '#133132',
                                    fontSize: '16px',
                                    background: '#fff',
                                    padding: '5px',
                                    border: '1px solid #000',
                                    borderRadius: '5px'
                                } } >
                            { "Active Plan" }
                        </span>
                    </Box>
                }
            </Box>
        </Box>
    );
}