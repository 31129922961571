import React, { useContext, useState } from 'react';
import {
    Edit,
    SimpleForm,
    useRecordContext,
    WithRecord,
    ReferenceManyField,
    Datagrid,
    EditButton,
    TextField,
    ReferenceField,
    DateInput,
    SelectField,
    DateField,
    Show,
    ShowButton,
    BooleanField,
    Button,
    useUpdate,
    useNotify,
    Toolbar,
    useDelete,
    SaveButton,
    Confirm,
    FormDataConsumer,
    useRefresh,
    List, usePermissions,
    TopToolbar,
    useGetOne
} from 'react-admin';
import {
    Box,
    Grid,
    CircularProgress,
    Switch
} from '@mui/material';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import { stripeMode } from "../config";
import { CancelAction } from "../Defaults/Subscriptions/CancelAction";
import { PauseAction } from "../Defaults/Subscriptions/PauseAction";
import { ResumeAction } from "../Defaults/Subscriptions/ResumeAction";
import { PaymentLinkAction } from "../Defaults/Subscriptions/PaymentLinkAction";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ReferenceManyToManyInput, DualListInput, ReferenceManyInput } from "@react-admin/ra-relationships";
import { TreeInput } from "@react-admin/ra-tree";
import {booleanFilter, numberFilter, StackedFilters, textFilter} from "@react-admin/ra-form-layout";

const EditSubscriptionTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{ "Subscription " + record.id }</span>
}

const fieldItemTStyles = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '10px'
};

const stripeControlStyles = {
    width: '100%',
    background: '#fff',
    padding: '16px;'
}

function resetTreeData(data) {
    data.forEach((item) => {
        if (item.disableCheckbox) {
            delete item.disableCheckbox;
            // /item.disableCheckbox = false;
        }
    })
    return data;
}

const CustomToolBar = () => {
    const context = useContext(AdminUserContext);
    const [open, setOpen] = useState(false);
    const record = useRecordContext();
    const [deleteOne] = useDelete();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = async () => {
        let updated = await context.cancelSubscription(record.stripe_subscription_id);
        if (updated) {
            await deleteOne('sales_subscriptions', {
                id: record.id,
                previousData: record
            });
        }
        setOpen(false);
    };
    return (
        <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton label="Save" />
            <>
                <Button
                    title="Resume Subscription"
                    onClick={ handleClick }
                    sx={ {
                        color: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    } }>
                    <>
                        { !open &&
                            <>
                                <DeleteForeverIcon  sx={ { fill: 'red', marginTop: '5px', marginRight: '5px' } } /> <span>{ "Delete" }</span>
                            </>
                        }
                        { open &&
                            <CircularProgress size={20} color="secondary" />
                        }
                    </>

                </Button>
                <Confirm isOpen={open}
                         title="Confirm Delete"
                         content="Deleting a subscription will also cancel if applicable, are you sure?"
                         onConfirm={ handleConfirm }
                         onClose={ handleDialogClose }
                />
            </>

        </Toolbar>
    );
}



// onChange={ () => phandleClick(innerRecord) }

const AddCouponCodeAction = (props) => {
    let outerRecord = props.outerRecord;
    // debugger;
    const notify = useNotify();
    const [update, { isPending, error }] = useUpdate();
    const starterIds = (Array.isArray(outerRecord.coupons) && outerRecord.coupons.length > 0) ? outerRecord.coupons : [];
    const [ids, setIds] = useState(starterIds);
    const [selectedId, setSelectedId] = useState(null);
    const refresh = useRefresh();

    async function handleClick(innerRecord, event) {

        let recordId =  innerRecord.id.toString();
        setSelectedId(recordId);
        let allIds = ids;
        let willUpdate = false;
        let added = false;
        if (!allIds.includes(recordId)) {
            willUpdate = true;
            allIds.push(recordId);
            setIds(allIds);
        }

        if (allIds.includes(recordId) && !event.target.checked ) {
            let recordIdLocation = allIds.indexOf(recordId);
            if (recordIdLocation !== -1) {
                allIds.splice(recordIdLocation, 1);
                setIds(allIds);
                willUpdate = true;
                added = true;
            }

            // console.log(ids);
        }


        if (willUpdate) {
            const newRecord = structuredClone(outerRecord);
            newRecord.coupons = allIds;
            console.log(newRecord);
            const updated = await update(
                'sales_subscriptions',
                { id: newRecord.id, data: newRecord, previousData: outerRecord }
            );
            console.log(updated);
            if (error) {
                // console.log(error);
                notify('Coupon update failed', { type: 'error' });
            } else {
                notify('Coupon Updated', { type: 'success' });
                // setTimeout(function(){
                //     window.location.reload();
                // }, 500)
            }
            outerRecord.coupons = allIds;
        }
    }

    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        coupon_code: textFilter({ operators: [ 'eq' ] }),
        value: numberFilter({ operators: [ 'eq' ] }),
    };

    const ListActions = () => {
        const { permissions } = usePermissions();
        return(
            <TopToolbar>
                <StackedFilters config={ filterConfig } />
            </TopToolbar>
        );
    }

    const CurrentCoupon = () => {
        let couponCode = '';
        if (outerRecord.coupons && outerRecord.coupons.length > 0) {

            const { data } = useGetOne(
                'sales_coupons',
                { id: outerRecord.coupons[0] },
            );
            if (data) {
                couponCode = data.coupon_code;
            } else {
                couponCode = '';
            }
        }

        return (
            <Box display="flex"  >
                <span style={ { margin: '0 10px 10px 0', fontFamily: 'Aeroport-bold' } } >{"Active Code:"}</span>
                <span>{ couponCode }</span>
            </Box>
        );
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', padding: '16px 16px 32px 16px', height: '600px', overflowY: 'auto'}}>
            <span style={{fontFamily: 'Aeroport-bold', fontSize: '18px'}}>{"Coupons"}</span>
            <List
                actions={ <ListActions/> }
                resource="sales_coupons"
                title=" "
                sx={ {
                    "& .RaDatagrid-root": {
                        height: '330px',
                        overflowY: 'auto'
                    }
                } }
            >
                <CurrentCoupon />
                <Datagrid
                    bulkActionButtons={false}
                    stickyHeader={true}

                >
                    <TextField source="id" />
                    <TextField source="couponCode" />
                    <TextField source="value" />
                    <TextField source="discount_type" sx={ { textTransform: 'capitalize' } } />
                    <WithRecord label=" " render={
                        innerRecord => {
                            let checked = ids.includes(innerRecord.id.toString());
                            let disabled = ids.length > 0;
                            if (checked || (selectedId == innerRecord.id)) {
                                disabled = false;
                            }
                            return (
                                <CustomSwitch disabled={ disabled } checked={ checked } handleClick={ handleClick } innerRecord={innerRecord} />
                            );
                        }
                    } />
                </Datagrid>
            </List>
        </Box>
    )
}

const CustomSwitch = (props) => {
    const [open, setOpen] = useState(props.checked ?? false);

    const handleClick = (e) => {
        setOpen(!open);
        props.handleClick(props.innerRecord, e);
    };

    return (
        <Switch disabled={ props.disabled } checked={ open } onChange={ e => handleClick(e) }/>
    );
}

export const SubscriptionEdit = () => {
    const context = useContext(AdminUserContext);
    const [update] = useUpdate();
    const notify = useNotify();
    // const [expandedTree, setExpandedTree] = useState([])
    const refresh = useRefresh();
    let expandedTree = null;

    function filterTreeData(formData, record, reload = false) {
        // debugger;
        // console.log(formData)
        let treeData = context.productTreeData;
        if (formData && treeData) {
            if (formData.products.length <= 0 && record.products.length <= 0 && !reload) {
                return resetTreeData(treeData);
            }  else {
                // debugger;
                treeData = resetTreeData(treeData);
                let intervalCount = undefined;
                treeData.forEach((item) => {
                    if (item.id == formData.products[0]) {
                        let parts = item.title.split(' ');
                        intervalCount = parts[0];
                    }
                })
                if (intervalCount) {
                    // debugger;
                    let filteredTreeData = [];
                    let parentNodes = []
                    let tmpIds = [];
                    let expandIds = [];
                    for (let i = 0; i < treeData.length; i++) {
                        let item = treeData[i];
                        if (item.title.indexOf('month') !== -1) {

                            if (item.title.indexOf((intervalCount + ' ')) == -1) {
                                item.disableCheckbox = true;
                                filteredTreeData.push(item);
                                //removedIds.push(item.id);
                            } else {
                                filteredTreeData.push(item);
                            }

                        } else {
                            tmpIds.push(item.id);
                            parentNodes.push(item);
                        }
                    }
                    let tmpId = record.products[0] ?? undefined;
                    for(let j = 0; j < parentNodes.length; j++) {
                        let node = parentNodes[j];
                        let childred = node['children'];
                        for (let k = 0; k < childred.length; k++) {
                            let child = childred[k];

                            if (tmpId !== undefined && tmpId == child) {
                                expandIds.push(node.id.toString());
                            }
                        }
                        filteredTreeData.push(node);
                    }
                    if (filteredTreeData.length > 0) {
                        treeData = filteredTreeData;
                        // refresh();
                    }
                    if (expandIds.length > 0) {
                        // console.log(expandIds);
                        expandedTree = expandIds;
                    }
                }
            }

        }
        // console.log(treeData);
        return treeData;
    }

    function getCurrencySymbol(id) {
        let symbol = '£';
        context.currencies.forEach((item) => {
            if (item.id == id) {
                symbol = item.symbol;
            }
        })
        return symbol;
    }

    function getStripeIntervalCountChoices(interval) {
        switch(interval) {
            case 'month':
                return context.stripeIntervalMonthCount;
            case 'year':
                return context.stripeIntervalYearCount;
            case 'week':
                return context.stripeIntervalWeekCount;
        }
    }

    const onSuccess = (data) => {
        notify('ra.notification.updated', {
            messageArgs: { smart_count: 1 },
            undoable: false
        });
        // yucky way but won't play ball at the mo
        // if (data.products.length <= 0) {
        //     window.location.reload();
        // }

    };

    return (
        <>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                    <Show title={ <EditSubscriptionTitle /> } actions={ false }>
                        <Grid container>
                            <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column', padding: '16px' } }>

                                    <Grid container spacing={ 3 }>
                                        <Grid item xs={ 4 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                            <span style={ { fontFamily: 'Aeroport-bold', fontSize: '18px' } } >{ "General" }</span>

                                            <Box sx={ fieldItemTStyles }>
                                                <span>Id:</span>
                                                <TextField label="Id" source="id" />
                                            </Box>
                                            <Box display="flex"
                                                 justifyContent="space-between"
                                            >
                                                <span>{ "Customer:" }</span>
                                                <ReferenceField
                                                    label="Customer"
                                                    source="customer"
                                                    reference="customer_entities">
                                                    <TextField source="full_name" />
                                                </ReferenceField>
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Next Cycle Date:" }</span>
                                                <DateField label="" name="next_cycle_date" source="next_cycle_date" />
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Shipping Method:" }</span>
                                                <ReferenceField label="Shipping Method" source="shipping_method" reference="sales_shipping_methods">
                                                    <TextField source="shipping_method_name" />
                                                </ReferenceField>
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Status:" }</span>
                                                <TextField label="Subscription Status" source="state" sx={ { textTransform: 'capitalize' } } />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={ 4 } sx={ { display: 'flex', flexDirection: 'column', marginTop: '23px' } }>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Is Trial:" }</span>
                                                <BooleanField source="is_trial" name='is_trial' />
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Currency:" }</span>
                                                <SelectField label="" name="currency" source="currency" choices={ context.currencies } />
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "One Off Total:" }</span>
                                                <WithRecord label="One Off Total" render={
                                                    record => (
                                                        <span>{ getCurrencySymbol(record.currency) + parseFloat(record.one_off_total).toFixed(2) }</span>
                                                    )
                                                } />
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Total Order Amount:" }</span>
                                                <WithRecord label="Total Order Value" render={
                                                    record => (
                                                        <span>{ getCurrencySymbol(record.currency) + parseFloat(record.total_order_value).toFixed(2) }</span>
                                                    )
                                                } />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={ 4 } sx={ { display: 'flex', flexDirection: 'column', marginTop: '23px' } }>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Subscription Frequency:" }</span>
                                                <SelectField source="stripe_interval" name="stripe_interval"
                                                             label={ false }
                                                             choices={ context.stripeIntervals }/>
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Subscription Frequency Count:" }</span>
                                                <TextField source="stripe_interval_count" name="stripe_interval"/>
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Created:" }</span>
                                                <DateField source="created_at" />
                                            </Box>
                                            <Box sx={ fieldItemTStyles }>
                                                <span>{ "Updated:" }</span>
                                                <DateField source="updated_at" />
                                            </Box>
                                        </Grid>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Show>
                </Grid>
                <Grid item xs={ 12 } sx={ { display: 'flex' } }>

                    <Grid item xs={ 6 } sx={ { marginRight: '10px' } }>
                        <Show title=" " actions={false} sx={ { height: '100%' } }>
                            <Box sx={{display: 'flex', flexDirection: 'column', padding: '16px', height: '600px', overflowY: 'auto'}}>
                                <span style={{fontFamily: 'Aeroport-bold', fontSize: '18px'}}>{"Addresses"}</span>
                                <ReferenceManyField
                                    reference="sales_order_addresses"
                                    target="id"
                                    source="addresses">
                                    <Datagrid bulkActionButtons={false}>
                                        <TextField source="id"/>
                                        <SelectField label="Type" source="address_type" choices={context.addressTypes}/>
                                        <WithRecord label="Customer" render={
                                            record => {
                                                return record.first_name + " " + record.surname
                                            }
                                        }/>
                                        <TextField source="street1"/>
                                        <TextField source="street2"/>
                                        <TextField source="postcode"/>
                                        <>
                                            <ShowButton/>
                                        </>
                                    </Datagrid>
                                </ReferenceManyField>
                            </Box>
                        </Show>
                    </Grid>
                    <Grid item xs={ 6 } sx={ { marginLeft: '10px',  } }>
                        <Show title=" " actions={false} sx={ { height: '100%' } } disableAuthentication>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '16px',
                                height: '600px',
                                overflowY: 'auto'
                            }}>
                                <WithRecord label=" " render={
                                    outerRecord => {

                                        return (
                                            <AddCouponCodeAction outerRecord={outerRecord}/>
                                        );
                                    }
                                }/>
                                <span>{"Note: Selected coupon(s) will be applied to all products for this subscription "}</span>
                            </Box>
                        </Show>
                    </Grid>

                </Grid>
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                    <Edit title=" " actions={ false } redirect={ false } mutationOptions={ { onSuccess: onSuccess } } mutationMode="pessimistic" >
                        <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', padding: '16px'}}>
                            <span style={{fontFamily: 'Aeroport-bold', fontSize: '18px'}}>{"Stripe"}</span>
                            <Box sx={fieldItemTStyles}>
                                <span>{"Subscription Id:"}</span>
                                <WithRecord render={
                                    record => {
                                        const url = "https://dashboard.stripe.com/" + stripeMode + "/subscriptions/" + record.stripe_subscription_id;
                                        return <a href={url} target="_blank"
                                                  rel="noopener noreferrer">{record.stripe_subscription_id}</a>
                                    }
                                }/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', padding: '16px'}}>
                            <span style={{fontFamily: 'Aeroport-bold', fontSize: '18px'}}>{"Subscription Control"}</span>
                            <Box sx={ fieldItemTStyles }>
                                <span></span>
                                <WithRecord render={
                                    record => {

                                        return (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                sx={ {   } }
                                            >
                                                {/*<PaymentLinkAction record={ record } fullButton={true} />*/}
                                                { record.state === 'active' &&
                                                    <>
                                                        <PauseAction record={ record } fullButton={true} />
                                                        <CancelAction record={ record } fullButton={true} />

                                                    </>
                                                }
                                                { record.state === 'paused' &&
                                                    <>
                                                        <ResumeAction record={ record } fullButton={true} />
                                                    </>
                                                }
                                            </Box>
                                        );
                                    }
                                } />
                            </Box>
                        </Grid>
                        <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                            <SimpleForm toolbar={ <CustomToolBar /> }>
                                <Box sx={ {
                                    ...fieldItemTStyles,
                                    width: '100%'
                                }}>
                                    <span>{ "Next Cycle Date:" }</span>
                                    <DateInput source="next_cycle_date" name="next_cycle_date" label={ false } />
                                </Box>
                                <Box sx={ {
                                    ...fieldItemTStyles,
                                    width: '100%',
                                }}>
                                    <span>{ "Products:" }</span>
                                    <WithRecord label=" " render={
                                        record => {
                                            return (
                                                <FormDataConsumer>
                                                    {
                                                        ({
                                                             formData,
                                                             ...rest
                                                         }) => (
                                                            <TreeInput
                                                                sx={ {
                                                                    width: '90%',
                                                                    maxWidth: '900px',
                                                                    height: '600px',
                                                                    overflowY: 'auto',
                                                                    border: '1px solid #eee',
                                                                    padding: '15px',
                                                                    borderRadius: '5px',
                                                                } }
                                                                source="products" multiple
                                                                hideRootNodes={true}
                                                                defaultExpandedKeys={ expandedTree }
                                                                treeData={ (filterTreeData(formData, record)) }
                                                                {...rest}
                                                            />
                                                        )
                                                    }
                                                </FormDataConsumer>
                                            );
                                        }
                                    } />


                                </Box>
                            </SimpleForm>
                        </Grid>
                    </Edit>
                </Grid>
            </Grid>

        </>
    );
}