import React, { useState, useEffect } from 'react';
import {
    Show,
    TextField,
    DateField,
    ReferenceField,
    BooleanField,
    WithRecord,
    ReferenceManyField,
    Datagrid, FunctionField
} from 'react-admin';
import {
    Box,
    CircularProgress,
    Grid,
    Stack,
    Link,
    LinearProgress,
} from '@mui/material';
import { useGetStripeMode } from "./components/hooks/EditSubscriptionModalHooks";
import { SalesOrderRefunds } from "./components/SalesOrderRefunds";

const labelStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px',
    fontFamily: 'Aeroport'
};

/**
 * Generate Stripe dashboard link based on stripe_mode setting
 * @param props
 * @returns {Element}
 * @constructor
 */
const InvoiceLink = (props) => {
    const record = props.record;
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("test");
    useEffect(() => {
        ( async function () {
                let modeLoad = await useGetStripeMode();
                if (modeLoad?.success) {
                    setMode(modeLoad.mode);
                    setLoading(false);
                }
                if (modeLoad?.error) {
                    setMode('error')
                }
            }
        )();
    }, []);

    if (loading) {
        return <LinearProgress sx={ { width: '50px' } } />;
    }

    if (mode === 'error') {
        return <span>'Failed to generate Stripe invoice link</span>
    }
    if (mode === 'test') {
        return (
            <Link href={ "https://dashboard.stripe.com/" + mode + "/invoices/" + record.stripeInvoiceId } target="_blank" >{ record.stripeInvoiceId }</Link>
        );
    }

    return (
        <Link href={ "https://dashboard.stripe.com/invoices/" + record.stripeInvoiceId } target="_blank" >{ record.stripeInvoiceId }</Link>
    );
}

export const SalesOrderEdit = () => {

    return (
       <Grid container spacing={ 2 }>
           <Grid container spacing={ 2 } sx={ { marginLeft: 0 } }>
               <Grid item xs={ 12 }>
                   <Show redirect="false" actions={ false } >
                       <Grid container spacing={ 2 } sx={ { padding: '20px' } }>
                           <Grid item xs={ 6 }>
                                <Stack spacing={ 2 }>
                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "ID" }</span>
                                        <TextField source="id"/>
                                    </Box>
                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Order Number" }</span>
                                        <TextField source="incrementId" />
                                    </Box>
                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Status" }</span>
                                        <TextField source="status" sx={ { textTransform: 'capitalize' } } />
                                    </Box>
                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Shipping Method" }</span>
                                        <ReferenceField source="shippingMethod" reference="sales_shipping_methods" link={ false }>
                                            <TextField source="shipping_method_name" />
                                        </ReferenceField>
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Shipping Amount (£)" }</span>
                                        <TextField source="shippingAmount" />
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Order Sent" }</span>
                                        <BooleanField source="orderSent" />
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Stripe Invoice ID" }</span>
                                        <WithRecord label=" " render={
                                            record => {
                                                return <InvoiceLink record={ record } />
                                            }
                                        } />
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Customer" }</span>
                                        <ReferenceField source="customerId" reference="customer_entities" >
                                            <TextField source="full_name" />
                                        </ReferenceField>
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Last Updated" }</span>
                                        <DateField source="updatedAt" />
                                    </Box>
                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Created At" }</span>
                                        <DateField source="createdAt" />
                                    </Box>

                                </Stack>
                           </Grid>
                           <Grid item xs={ 6 }>
                                <Stack spacing={ 2 }>
                                    <Box
                                        sx={ {
                                            ...labelStyles,
                                            flexDirection: 'column'
                                        } } >
                                        <span>{ "Order Items" }</span>
                                        <ReferenceManyField source="items" reference="sales_order_items" target="sales_order" label="Items">
                                            <Datagrid bulkActionButtons={ false }>
                                                <TextField source="parentProductName" label="Products" />
                                            </Datagrid>
                                        </ReferenceManyField>
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Grand Total (£)" }</span>
                                        <FunctionField source="grandTotal" render={ record => '£' + record.grandTotal.toFixed(2)  } />
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Sub Total (£)" }</span>
                                        <FunctionField source="subtotal" render={ record => '£' + record.subtotal.toFixed(2)  } />
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Tax Amount (£)" }</span>
                                        <FunctionField source="taxAmount" render={ record => '£' + record.taxAmount.toFixed(2)  } />
                                    </Box>

                                    <Box
                                        sx={ labelStyles } >
                                        <span>{ "Refund Amount (£)" }</span>
                                        <FunctionField source="refundAmount" render={
                                            record => {
                                                let refundAmount = record.refundAmount ?? 0;
                                                return (
                                                    '£' + refundAmount.toFixed(2)
                                                );
                                        } } />
                                    </Box>

                                </Stack>
                           </Grid>
                       </Grid>
                   </Show>
               </Grid>
           </Grid>
           <Grid container spacing={ 2 } sx={ { marginLeft: 0 } }>
               <Grid item xs={ 12 }>
                  <SalesOrderRefunds labelStyles={ labelStyles } />
               </Grid>

           </Grid>

       </Grid>
    );
}
