import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    WithRecord,
    DateInput,
    Labeled,
    useRefresh,
    useUpdate,
    useNotify,
    SelectInput,
} from 'react-admin';
import {
    Grid,
    Link
} from '@mui/material';
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";
import { JsonFormItemisedContainer } from "../../Form/Notes/JsonFormItemisedContainer";
import { sha256 } from "js-sha256";
import { getAccountAppUrl } from "../../../utils/UrlManager";

const EditTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{ "Customer - " + record.full_name }</span>
}

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

const whereDidYouHearAboutUsSelect = [
    { id: 'Social Media', name: 'Social Media' },
    { id: 'Search Engine', name: 'Search Engine' },
    { id: 'Recommended by Friend / Family', name: 'Recommended by Friend / Family' },
    { id: 'TV Advertisement', name: 'TV Advertisement' },
    { id: 'Print Media (magazines, billboards)', name: 'Print Media (magazines, billboards)' },
    { id: 'Sports Team Partnership', name: 'Sports Team Partnership' },
    { id: 'Podcast / Radio', name: 'Podcast / Radio' },
    { id: 'Clinic Referral', name: 'Clinic Referral' },
    { id: 'Online Reviews', name: 'Online Reviews' }
];


export const CustomerDetails = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [update] = useUpdate();
    const record = useRecordContext();

    const handleFormSubmit = async (data) => {
        await update(
            'customer_entities',
            { id: data.id, data: data, previousData: record },
            {
                onSuccess: (data) => {
                    notify("Customer Updated");
                    refresh();
                },
                onError: (error) => {
                    notify("Unable to update customer", { type: 'error' });
                }
            }
        );
    }

    const handleCustomerAccountLogin = (record) => {
        let shaKey = "Luke Leia Han Chewbacca Vader Yoda Obi-Wan Tatooine Endor Hoth Dagobah Falcon Death Star Stormtrooper Lightsaber X-Wing TIE Fighter Emperor Jabba R2-D2 C-3PO Lando Wampa Ewok Bespin Alderaan Jawa Blaster";
        let sha = sha256(shaKey);
        let jsonValue = {
            id: record.shopifyCustomerId,
            secret: sha
        };
        jsonValue = JSON.stringify(jsonValue);
        let param = btoa(jsonValue);
        let url = getAccountAppUrl();
        return url + param;
    }

    return (
        <Edit
            title={ <EditTitle /> }
            actions={ false }
            redirect={false}
        >

            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                    <SimpleForm onSubmit={ handleFormSubmit } >
                                    <span style={
                                        { ...sectionTitleStyles, paddingLeft: 0, margin: '0' }
                                    }> { "Customer Details" } </span>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextInput name="first_name" source="first_name"/>
                                <TextInput name="last_name" source="last_name"/>
                                <TextInput name="email_address" source="email_address"/>
                                <TextInput name="phone_number" source="phone_number"/>
                                <SelectInput
                                    source="how_did_you_find_us"
                                    name="how_did_you_find_us"
                                    choices={whereDidYouHearAboutUsSelect} />
                                <Labeled label="Used Coupons">
                                    <WithRecord label="Used Coupons" render={
                                        record => {
                                            let usedCoupons = record.usedCoupons;
                                            let couponString = '';
                                            if (Array.isArray(usedCoupons)) {
                                                for(const coupon of usedCoupons) {
                                                    couponString += (coupon + ' ')
                                                }
                                            }


                                            return (
                                                <span>{ couponString }</span>
                                            );
                                        }
                                    }  />
                                </Labeled>
                            </Grid>

                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextInput name="shopify_customer_id" source="shopify_customer_id"/>
                                <TextInput name="stripe_customer_id" source="stripe_customer_id"/>
                                <DateInput source="dateOfBirth" name="dateOfBirth"/>
                                <Labeled label="Customer Account App" sx={ { marginBottom: '40px' } }>
                                    <WithRecord label="" render={
                                        record => {

                                            return (
                                                <Link target="_blank"
                                                      href={ handleCustomerAccountLogin(record) } >
                                                    { "Login to Customer Account App" }
                                                </Link>
                                            );

                                        }
                                    } />
                                </Labeled>

                                <EditInDialogButton
                                    label="Consulation Data"
                                    fullWidth={true}
                                    maxWidth="lg"
                                    ButtonProps={
                                        {
                                            variant: 'contained',
                                            size: 'medium',
                                            color: 'secondary',
                                            startIcon: false
                                        }
                                    }  >
                                    <Grid container spacing={2}>
                                        <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                            <JsonSchemaForm
                                                schema={ {
                                                    type: 'object',
                                                    properties: {
                                                        note: {
                                                            title: 'Consultation Notes - quick view',
                                                            type: 'object',
                                                            properties: {
                                                                first_name: { type: 'string', title: 'First Name' },
                                                                last_name: { type: 'string', title: 'Last Name' },
                                                                email: { type: 'string', title: 'Email Address' },
                                                                phone: { type: 'string', title: 'Phone Number' },
                                                                date_of_birth: { type: 'string', title: 'Date of Birth', format: 'date' },
                                                                what_is_your_gender: { type: 'string', title: 'Gender', enum: ['Male', 'Female'] },
                                                                average_order_amount: { type: 'string', title: 'Average Order Amount' },
                                                                consent_medical: { type: 'boolean', title: 'Consent Medical' },
                                                                accepts_marketing: { type: 'boolean' },
                                                            }
                                                        },
                                                    },
                                                } }
                                                uiSchema={ {
                                                    average_order_amount: { 'ui:disabled': true },
                                                } }
                                            />
                                        </Grid>
                                        <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                            <JsonFormItemisedContainer />
                                        </Grid>
                                    </Grid>
                                </EditInDialogButton>

                            </Grid>

                        </Grid>
                    </SimpleForm>
                </Grid>
            </Grid>
        </Edit>
    );
}