import React, { useState, useContext, useEffect } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required,
    useCreate,
    CreateBase,
    TextField,
    BooleanField,
    DateField,
    SelectField
} from 'react-admin';
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import {
    Box,
    Grid,
    Stack,
    CircularProgress,
} from '@mui/material';

const itemStyles = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
};

export const SalesCouponEdt = (props) => {
    const context = useContext(AdminUserContext);
    const [loading, setLoading] = useState(true);
    const [couponProducts, setCouponProducts] = useState([]);
    const [freeSkus, setFreeSkus] = useState( [])
    const [create] = useCreate();

    let discountTypes = [];
    for (const discountType of context.subscriptionDiscountTypes) {
        discountTypes.push({ id: discountType.id, name: discountType.name });
    }

    const durationOptions = [
        { id: 'once', name: 'Once' },
        { id: 'repeating', name: 'Repeating' },
        { id: 'forever', name: 'Forever' },
    ]

    function submitFormAction(values) {
        let datum = values.formData;
        create(
            'sales_coupons',
            {
                data: {
                    'couponCode': datum.coupon_code,
                    'active': datum.active,
                    'oneTime': datum.one_time,
                    'value': datum.value,
                    'couponRule': JSON.stringify(datum.coupon_rule),
                    'discountType': datum.discount_type,
                    'duration': datum.duration,
                    'free_gift_sku': datum.free_gift_sku,
                    'usage_limit': datum.usage_limit,
                }
            }
        );
    }

    useEffect( () => {
        ( async () => {
            if (couponProducts.length === 0) {
                await context.loadProductsForCouponCreator()
                    .then(response => {
                        if (response && response.success === true) {
                            setCouponProducts(response.data.lookup);
                            let datum = response.data.sku;
                            let tmp = [];
                            for (const item of datum) {
                                tmp.push({ id: item.const, name: item.title });
                            }
                            setFreeSkus(tmp);
                        }
                        setLoading(false);
                    })
            }
        } )();
    }, [] );

    function processCouponRuleUpdate(data) {
        let couponRule = data;
        switch (couponRule['rule_operand']) {
            case 'all':
                if (couponRule.hasOwnProperty('eligible_products')) {
                    delete couponRule.eligible_products;
                }
                if (couponRule.hasOwnProperty('excluded_products')) {
                    delete couponRule.excluded_products;
                }
                break;
            case 'eligible_products':
                if (couponRule.hasOwnProperty('excluded_products')) {
                    delete couponRule.excluded_products;
                }
                break;
            case 'excluded_products':
                if (couponRule.hasOwnProperty('eligible_products')) {
                    delete couponRule.eligible_products;
                }
                break;
        }
        return couponRule;
    }

    const transform = (data) => ({
        ...data,
        couponRule: processCouponRuleUpdate(data.coupon_rule),
        coupon_rule: processCouponRuleUpdate(data.coupon_rule),
    })

    return (
        <Edit redirect={ false } transform={ transform }>
            <Grid container spacing={2} sx={ { padding: '20px' } }>
                <Grid item xs={6} >
                    <Stack spacing={2}>
                        <Box sx={ itemStyles }>
                            <span>{ "Value" }</span>
                            <TextField source="value" />
                        </Box>
                        <Box sx={ itemStyles }>
                            <span>{ "One Time" }</span>
                            <BooleanField source="oneTime" />
                        </Box>
                        <Box sx={ itemStyles }>
                            <span>{ "Discount Type" }</span>
                            <SelectField source="discountType" choices={ discountTypes } />
                        </Box>
                        <Box sx={ itemStyles }>
                            <span>{ "Free Gift Sku" }</span>
                            <SelectField source="freeGiftSku" choices={ freeSkus } />
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={6} >
                    <Stack spacing={2}>
                        <Box sx={ itemStyles }>
                            <span>{ "Duration" }</span>
                            <SelectField source="duration" choices={ durationOptions } />
                        </Box>
                        <Box sx={ itemStyles }>
                            <span>{ "Duration Months" }</span>
                            <TextField source="durationInMonths" />
                        </Box>
                        <Box sx={ itemStyles }>
                            <span>{ "Usage Limit" }</span>
                            <TextField source="usageLimit" />
                        </Box>
                        <Box sx={ itemStyles }>
                            <span>{ "Redeem By" }</span>
                            <DateField source="redeemBy" />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>

            { loading &&
                <Box sx={ {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '20px',
                } }>
                    <span style={ { marginTop: '20px' } } >{ "Loading..." }</span>
                    <CircularProgress size={ 40 }/>
                </Box>
            }
            { !loading &&
                <JsonSchemaForm
                    schema={ {
                        type: "object",
                        "required": [
                            "couponCode",
                            "value",
                            "couponRule",
                            "discountType",
                            "duration",
                        ],
                        properties: {
                            'active': {
                                type: "boolean",
                                title: "Active",
                                default: false,
                                description: 'Enable/Disable a coupon (2.0 only)'
                            },
                            'coupon_code': {
                                type: "string",
                                title: "Code",
                                description: 'Coupon Code for customer use (2.0 only)'
                            },
                            'coupon_rule': {
                                type: 'object',
                                title: 'Coupon Rule',
                                description: 'Products to be included or excluded for the coupon (2.0 only)',
                                properties: {
                                    "rule_operand": {
                                        type: 'string',
                                        title: 'Product List',
                                        oneOf: [
                                            { const: 'all', title: 'All' },
                                            { const: 'eligible_products', title: 'Eligible Products' },
                                            { const: 'excluded_products', title: 'Excluded Products' },
                                            { const: 'entitled', title: 'Entitled' },
                                        ]
                                    }
                                },
                                dependencies: {
                                    'rule_operand': {
                                        'oneOf': [
                                            {
                                                'properties': {
                                                    'rule_operand': {
                                                        'const': 'eligible_products'
                                                    },
                                                    'eligible_products': {
                                                        type: 'array',
                                                        title: 'Eligible Products',
                                                        // uniqueItems: true,
                                                        items: {
                                                            type: 'array',
                                                            title: 'Eligible Products',
                                                            uniqueItems: true,
                                                            items: {
                                                                oneOf: couponProducts
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                'properties': {
                                                    'rule_operand': {
                                                        'const': 'excluded_products'
                                                    },
                                                    'excluded_products': {
                                                        type: 'array',
                                                        title: 'Excluded Products',
                                                        uniqueItems: true,
                                                        items: {
                                                            oneOf: couponProducts
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                'properties': {
                                                    'rule_operand': {
                                                        'const' : 'all'
                                                    }
                                                }
                                            },
                                            {
                                                'properties': {
                                                    'rule_operand': {
                                                        'const' : 'entitled'
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    } }
                    uiSchema={ {
                        active: { 'ui:widget': 'radio' },
                    } }
                />
            }
        </Edit>
    );
}