import React from 'react';
import {
    ReferenceManyField,
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    SelectField,
    WithListContext,
    Pagination,
} from 'react-admin';
import {
    Box,
    Grid,
    Stack
} from '@mui/material';
import { AddressEditDialog } from "../../Dialog/AddressEditDialog";

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

const AddressTypes = [
    { id: 'shipping', name: 'Shipping' },
    { id: 'billing', name: 'Billing' },
];

export const CustomerAddress = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={ 6 } sx={ { display: 'flex' } }>
                <Show actions={false} title=" " sx={ { width: '100%', height: '100%', minHeight: '424px' } }>
                    <SimpleShowLayout>
                        <span style={{
                            ...sectionTitleStyles,
                            paddingLeft: 0
                        }
                        }>{"Default Customer Addresses"}</span>
                        <Grid container sx={{width: '100%'}}>
                            <Grid item xs={6}>
                                <ReferenceManyField
                                    source="addresses"
                                    reference="sales_order_addresses"
                                    target="id"
                                    filter={{address_type: 'shipping', isDefault: 1}}
                                >
                                    <WithListContext render={({data}) => {
                                        if (!data) return null;
                                        let address;
                                        if (data && data.length > 0) {
                                            address = data[0];
                                        }
                                        return (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                sx={ { borderRight: '1px solid #696868FF' } }>
                                                <Box
                                                    sx={ {
                                                        background: '#eee',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        padding: '5px'
                                                    } } >
                                                    <span>{"Shipping Address"}</span>
                                                    { (address !== undefined) &&
                                                        <AddressEditDialog record={address}/>
                                                    }
                                                </Box>
                                                { (address !== undefined) &&
                                                    <Stack spacing={2} sx={{padding: '5px'}}>
                                                        <span>{address.street1}</span>
                                                        <span>{address.street2}</span>
                                                        <span>{address.city}</span>
                                                        <span>{address.county}</span>
                                                        <span>{address.country}</span>
                                                        <span>{address.postcode}</span>
                                                    </Stack>
                                                }
                                                { (address === undefined) &&
                                                    <span>No Default Billing Address Found</span>
                                                }
                                            </Box>
                                        );
                                    }}/>
                                </ReferenceManyField>
                            </Grid>

                            <Grid item xs={6}>
                                <ReferenceManyField
                                    source="addresses"
                                    reference="sales_order_addresses"
                                    target="id"
                                    filter={{address_type: 'billing', isDefault: 1}}
                                >
                                    <WithListContext render={({data}) => {
                                        if (!data) return null;
                                        let address;
                                        if (data && data.length > 0) {
                                            address = data[0];
                                        }
                                        return (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                sx={ { borderLeft: '1px solid #696868FF' } }>
                                                <Box
                                                    sx={ {
                                                        background: '#eee',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        padding: '5px'
                                                    } } >
                                                    <span>{"Billing Address"}</span>
                                                    { (address !== undefined) &&
                                                        <AddressEditDialog record={address}/>
                                                    }
                                                </Box>
                                                { (address !== undefined) &&
                                                    <Stack spacing={2} sx={{padding: '5px'}}>
                                                        <span>{address.street1}</span>
                                                        <span>{address.street2}</span>
                                                        <span>{address.city}</span>
                                                        <span>{address.county}</span>
                                                        <span>{address.country}</span>
                                                        <span>{address.postcode}</span>
                                                    </Stack>
                                                }
                                                { (address === undefined) &&
                                                    <span>No Default Billing Address Found</span>
                                                }
                                            </Box>
                                        );
                                    }}/>
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </SimpleShowLayout>
                </Show>
            </Grid>

            <Grid item xs={ 6 }>
                <Show actions={false} title=" ">
                    <SimpleShowLayout>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <span style={sectionTitleStyles}>{"All Customer Addresses"}</span>
                            <ReferenceManyField
                                reference="sales_order_addresses"
                                source="addresses"
                                target="id"
                                stickyHeader={true}
                                sx={{
                                    "& .RaDatagrid-tbody": {
                                        height: '100px',
                                        overflowY: 'auto'
                                    }
                                }}
                                pagination={ <Pagination /> }
                            >
                                <Datagrid
                                    bulkActionButtons={false}
                                >
                                    <TextField source="id"/>
                                    <SelectField source="address_type" choices={AddressTypes}/>
                                    <TextField source="street1"/>
                                    <TextField source="postcode"/>
                                    <AddressEditDialog/>
                                </Datagrid>
                            </ReferenceManyField>

                        </Box>
                    </SimpleShowLayout>
                </Show>
            </Grid>

        </Grid>
    );
}