import React from 'react';
import {
    SelectInput,
    SimpleForm,
    TextInput,
    BooleanInput,
    Create,
    required
} from 'react-admin';

const choices = [
    { id: 'input', name: 'input' },
    { id: 'select', name: 'select' },
];

export const SettingsCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput name="name" source="name" validate={required()} />
                <TextInput name="code" source="code" validate={required()} />
                <TextInput name="value" source="value" validate={required()} />
                <SelectInput name="type" source="type" choices={ choices } validate={required()} />
                <BooleanInput name="isSystem" source="isSystem" validate={required()} />
            </SimpleForm>
        </Create>
    );
}