import React, { useContext } from 'react';
import {
    Edit,
    TabbedForm,
    TextInput,
    PasswordInput,
    required,
    ImageInput,
    ImageField,
    usePermissions,
    SelectInput,
    SelectArrayInput,
    useAuthProvider,
    useUpdate, useGetIdentity, useNotify, useEditContext
} from 'react-admin';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import { usePermissionsManager } from "../utils/permissionsManager";

export const AdminUserEdit = () => {
    const auth = useAuthProvider();
    // if (auth) return null;
    const { id, fullName, avatar } = auth.getIdentity();
    const { permissions } = usePermissions();
    const adminUserContext = useContext(AdminUserContext);
    const [update] = useUpdate();
    const idCard = useGetIdentity();
    const notify = useNotify();
    const record = useEditContext();
    /**
     * Validate the username and password if the fields are edited
     * @param values
     * @returns {{}}
     */
    const handleSave = (values) => {
        if (typeof values['new_password'] !== undefined &&
            typeof values['repeat_new_password'] !== undefined &&
            typeof values['old_password'] !== undefined &&
            values['new_password'] !== values['repeat_new_password']) {
            notify("Your new passwords doesn't match, please make sure they match", { type: 'error' });
        } else {
            update(
                'admin_users',
                { id: values.id, data: values, previousData: record },
                {
                    onSuccess: (data) => {
                        idCard.identity.avatar = data['avatar'];
                        idCard.identity.fullName = data['name'];
                        idCard.identity.email = data['email'];
                        idCard.identity.name = data['name'];
                        // refetch(); //reload the record
                        idCard.refetch(); //reload the identity
                        notify("Your Profile has been updated");
                    },
                    onError: (error) => {
                        notify(`Failed to update error: ${error.message}`, {type: 'error'});
                    },
                }
            );
        }
    }

    return (
        <Edit>
            <TabbedForm onSubmit={ handleSave }>
                <TabbedForm.Tab label="Profile">
                    <TextInput disabled name="user_edit" source="user_edit" defaultValue={ id } sx={{ display: 'none' }} />
                    <TextInput name="name" source="name" fullWidth />
                    <TextInput name="email" source="email" validate={[required()]} fullWidth autoComplete="email" />
                    <ImageInput name="avatar" source="avatar" label="Avatar" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </TabbedForm.Tab>
                { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN'] }) &&
                    <TabbedForm.Tab label="Roles">
                        <SelectArrayInput source="roles" choices={ adminUserContext.adminUserRoles } optionValue="id"  name="roles"/>
                    </TabbedForm.Tab>
                }
                <TabbedForm.Tab label="Password">
                    <PasswordInput name="new_password" source="new_password" fullWidth autoComplete="new-password" />
                    <PasswordInput name="repeat_new_password" source="repeat_new_password" fullWidth autoComplete="new-password" />
                    <PasswordInput name="your_password" source="your_password" fullWidth autoComplete="current-password" />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
}