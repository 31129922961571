
// Add XDebug value to local URLs
export const useUrlManagerCleanUrl = ({ url }) => {
    if (typeof url == 'undefined') {
        return url;
    }
    if (window.location.href.includes('local')) {

        if (url.includes('?')) {
            url += '&XDEBUG_SESSION_START=PHPSTORM';
        } else {
            url += '?XDEBUG_SESSION_START=PHPSTORM';
        }

    }

    return url;
}

export const getAccountAppUrl = () => {
    let url = 'https://shop.sons.co.uk/customer/account/';
    if (typeof window !== "undefined") {
        const windowHref = window.location.href;
        if (windowHref.includes('sons-staging')) {
            url = 'https://sons-staging.tickledchilli.co.uk/customer/account/';
        } else if (windowHref.includes('sons-dev')) {
            url = 'https://sons-dev.tickledchilli.co.uk/customer/account/';
        } else if  (windowHref.includes('local.sons')) {
            url = 'https://local.sons/customer/account/';
        } else if(windowHref.includes('localhost')) {
            url = 'https://customiser.sons.local/customer/account/';
        }
    }

    return url;
}

export const getFeBaseUrl = () => {
    let url = 'https://sons.co.uk/';
    if (typeof window !== "undefined") {
        const windowHref = window.location.href;
        if (windowHref.includes('sons-staging')) {
            url = 'https://sons-stagingtickledchilli.co.uk/';
        } else if (windowHref.includes('sons-dev') ) {
            url = 'https://sons-dev.tickledchilli.co.uk/';
        } else if (windowHref.includes('sonshaar.de') || windowHref.includes('/de/')) {
            url = 'https://sonshaar.de/';
        } else if (windowHref.includes('local.sons')) {
            url = 'https://local.sons/';
        }
    }
    return url;
}