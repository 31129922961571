import React, { useContext } from 'react';
import {
    Box, Button,
    Grid,
    Stack,
    LinearProgress
} from '@mui/material';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 99999,
    background: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const ActionOverlay = () => {
    const context = useContext(AdminUserContext);

    if (context.showActionOverlay) {
        return (
            <Box className="action-overlay" sx={ overlayStyles }>
                <Box>
                    <img src="/images/loader-two.gif" alt="loader" />
                </Box>
            </Box>
        );
    }

    return null;

}