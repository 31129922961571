
import {
    Menu,
} from 'react-admin';
import {
        IconMenu,
        MenuItemList,
        MenuItemNode,
} from "@react-admin/ra-navigation";
import SettingsIcon from '@mui/icons-material/Settings';
import {
        Box,
        Stack
} from "@mui/material";

import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReportIcon from '@mui/icons-material/Report';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import Person2Icon from '@mui/icons-material/Person2';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PollIcon from '@mui/icons-material/Poll';

import { usePermissionsManager } from "../utils/permissionsManager";

const AdminMenu = () => {

    return (
        // <></>
        <IconMenu
            variant="categories"
            sx={ { textTransform: 'capitalize' } } >
            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_SALES', 'ROLE_SALES_LEAD'] }) &&
                <IconMenu.Item
                    name="Dashbeoard"
                    to="/"
                    label="Dashboard"
                    icon={ <DashboardIcon /> } />
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_SALES', 'ROLE_SALES_LEAD'] }) &&
                <IconMenu.Item
                    name="sales_subscriptions"
                    to="/sales_subscriptions"
                    label="Subscriptions"
                    icon={ <CardMembershipIcon /> } />
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_SALES', 'ROLE_SALES_LEAD'] }) &&
                <IconMenu.Item
                    name="customer_entities"
                    to="/customer_entities"
                    label="Customers"
                    icon={ <Person2Icon /> } />
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_SALES', 'ROLE_SALES_LEAD'] }) &&
                <IconMenu.Item
                    name="sales_coupons"
                    to="/sales_coupons"
                    label="Coupons"
                    icon={ <QrCode2Icon /> } />
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_SALES_LEAD'] }) &&
                <IconMenu.Item
                    name="catalog_product_entities"
                    to="/catalog_product_entities"
                    label="Products"
                    icon={ <InventoryIcon /> } />
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_COMMERCE', 'ROLE_SALES_LEAD'] }) &&
                <IconMenu.Item
                    name="Surveys"
                    to=""
                    label="Surveys"
                    icon={ <PollIcon /> } >
                    <Stack
                        sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                        spacing={2}>
                        <Menu.Item to="/survey_analytics" primaryText="Analytics" leftIcon={ <SettingsIcon /> } />
                        <Menu.ResourceItem name="surveys" label="Surveys" />
                        <Menu.ResourceItem name="survey_forms" label="Survey Forms" />
                        <Menu.ResourceItem name="survey_forms_archives" title="Survey Archives"/>
                        {/*<Menu.ResourceItem name="sales_order_addresses" />*/}
                    </Stack>
                </IconMenu.Item>
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN', 'ROLE_SALES', 'ROLE_SALES_LEAD'] }) &&
                <IconMenu.Item
                    name="Sales"
                    to=""
                    label="Sales"
                    icon={ <MonetizationOnIcon /> } >
                    <Stack
                        sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                        spacing={2}>
                        <Menu.ResourceItem name="sales_orders" />
                        <Menu.ResourceItem name="sales_order_items" />
                        <Menu.ResourceItem name="sales_order_addresses" />
                    </Stack>
                </IconMenu.Item>
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN'] }) &&
                <IconMenu.Item
                    name="Catalog"
                    to=""
                    label="Catalog"
                    icon={ <InventoryIcon /> } >
                    <Stack
                        sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                        spacing={2}>
                        <Menu.ResourceItem name="catalog_product_prices" />
                        <Menu.ResourceItem name="catalog_product_images" />
                        <Menu.ResourceItem name="catalog_product_plans" />
                        <Menu.ResourceItem name="catalog_pharmacy_lookup" />
                    </Stack>
                </IconMenu.Item>
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN'] }) &&
                <IconMenu.Item
                    name="Settings"
                    to=""
                    label="Settings"
                    icon={ <SettingsIcon /> } >
                    <Stack
                        sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                        spacing={2}>
                        <Menu.ResourceItem name="sales_shipping_methods" />
                        <Menu.Item to="/settings" primaryText="Settings" leftIcon={<SettingsIcon />} />

                    </Stack>
                </IconMenu.Item>
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN'] }) &&
                <IconMenu.Item
                    name="Logs"
                    to=""
                    label="Logs"
                    icon={ <ReportIcon /> }>
                    <Stack
                        sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                        spacing={2}>
                        <Menu.ResourceItem name="message_messengers" label="Messenger Messages" />
                        <Menu.ResourceItem name="activity_monitors" label="Activity Monitor" />
                        <Menu.ResourceItem name="error_logs" label="Error Logs" />
                    </Stack>
                </IconMenu.Item>
            }

            { usePermissionsManager({ accessToRoles: ['ROLE_SUPER_ADMIN'] }) &&
                <IconMenu.Item
                    name="Admin Users"
                    to=""
                    label="Admin Users"
                    icon={ <AccessibilityNewIcon /> }>
                    <Stack
                        sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                        spacing={2}>
                        <Menu.ResourceItem name="admin_users" />
                    </Stack>
                </IconMenu.Item>
            }

            {/*<IconMenu.Item*/}
            {/*    name="search"*/}
            {/*    to=""*/}
            {/*    label="Search"*/}
            {/*    icon={ <SearchIcon /> }>*/}
            {/*    <Stack*/}
            {/*        sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }*/}
            {/*        spacing={2}>*/}
            {/*        <SearchWithResult />*/}
            {/*    </Stack>*/}

            {/*</IconMenu.Item>*/}
        </IconMenu>
    );
}
export default AdminMenu;