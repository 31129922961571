import React from 'react';
import {
    WithRecord,
    Show,
} from 'react-admin';
import {CustomerPaymentMethods} from "./CustomerPaymentMethods";

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

export const CustomerPayment = (props) => {

    return (
        <Show title=" " actions={false}>
            <span style={ sectionTitleStyles }>{ "Payment Methods" }</span>
            <WithRecord label=" " render={
                record => {
                    return (
                        <CustomerPaymentMethods record={ record }/>
                    );
                }
            }/>
        </Show>
    );
}