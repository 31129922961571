import React, { useContext } from 'react';
import {
    Edit,
} from 'react-admin';
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';

export const SurveyEdit = (props) => {


    return (
        <Edit>
            <JsonSchemaForm schema={{
                type: 'object',
                properties: {
                    email_address: { type: 'string', format: 'email' },
                    external_id: { type: 'string' },
                    ip_address: { type: 'string' },
                    json: {
                        type: 'object',
                        title: 'Form Data',
                        properties: {
                            'Are you an existing customer': {
                                type: 'string',
                                enum: ['Yes', 'No'],
                            },
                            'Do you suffer from any of the following': {
                                type: 'array',
                                items: {
                                    type: 'string',
                                    title: '',
                                    enum: [
                                        "None",
                                        "Rheumatoid Arthritis, Systemic Lupus or on Methotrexate",
                                        "Scalp Disorders (including Scalp Psoriasis, Eczema, Dermatitis or Infection)",
                                        'Severe Kidney Disease/Dialysis',
                                        "Liver Disease or Abnormal Liver Function",
                                        'Prostate Disease, Male Breast Cancer or Testicular Cancer',
                                        'High Blood Pressure',
                                        'Significant Depression or Anxiety',
                                        'Other Mental Health Disorders',
                                        'Sexual Dysfunction',
                                        'Cardiovascular Disease (incl. Heart Attack, Rhythm, Valve Disorder or Stroke)',
                                        'Pheaochromocytoma',
                                        'Diagnosed Lactose Allergy',
                                        "Other"
                                    ],
                                },
                            },
                            'Finasteride or propecia': {
                                type: 'string',
                                enum: ['Yes', 'No'],
                            },
                            'Hair loss caused by other medication or illness': {
                                type: 'string',
                                enum: ['Yes', 'No'],
                            },
                            'Hair loss caused by other medication or illness two': {
                                type: 'string',
                                enum: ['Yes', 'No'],
                            },
                            'Hair loss pattern': {
                                type: 'string',
                                enum: [
                                    "Mild",
                                    "Temples / Crown",
                                    "Thinning",
                                    "All over",
                                    " Extensive"
                                ]
                            },
                            'How quickly has hair loss developed': {
                                type: 'string',
                                enum: [
                                    "Suddenly",
                                    "Gradually",
                                    "I have not experienced any hair loss"
                                ]
                            },
                            "Medications used": {
                                type: 'string',
                                enum: [
                                    "finasteride or propecia",
                                    "Minoxidil or regaine",
                                    "Topical finasteride minoxidil combo",
                                    "None"
                                ]
                            },
                            "Minoxidil or regaine": {
                                type: 'string',
                                enum: ['Yes', 'No']
                            },
                            "Previous diagnosis": {
                                type: 'string',
                                enum: [
                                    'Yes gp',
                                    'Yes hair specialist',
                                    'Minoxidil or regaine',
                                    'Topical finasteride minoxidil combo',
                                    'None'
                                ]
                            },
                            'Side effects': {
                                type: 'string',
                                enum: [
                                    'Finasteride',
                                    'Minoxidil',
                                    'No'
                                ]
                            },
                            'What was your gender at birth': {
                                type: 'string',
                                title: 'What was your gender at birth',
                                enum: ['Male', 'Female'],
                            },
                            notes: {
                                type: 'string',
                                format: 'textarea',
                            }
                        }
                    }
                }
            }} />
        </Edit>
    );
}