import React, { useState, useEffect } from "react";
import {
    Box,
    CircularProgress
} from "@mui/material";
import { LineChart } from '@mui/x-charts/LineChart';
import { useMonthChartData } from "./ChartHooks";

export const DashboardMonthOrders = (props) => {
    const [loadingData, setLoadingData] = useState(true);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            const chartDatum = await useMonthChartData()
            if (chartDatum) {
                setChartData(chartDatum.data);
            } else {
                setChartData([])
            }
            setLoadingData(false)
        }
        if (chartData == null) {
            loadData();
        }

    }, [])

    return (
        <Box>
            { loadingData &&
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center"
                     flexDirection="column"
                     sx={ { width: '400px', height: '300px' } }>
                    <span style={ { width: '100%', textAlign: 'center' } }>{ "Loading..." }</span>
                    <CircularProgress />
                </Box>
            }
            { (!loadingData && chartData && chartData.length > 0) &&
                <LineChart
                    dataset={ chartData }
                    xAxis={[ { scaleType: 'band', dataKey: 'day' } ]}
                    series={ [ { dataKey: 'total', label: 'Monthly Sales', color: '#e37b0c' } ] }
                    width={ 650 }
                    height={ props.height }
                    skipAnimation={true}
                />
            }
            { (!loadingData && chartData && chartData.length <= 0) &&
                <span>{"No Data available for charts..."}</span>
            }
        </Box>
    );
}