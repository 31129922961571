import React, { useContext, useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    WithRecord,
    ReferenceManyField,
    Datagrid,
    TextField,
    ReferenceField,
    DateInput,
    List,
    Show,
    DateField,
    Labeled,
    SearchInput,
    SimpleShowLayout,
    SelectField,
    WithListContext,
    useRefresh,
    useUpdate,
    useNotify,
    ShowButton,
    SelectInput,
    Pagination,
    EditButton
} from 'react-admin';
import {
    Box, Button,
    Grid, Link,
    Stack
} from '@mui/material';
import { SubscriptionListProductColumn } from "../../../Lists/components/SubscriptionListProductColumn";

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

export const CustomerOrders = (props) => {

    return (
        <Show title=" " actions={false}>
            <span style={ sectionTitleStyles }>{"Orders"}</span>
            <ReferenceManyField
                perPage={10}
                source="sales_order"
                target="id"
                reference="sales_orders"
                pagination={ <Pagination /> }
                sort={ { field: 'id', order: 'DESC' } }
            >
                <Datagrid
                    bulkActionButtons={false}
                >
                    <TextField source="id" label="ID"/>
                    <TextField source="increment_id" label="Order Number"/>
                    <DateField source="created_at"/>
                    <ReferenceField
                        label="Plan"
                        link={false}
                        reference="sales_subscriptions"
                        source="subscription">
                        <WithRecord label=" " render={
                            record => {
                                return <SubscriptionListProductColumn record={record}/>
                            }
                        } />
                    </ReferenceField>
                    <TextField source="grand_total" label="Price" />
                    <TextField source="refundAmount" label="Refund Amount" />
                    <ReferenceField
                        label="Discount"
                        link={false}
                        reference="sales_subscriptions"
                        source="subscription">
                        <ReferenceField label=" " source="coupons" reference="sales_coupons"
                                        perPage={50}>
                            <TextField source="coupon_code" defaultValue="N/A"/>
                        </ReferenceField>
                    </ReferenceField>
                    <EditButton label="View" target="_blank" />
                </Datagrid>
            </ReferenceManyField>
        </Show>
    );
}