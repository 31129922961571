import React, { useContext, useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    WithRecord,
    ReferenceManyField,
    Datagrid,
    TextField,
    ReferenceField,
    DateInput,
    List,
    Show,
    DateField,
    Labeled,
    SearchInput,
    SimpleShowLayout,
    SelectField,
    WithListContext,
    useRefresh,
    useUpdate,
    useNotify
} from 'react-admin';
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import {
    Box, Button,
    Grid, Link,
    Stack
} from '@mui/material';
import { JsonFormItemisedContainer } from "./Form/Notes/JsonFormItemisedContainer";
import {SubscriptionListProductColumn} from "../Lists/components/SubscriptionListProductColumn";
import { SubscriptionMainDialogEdit } from "./Dialog/SubscriptionMainDialogEdit";
import { CustomerPaymentMethods } from "./components/customer/CustomerPaymentMethods";
import { CustomerActivity } from "./components/customer/CustomerActivity";
import { AddressEditDialog } from "./Dialog/AddressEditDialog";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { sha256 } from 'js-sha256';
import { getAccountAppUrl} from "../utils/UrlManager";

const EditAddressTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{ "Customer - " + record.full_name }</span>
}
const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

const AddressTypes = [
    { id: 'shipping', name: 'Shipping' },
    { id: 'billing', name: 'Billing' },
];


export const CustomerEdit = () => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [update] = useUpdate();
    const record = useRecordContext();


    const handleFormSubmit = async (data) => {
        await update(
            'customer_entities',
            { id: data.id, data: data, previousData: record },
            {
                onSuccess: (data) => {
                    notify("Customer Updated");
                    refresh();
                },
                onError: (error) => {
                    notify("Unable to update customer", { type: 'error' });
                }
            }
        );
    }

    const handleCustomerAccountLogin = (record) => {
        let shaKey = "Luke Leia Han Chewbacca Vader Yoda Obi-Wan Tatooine Endor Hoth Dagobah Falcon Death Star Stormtrooper Lightsaber X-Wing TIE Fighter Emperor Jabba R2-D2 C-3PO Lando Wampa Ewok Bespin Alderaan Jawa Blaster";
        let sha = sha256(shaKey);
        // debugger;
        let jsonValue = {
            id: record.shopifyCustomerId,
            secret: sha
        };
        jsonValue = JSON.stringify(jsonValue);
        let param = btoa(jsonValue);
        let url = getAccountAppUrl();
        return url + param;

    }


    return (
        <>
            { /* Subscriptions */ }
            <Grid container spacing={ 2 } >
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                    <Show title=" " actions={false}>
                        <span style={ sectionTitleStyles }>{"Subscriptions"}</span>
                        <WithRecord label=" " render={
                            record => {
                                return (
                                    <List
                                        sx={{padding: "0 16px"}}
                                        title=" "
                                        actions={false}
                                        storeKey={"customer_edit_subscription_list"}
                                        perPage={5}
                                        resource="sales_subscriptions"
                                        filter={ { 'customer.id': record.id }}
                                        filters={customerFilters}
                                        disableSyncWithLocation
                                    >
                                        <Datagrid
                                            bulkActionButtons={false}
                                        >
                                            <TextField source="id"/>
                                            <ReferenceField label="Customer"
                                                            source="customer"
                                                            reference="customer_entities"
                                                            sortable={false}
                                                            link={false}
                                            >
                                                <TextField source="full_name"/>
                                            </ReferenceField>
                                            <ReferenceField
                                                label="Customer Email"
                                                source="customer"
                                                reference="customer_entities"
                                                link={false}
                                                sortable={false}>
                                                <TextField source="email_address"/>
                                            </ReferenceField>
                                            <DateField source="next_cycle_date" label="Next Order Date"/>
                                            <WithRecord label="Plan" render={
                                                record => {
                                                    return (
                                                        <SubscriptionListProductColumn record={record}/>
                                                    );
                                                }
                                            }/>
                                            <WithRecord label="Frequency" render={
                                                record => {
                                                    let frequency = record.stripeIntervalCount + ' ' + record.stripeInterval;

                                                    return (
                                                        <span>{frequency}</span>
                                                    );
                                                }
                                            }/>
                                            <WithRecord label="Subscription Status" render={
                                                record => {
                                                    let colour = '#000';
                                                    if (record.state === 'paused') {
                                                        colour = '#f36907';
                                                    }
                                                    if (record.state === 'active') {
                                                        colour = '#419d06';
                                                    }

                                                    if (record.resumes && record.state == 'paused') {
                                                        return <span style={ {
                                                            fontsize: '20px',
                                                            fontWeight: 'bold',
                                                            background: '#74ee15',
                                                            padding: '10px',
                                                            color: '#000',
                                                            borderRadius: '10px'
                                                        } } >{ "Processing...." }</span>
                                                    }

                                                    return (
                                                        <TextField label="Subscription Status" source="state"
                                                                   sx={{textTransform: 'capitalize', color: colour}}/>
                                                    );
                                                }
                                            }/>
                                            <ReferenceField label="Discount" source="coupons" reference="sales_coupons"
                                                            perPage={50}>
                                                <TextField source="coupon_code"/>
                                            </ReferenceField>
                                            <Labeled
                                                label="Retry Failed Payment"
                                                sx={
                                                    {
                                                        '& .RaLabeled-label': {display: 'none'},
                                                    }
                                                }>
                                                <Button
                                                    variant="contained"
                                                    disabled>
                                                    <span>{'Retry'}</span>
                                                </Button>
                                            </Labeled>
                                            <Labeled
                                                label="Edit"
                                                sx={
                                                    {
                                                        '& .RaLabeled-label': {display: 'none'},
                                                    }
                                                }>
                                                <SubscriptionMainDialogEdit/>

                                            </Labeled>
                                        </Datagrid>
                                    </List>
                                );
                            }
                        }/>
                    </Show>
                    { /* Customer and address */ }
                    <Grid container spacing={2}>
                        <Grid item xs={ 6 } sx={ { display: 'flex' } }>
                            <Show actions={false} title=" " sx={ { width: '100%', height: '100%', minHeight: '424px' } }>
                                <SimpleShowLayout>
                                    <span style={{
                                        ...sectionTitleStyles,
                                        paddingLeft: 0
                                    }
                                    }>{"Default Customer Addresses"}</span>
                                    <Grid container sx={{width: '100%'}}>
                                        <Grid item xs={6}>
                                            <ReferenceManyField
                                                source="addresses"
                                                reference="sales_order_addresses"
                                                target="id"
                                                filter={{address_type: 'shipping', isDefault: 1}}
                                            >
                                                <WithListContext render={({data}) => {
                                                    if (!data) return null;
                                                    let address;
                                                    if (data && data.length > 0) {
                                                        address = data[0];
                                                    }
                                                    return (
                                                        <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            sx={ { borderRight: '1px solid #696868FF' } }>
                                                            <Box
                                                                sx={ {
                                                                        background: '#eee',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        padding: '5px'
                                                                    } } >
                                                                <span>{"Shipping Address"}</span>
                                                                { (address !== undefined) &&
                                                                    <AddressEditDialog record={address}/>
                                                                }
                                                            </Box>
                                                            { (address !== undefined) &&
                                                                <Stack spacing={2} sx={{padding: '5px'}}>
                                                                    <span>{address.street1}</span>
                                                                    <span>{address.street2}</span>
                                                                    <span>{address.city}</span>
                                                                    <span>{address.county}</span>
                                                                    <span>{address.country}</span>
                                                                    <span>{address.postcode}</span>
                                                                </Stack>
                                                            }
                                                            { (address === undefined) &&
                                                                <span>No Default Billing Address Found</span>
                                                            }
                                                        </Box>
                                                    );
                                                }}/>
                                            </ReferenceManyField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ReferenceManyField
                                                source="addresses"
                                                reference="sales_order_addresses"
                                                target="id"
                                                filter={{address_type: 'billing', isDefault: 1}}
                                            >
                                                <WithListContext render={({data}) => {
                                                    if (!data) return null;
                                                    let address;
                                                    if (data && data.length > 0) {
                                                        address = data[0];
                                                    }
                                                    return (
                                                        <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            sx={ { borderLeft: '1px solid #696868FF' } }>
                                                            <Box
                                                                sx={ {
                                                                        background: '#eee',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        padding: '5px'
                                                                    } } >
                                                                <span>{"Billing Address"}</span>
                                                                { (address !== undefined) &&
                                                                    <AddressEditDialog record={address}/>
                                                                }
                                                            </Box>
                                                            { (address !== undefined) &&
                                                                <Stack spacing={2} sx={{padding: '5px'}}>
                                                                    <span>{address.street1}</span>
                                                                    <span>{address.street2}</span>
                                                                    <span>{address.city}</span>
                                                                    <span>{address.county}</span>
                                                                    <span>{address.country}</span>
                                                                    <span>{address.postcode}</span>
                                                                </Stack>
                                                            }
                                                            { (address === undefined) &&
                                                                <span>No Default Billing Address Found</span>
                                                            }
                                                        </Box>
                                                    );
                                                }}/>
                                            </ReferenceManyField>
                                        </Grid>
                                    </Grid>
                                </SimpleShowLayout>
                            </Show>

                        </Grid>
                        <Grid item xs={ 6 }>
                            <Show actions={false} title=" ">
                                <SimpleShowLayout>
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <span style={sectionTitleStyles}>{"All Customer Addresses"}</span>
                                        <ReferenceManyField
                                            reference="sales_order_addresses"
                                            source="addresses"
                                            target="id"
                                            stickyHeader={true}
                                            sx={{
                                                "& .RaDatagrid-tbody": {
                                                    height: '100px',
                                                    overflowY: 'auto'
                                                }
                                            }}
                                        >
                                            <Datagrid
                                                bulkActionButtons={false}
                                            >
                                                <TextField source="id"/>
                                                <SelectField source="address_type" choices={AddressTypes}/>
                                                <TextField source="street1"/>
                                                <TextField source="postcode"/>
                                                <AddressEditDialog/>
                                            </Datagrid>
                                        </ReferenceManyField>

                                    </Box>
                                </SimpleShowLayout>
                            </Show>

                        </Grid>
                    </Grid>

                    { /* Customer Details  */  }
                    <Edit
                        title={ <EditAddressTitle /> }
                        actions={ false }
                        redirect={false}
                        >

                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                <SimpleForm onSubmit={ handleFormSubmit } >
                                    <span style={
                                        { ...sectionTitleStyles, paddingLeft: 0, margin: '0' }
                                    }> { "Customer Details" } </span>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                            <TextInput name="first_name" source="first_name"/>
                                            <TextInput name="last_name" source="last_name"/>
                                            <TextInput name="email_address" source="email_address"/>
                                            <TextInput name="phone_number" source="phone_number"/>
                                            <Labeled label="Used Coupons">
                                                <WithRecord label="Used Coupons" render={
                                                    record => {
                                                        let usedCoupons = record.usedCoupons;
                                                        let couponString = '';
                                                        if (Array.isArray(usedCoupons)) {
                                                            for(const coupon of usedCoupons) {
                                                                couponString += (coupon + ' ')
                                                            }
                                                        }


                                                        return (
                                                            <span>{ couponString }</span>
                                                        );
                                                    }
                                                }  />
                                            </Labeled>

                                        </Grid>
                                        <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                            <TextInput name="shopify_customer_id" source="shopify_customer_id"/>
                                            <TextInput name="stripe_customer_id" source="stripe_customer_id"/>
                                            <DateInput source="dateOfBirth" name="dateOfBirth"/>
                                            <Labeled label="Customer Account App" sx={ { marginBottom: '40px' } }>
                                                <WithRecord label="" render={
                                                    record => {

                                                        return (
                                                            <Link target="_blank"
                                                                  href={ handleCustomerAccountLogin(record) } >
                                                                { "Login to Customer Account App" }
                                                            </Link>
                                                        );

                                                    }
                                                } />
                                            </Labeled>
                                            <EditInDialogButton
                                                label="Consulation Data"
                                                fullWidth={true}
                                                maxWidth="lg"
                                                ButtonProps={
                                                    {
                                                        variant: 'contained',
                                                        size: 'medium',
                                                        color: 'secondary',
                                                        startIcon: false
                                                    }
                                                }  >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                                        <JsonSchemaForm
                                                            schema={ {
                                                                type: 'object',
                                                                properties: {
                                                                    note: {
                                                                        title: 'Consultation Notes - quick view',
                                                                        type: 'object',
                                                                        properties: {
                                                                            first_name: { type: 'string', title: 'First Name' },
                                                                            last_name: { type: 'string', title: 'Last Name' },
                                                                            email: { type: 'string', title: 'Email Address' },
                                                                            phone: { type: 'string', title: 'Phone Number' },
                                                                            date_of_birth: { type: 'string', title: 'Date of Birth', format: 'date' },
                                                                            what_is_your_gender: { type: 'string', title: 'Gender', enum: ['Male', 'Female'] },
                                                                            average_order_amount: { type: 'string', title: 'Average Order Amount' },
                                                                            consent_medical: { type: 'boolean', title: 'Consent Medical' },
                                                                            accepts_marketing: { type: 'boolean' },
                                                                        }
                                                                    },
                                                                },
                                                            } }
                                                            uiSchema={ {
                                                                average_order_amount: { 'ui:disabled': true },
                                                            } }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                                        <JsonFormItemisedContainer />
                                                    </Grid>
                                                </Grid>

                                            </EditInDialogButton>

                                        </Grid>
                                    </Grid>
                                </SimpleForm>
                            </Grid>
                            <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>

                            </Grid>
                        </Grid>
                    </Edit>
                </Grid>
            </Grid>
            { /* Payment methods */ }
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Show title=" " actions={false}>
                        <span style={sectionTitleStyles}>{"Payment Methods"}</span>
                        <WithRecord label=" " render={
                            record => {
                                return (
                                    <CustomerPaymentMethods record={record}/>
                                );
                            }
                        }/>
                    </Show>

                </Grid>
            </Grid>
            { /* Orders */ }
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Show title=" " actions={false}>
                        <span style={ sectionTitleStyles }>{"Orders"}</span>
                        <ReferenceManyField
                            perPage={10}
                            source="sales_order"
                            target="id"
                            reference="sales_orders">
                            <Datagrid
                                bulkActionButtons={false}
                            >
                                <TextField source="increment_id" label="Order Number"/>
                                <DateField source="created_at"/>
                                <ReferenceField
                                    label="Plan"
                                    link={false}
                                    reference="sales_subscriptions"
                                    source="subscription">
                                    <WithRecord label=" " render={
                                        record => {
                                            return <SubscriptionListProductColumn record={record}/>
                                        }
                                    } />
                                </ReferenceField>
                                <TextField source="grand_total" label="Price" />
                                <ReferenceField
                                    label="Discount"
                                    link={false}
                                    reference="sales_subscriptions"
                                    source="subscription">
                                    <ReferenceField label=" " source="coupons" reference="sales_coupons"
                                                    perPage={50}>
                                        <TextField source="coupon_code" defaultValue="N/A"/>
                                    </ReferenceField>
                                </ReferenceField>
                            </Datagrid>
                        </ReferenceManyField>
                    </Show>
                </Grid>
            </Grid>
            <Grid container spacing={ 2 } sx={ { marginBottom: '80px' } }>
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>
                    <Show title=" " actions={false}>
                        <span style={sectionTitleStyles}>{"Customer's Action History"}</span>
                        <CustomerActivity />
                    </Show>
                </Grid>
            </Grid>
        </>
    );
}