import React, { useState } from "react";
import {
    Button,
    Stack,
    Box,
} from "@mui/material";

import { DashboardTodayOrders } from "./charts/DashboardTodayOrders";
import { DashboardWeekOrders } from "./charts/DashboardWeekOrders";
import { DashboardMonthOrders } from "./charts/DashboardMonthOrders";

export const DashboardInfoTab = () => {
    const chartWidth = 600, chartHeight = 300;
    let presetShowInfoTab = 'open';
    if (window && window.localStorage) {
        presetShowInfoTab = window.localStorage.getItem('presetShowInfoTab') ?? 'open';
    }
    const [showInfoTab, setShowInfoTab] = useState(presetShowInfoTab);

    function toggleInfoTab() {
        if (showInfoTab == 'open') {
            setShowInfoTab('close');
            window.localStorage.setItem('presetShowInfoTab', 'close');
        } else {
            setShowInfoTab('open');
            window.localStorage.setItem('presetShowInfoTab', 'open');
        }
    }

    return (
        <Box display="flex"
             flexDirection="column"
            sx={ {
                padding: '0 0 10px 0',
                borderBottom: '1px solid #eee',
                margin: '0 0 10px 0'
            } }
        >
            <Box display="flex">
                <span style={
                    {
                        fontSize: '19px',
                        fontWeight: 'bold',
                        marginRight: '10px',
                        paddingTop: '2px'
                    }
                } >
                    { "Totals" }
                </span>
                <Button onClick={toggleInfoTab}>
                    { ( showInfoTab ? 'Hide' : 'Show' ) }
                </Button>
            </Box>
            { showInfoTab == 'open' &&
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box>
                        <DashboardTodayOrders
                            width={ chartWidth }
                            height={ chartHeight } />
                    </Box>
                    <Box>
                        <DashboardWeekOrders
                            width={ chartWidth }
                            height={ chartHeight } />
                    </Box>
                    <Box>
                        <DashboardMonthOrders
                            width={ chartWidth }
                            height={ chartHeight } />
                    </Box>
                </Box>
            }
        </Box>
    );
}