import React, { useContext } from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    SelectField,
    useRecordContext,
    useResourceContext,
    DateField,
    WithRecord,
    Show,
    ReferenceManyField
} from 'react-admin';
import {
    Box,
    Stack,
    TextField as MUITextField
} from "@mui/material";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import { Link } from 'react-router-dom';

const PreStyles = {
    minHeight: '40px',
    border: 'none'
};

const ProcessJsonMessageOutput = (props) => {
    // debugger;
    let messageData = props.message;
    console.log(messageData);
    const url = `/sales_subscriptions/${messageData.id}/`;
    let messageArray;
    try {
         messageArray = JSON.parse(messageData.message);
    } catch (error) {
    }

    switch (messageData.type) {
        case 'subscription_change':
            return (
                <Stack sx={ { height: '200px', overflowY: 'auto' } }>
                    <span style={ { fontSize: '18px', fontFamily: 'Aeroport-bold', width: '100%', marginBottom: '10px' } }>{ "Changes:" }</span>
                    { (messageData?.message && !messageArray) &&
                        <Box>
                            <span style={{fontSize: '18px'}}>{ messageData.userName + ' has made a change to a '}</span>
                            <Link style={{fontSize: '18px'}} to={url}>{'subscription'}</Link>
                            <pre
                                title="Click to copy to clipboard"
                                style={ PreStyles }
                                >
                                {  messageData?.message }
                            </pre>
                        </Box>
                    }

                    { (messageArray?.messages?.length > 0) &&
                        <>

                            { messageArray.messages.map((item, index) => {

                                if (item.reource !== undefined) {
                                    let urlValue = `/${item.resource}/${messageData.id}/`;

                                    return (
                                        <Box key={ "activity_monitor_output_key_" + index }>
                                            <span
                                                style={{ fontSize: '18px' }}>{ messageData.userName + ' has made a change to a '}</span>
                                            <Link style={{ fontSize: '18px' }} to={ urlValue }>{ item.resource }</Link>
                                            <pre
                                                style={ PreStyles }
                                            >
                                                { item.message }
                                            </pre>
                                        </Box>
                                    )


                                } else {
                                    return (
                                        <Box key={ "activity_monitor_output_key_" + index }>
                                            <span
                                                style={{fontSize: '18px'}}>{ messageData.userName + ' has made a change to a '}</span>
                                            <Link style={{fontSize: '18px'}} to={url}>{'subscription'}</Link>
                                            <pre
                                                style={ PreStyles }
                                            >
                                                { item.message }
                                            </pre>
                                        </Box>
                                    )
                                }
                            })}

                        </>
                    }
                </Stack>
            );
    }
    return null;
}

const ActivityMonitorExpand = (props) => {
    const record = useRecordContext();
    const resource = useResourceContext();

    return (
        <Show
            title=" "
            resource={ resource }
            id={ record.id }
            recordId={ record.id }
            show={ false }
            sx={ {
                '.RaShow-card': {
                    boxShadow: 'none',
                },
        } }
        >
            <Stack spacing={1} >
                <WithRecord label="" render={
                    record => {
                        //debugger;
                        let isJson = false;
                        if (typeof record.message === 'object') {
                            isJson = true;
                        }

                        if (isJson) {
                            return <ProcessJsonMessageOutput message={record.message} />;

                        }
                        if (!isJson) {
                            return (
                                <pre
                                    style={ PreStyles }
                                >
                                    <Stack>
                                        <span style={{
                                            fontSize: '18px',
                                            fontFamily: 'Aeroport-bold',
                                            width: '100%',
                                            marginBottom: '10px'
                                        }}>{"Changes:"}</span>
                                        <span>{record.message}</span>
                                    </Stack>

                            </pre>
                            );
                        }
                    }
                } />
            </Stack>
        </Show>
    );
}


export const ActivityMonitor = (props) => {
    const context = useContext(AdminUserContext);
    return (
        <List
            title="Activity Monitor"
            sort={ { field: 'id', order: 'DESC' } }
        >
            <Datagrid
                bulkActionButtons={false}
                expand={ ActivityMonitorExpand } expandSingle={ true }
            >
                <TextField source="id" />
                <TextField source="eventType"/>
                <WithRecord label="Entity" render={
                    record => {
                        console.log(record);
                        if (record.subscription) {
                            return (
                                <Link to={ "/sales_subscriptions/" + record.subscription } >
                                    { "Subscription" }
                                </Link>
                            );
                        } else if (record.data?.entityType == 'subscription') {
                            return (
                                <Link to={ "/sales_subscriptions/" + record.data.id } >
                                    { "Subscription" }
                                </Link>
                            );
                        } else if (record.data?.entityType == 'address') {
                            return (
                                <Link to={ "/sales_order_addresses/" + record.data.id } >
                                    { "Address" }
                                </Link>
                            );
                        }

                        return (
                            <span>{ record.entityType }</span>
                        );
                    }
                } />
                <ReferenceField source="adminUser" target="id" reference="admin_users">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="customer" reference="customer_entities">
                    <TextField source="full_name" />
                </ReferenceField>
                <DateField source="createdAt" />
            </Datagrid>
        </List>
    );
}