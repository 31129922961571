import React, {useEffect, useState} from 'react';
import {
    Show,
    TextField,
    ReferenceManyField,
    Datagrid,
    DateField,
    SelectField,
    WithRecord,
    FunctionField,
    TextInput,
    SimpleForm,
    Pagination,
    Toolbar,
    SaveButton
} from 'react-admin';
import { ShowInDialogButton, EditInDialogButton } from "@react-admin/ra-form-layout";
import {
    Box,
    Grid, LinearProgress, Link,
    Stack,
} from '@mui/material';
import { RefundForm } from "./order/RefundForm";
import {useGetStripeMode} from "./hooks/EditSubscriptionModalHooks";
import { usePermissionsManager } from "../../utils/permissionsManager";

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

const refundChoices = [
    { id: 'Adverse event', name: 'Adverse event' },
    { id: 'GOGW', name: 'GOGW' },
    { id: 'Discount code retrospectively', name: 'Discount code retrospectively' },
    { id: 'Discount code not applied', name: 'Discount code not applied' },
    { id: 'Prescription rejected', name: 'Prescription rejected' },
    { id: 'On hold – no response', name: 'On hold – no response' },
    { id: ' On hold – requested to cancel', name: ' On hold – requested to cancel' },
    { id: 'Return', name: 'Return' },
    { id: 'Complaint', name: 'Complaint' },
    { id: 'Sons Tech Issue', name: 'Sons Tech Issue' },
    { id: 'Pharmacy Issue', name: 'Pharmacy Issue' },
];

const PaymentIntentLink = (props) => {
    const record = props.record;
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("test");
    useEffect(() => {
        ( async function () {
                let modeLoad = await useGetStripeMode();
                if (modeLoad?.success) {
                    setMode(modeLoad.mode);
                    setLoading(false);
                }
                if (modeLoad?.error) {
                    setMode('error')
                }
            }
        )();
    }, []);

    if (loading) {
        return <LinearProgress sx={ { width: '50px' } } />;
    }

    if (mode === 'error') {
        return <span>'Failed to generate Stripe Payment link</span>
    }
    if (mode === 'test') {
        return (
            <Link href={ "https://dashboard.stripe.com/" + mode + "/payments/" + record.stripePaymentIntent } target="_blank" >{ record.stripePaymentIntent }</Link>
        );
    }

    return (
        <Link href={ "https://dashboard.stripe.com/payments/" + record.stripePaymentIntent } target="_blank" >{ record.stripePaymentIntent }</Link>
    );
}

const CreditMemoToolbar = () => {
    return (
        <Toolbar>
            <SaveButton label="Save" />
        </Toolbar>
    );
}

export const SalesOrderRefunds = (props) => {

    return (
        <Grid container spacing={ 2 } >
            <Grid item xs={ 6 } >
                <Show title=" " actions={ false }>
                    <Stack spacing={ 2 }>

                        <Box
                            sx={ {
                                ...props.labelStyles,
                                flexDirection: 'column'
                            } } >
                            <span style={ sectionTitleStyles }>{ "Credit memos" }</span>
                            <ReferenceManyField
                                source="creditMemos"
                                reference="credit_memos"
                                target="sales_order"
                                label="Refunds"
                                perPage={ 10 }
                                pagination={ <Pagination /> }
                            >
                                <Datagrid bulkActionButtons={ false }>
                                    <TextField source="id"/>
                                    <FunctionField label="Amount" source="amount" render={ record => '£' + record.amount.toFixed(2)  } />
                                    <DateField showTime source="createdAt"/>
                                    <EditInDialogButton fullWidth maxWidth="md">
                                        <SimpleForm toolbar={ <CreditMemoToolbar /> }>
                                            <Grid container spacing={ 2 } sx={ { padding: '20px' } }>
                                                <Grid item xs={ 6 }>
                                                    <Box
                                                        sx={ props.labelStyles } >
                                                        <span>{ "ID" }</span>
                                                        <TextField source="id"/>
                                                    </Box>
                                                    <Box
                                                        sx={ props.labelStyles } >
                                                        <span>{ "Amount" }</span>
                                                        <FunctionField source="amount" render={ record => '£' + record.amount.toFixed(2)  } />
                                                    </Box>
                                                    <Box
                                                        sx={ props.labelStyles } >
                                                        <span>{ "Stripe Status" }</span>
                                                        <TextField source="stripeStatus" sx={ { textTransform: 'capitalize' } } />
                                                    </Box>
                                                    <Box
                                                        sx={ props.labelStyles } >
                                                        <span>{ "Reason" }</span>
                                                        <SelectField source="reason" choices={ refundChoices } />
                                                    </Box>
                                                    <Box
                                                        sx={ {
                                                            ...props.labelStyles,
                                                            flexDirection: 'column'
                                                        } } >
                                                        <span>{ "Notes" }</span>
                                                        <TextInput source="notes" name="notes" multiline rows={ 8 } />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={ 6 }>
                                                    <WithRecord label=" " render={
                                                        record => {
                                                            return (
                                                                <Box
                                                                    sx={ props.labelStyles } >
                                                                    <span>{ "Stripe Payment" }</span>
                                                                    <PaymentIntentLink record={ record } />
                                                                </Box>
                                                            );
                                                        }
                                                    } />
                                                    <Box
                                                        sx={ props.labelStyles } >
                                                        <span>{ "Stripe Refund ID" }</span>
                                                        <TextField source="stripeRefundId"  />
                                                    </Box>
                                                    <Box
                                                        sx={ props.labelStyles } >
                                                        <span>{ "Stripe Charge ID" }</span>
                                                        <TextField source="stripeChargeId"  />
                                                    </Box>
                                                    <Box
                                                        sx={ props.labelStyles } >
                                                        <span>{ "Created At" }</span>
                                                        <DateField showTime source="createdAt" />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </SimpleForm>

                                    </EditInDialogButton>
                                </Datagrid>
                            </ReferenceManyField>
                        </Box>
                    </Stack>
                </Show>
            </Grid>

            <Grid item xs={ 6 }>
                <RefundForm refundChoices={ refundChoices } labelStyles={ props.labelStyles } />
            </Grid>
        </Grid>
    );
}