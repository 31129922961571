import * as React from 'react';
import {
    AppBar,
    TitlePortal,
    UserMenu,
    useUserMenu,
    Logout,
    UrlField,
    useGetIdentity,
    useAuthProvider
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Avatar
} from '@mui/material';
import SettingsIcon from "@mui/icons-material/Settings";
const Logo = () => {
    return (
        <Box sx={ { paddingTop: '5px' } }>
            <Box
                sx={ { height: '35px' } }
                className="logo"
                component="img"
                src="/images/logo/logo.png"
            />
        </Box>
    );
}

const CustonMenuItem = React.forwardRef((props, ref) => {

    const { onClose } = useUserMenu();
    return (
        <MenuItem
            onClick={onClose}
            ref={ref}
            // It's important to pass the props to allow Material UI to manage the keyboard navigation
            {...props}
            >
            <ListItemIcon>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                <Link to="/my-profile/">My Profile</Link>
            </ListItemText>
        </MenuItem>
    );
});

export const CustomAppBar = () => {
    const auth = useAuthProvider();
    // if (auth) return null;
    const { id, fullName, avatar } = auth.getIdentity();

    function getAvatar() {

        return (
            <Avatar
                sx={{
                    height: 30,
                    width: 30,
                }}
                src={ avatar ?? '' }
            />
        );
    }

    return (
        <AppBar
            sx={{
                background: '#24272A',
                '& .RaAppBar-toolbar': { padding: 0 },
            }}
            userMenu={
                <UserMenu
                    icon={ getAvatar() }
                >
                    <CustonMenuItem />
                    <Logout />
                </UserMenu>

            }
        >

            <TitlePortal />
            <Logo />
            <Box flex="1" />

        </AppBar>
    );
}