import React, { useState, useEffect } from "react";
import { LinearProgress } from '@mui/material';
import { useGetProductPlan } from "./hooks/SubscriptionListColumnHooks";

export const SubscriptionListProductColumn = (props) => {
    const [loading, setLoading] = useState(true);
    const [plan, setPlan] = useState(null);
    const record = props.record;
    let planName = 'Unavailable';
    useEffect(() => {
        const loadPlan = async () => {
            const loadedPlan = await useGetProductPlan({ id: record.id })
            if (loadedPlan) {
                let plans = loadedPlan.data;

                if (plans.length > 1) {
                    let l = plans.length;
                    if ((l - 1) === 0) {
                        l = '';
                    } else {
                        l = l - 1;
                        l = ' + (' + l + ')';
                    }
                    planName = plans[0].name + l;
                }
                if (plans.length === 1) {
                    planName = plans[0].name;
                }
                setPlan(planName);
            }
            setLoading(false);
        }
        if (loading) {
            loadPlan();
        }
    }, [])

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <span>{ (plan !== null ? plan : '') }</span>
    );
}