
import React, {useEffect, useMemo, useState} from "react";
import globalStyles from "./styles/global.scss";
import { Route } from "react-router-dom";
import { i18nProvider } from "./i18n";
import { authProvider } from "./providers/authProvider";
import { CustomRoutes, ListGuesser} from "react-admin";
import Profile from "./Profile";
import {HydraAdmin, AdminGuesser, ResourceGuesser, hydraSchemaAnalyzer} from "@api-platform/admin";
import { Admin } from '@react-admin/ra-enterprise';
import { Resource, Layout, EditGuesser } from 'react-admin';
import { CatalogPharmacyList } from "./Lists/CatalogPharmacy";
import { SalesOrder } from "./Lists/SalesOrder";
import { SalesOrderEdit } from "./Edits/SalesOrderEdit";
import { SalesOrderItems } from "./Lists/SalesOrderItems";
import { SalesSubscription } from "./Lists/SalesSubscription";

import { SalesCoupon } from "./Lists/SalesCoupon";
import { SalesCouponCreate } from "./create/SalesCouponCreate";
import { SalesCouponEdt } from "./Edits/SalesCouponEdit";

import { ShippingMethods } from "./Lists/ShippingMethods";
import { CatalogProductImages } from "./Lists/CatalogProductImages";
import { SalesOrderAddress } from "./Lists/SalesOrderAddress";
import { Customers } from "./Lists/Customers";
import { CatalogProducts } from "./Lists/CatalogProducts";
import { CatalogProductPlans } from "./Lists/CatalogProductPlans";
import { CatalogProductPrices } from "./Lists/CatalogProductPrices";
import { MessengerMessages } from "./Lists/MessengerMessages";
import { ActivityMonitor } from "./Lists/ActivityMonitor";
import MonitorIcon from '@mui/icons-material/Monitor';

import Login from "./Login/Login";
import { SonsTheme } from "./theme/SonsTheme";
import {Box} from "@mui/material";
import MedicationIcon from '@mui/icons-material/Medication';
import Person2Icon from '@mui/icons-material/Person2';
import HomeIcon from '@mui/icons-material/Home';
import GradingIcon from '@mui/icons-material/Grading';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ImageIcon from '@mui/icons-material/Image';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import PollIcon from '@mui/icons-material/Poll';

import { AppLocationContext } from '@react-admin/ra-navigation';

import { CustomAppBar } from "./Nav/CustomAppBar";
import AdminMenu from './Menu/AdminMenu'

import { dataProvider } from "./providers/dataProvider";
import AdminUserContextProvider from "./contexts/AdminUserContextProvider";

import { CustomerEdit } from "./Edits/CustomerEdit";
import { SubscriptionEdit } from "./Edits/SubscriptionEdit";
import SummarizeIcon from '@mui/icons-material/Summarize';

import { Dashboard } from "./Defaults/Dashboard";

import { AdminUserList } from "./Lists/AdminUserList";
import { AdminUserEdit } from "./Edits/AdminUserEdit";
import { AdminUserCreate } from "./create/AdminUserCreate";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';

import { AdminUserProfileEdit } from "./profile/AdminUserProfileEdit";

import { SettingsList } from "./Lists/SettingsList";
import { SettingsCreate } from "./create/SettingsCreate";
import { ActionOverlay } from "./Global/ActionOverlay";

import { LogList } from "./Lists/LogList";

import { SurveyList } from "./Lists/SurveyList";
import { SurveyEdit } from "./Edits/SurveyEdit";
import { SurveyArchiveList } from "./Lists/SurveArchiveList";
import { SurveyArchiveEdit } from "./Edits/SurveyArchiveEdit";

import { SurveyAnalytics } from "./survey/SurveyAnalytics";
import { SurveysList } from "./Lists/SurveysList";
import { SurveyCreate } from "./create/SurveyCreate";
import { SurveysEdit } from "./Edits/SurveysEdit";

const CustomLayout = (props) => {
    return (
        <AppLocationContext>
            <Layout {...props} appBar={CustomAppBar} menu={ AdminMenu } />
        </AppLocationContext>
    );
}

//Disabled console log
console.log = function(){}

export default function App() {

    return (
        <AdminUserContextProvider>
            <Admin
                entrypoint={window.origin}
                dataProvider={dataProvider}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                loginPage={Login}
                theme={ SonsTheme }
                layout={ CustomLayout }
                dashboard={ Dashboard }
            >
                <Resource
                    name="sales_subscriptions"
                    hasCreate={true}
                    hasEdit={true}
                    edit={ SubscriptionEdit }
                    list={SalesSubscription}
                    options={{ label: 'Subscriptions' }}
                    icon={ CardMembershipIcon }
                />
                <Resource
                    name="catalog_pharmacy_lookup"
                    hasCreate={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={CatalogPharmacyList}
                    options={{ label: 'Pharmacy Lookup' }}
                    icon={ MedicationIcon }
                />
                <Resource
                    name="customer_entities"
                    hasCreate={true}
                    hasShow={true}
                    hasEdit={true}
                    edit={ CustomerEdit }
                    list={Customers}
                    options={{ label: 'Customers' }}
                    icon={ Person2Icon }
                />
                <Resource
                    name="sales_order_addresses"
                    hasCreate={true}
                    hasShow={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={SalesOrderAddress}
                    options={{ label: 'Customer Addresses' }}
                    icon={ HomeIcon }
                />
                <Resource
                    name="sales_orders"
                    hasCreate={true}
                    hasEdit={true}
                    edit={ SalesOrderEdit }
                    list={ SalesOrder }
                    options={{ label: 'Orders' }}
                    icon={ GradingIcon }
                />
                <Resource
                    name="sales_order_items"
                    hasCreate={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={SalesOrderItems}
                    options={{ label: 'Ordered Items' }}
                    icon={ EventNoteIcon }
                />

                <Resource
                    name="sales_coupons"
                    hasCreate={true}
                    hasEdit={true}
                    edit={SalesCouponEdt}
                    list={SalesCoupon}
                    create={ SalesCouponCreate }
                    options={{ label: 'Coupons' }}
                    icon={ QrCode2Icon }
                />
                <Resource
                    name="sales_shipping_methods"
                    hasCreate={true}
                    hasShow={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={ShippingMethods}
                    options={{ label: 'Shipping Methods' }}
                    icon={ LocalShippingIcon }
                />
                <Resource
                    name="catalog_product_entities"
                    hasCreate={true}
                    hasShow={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={CatalogProducts}
                    options={{ label: 'Products' }}
                    icon={ InventoryIcon }
                />
                <Resource
                    name="catalog_product_prices"
                    hasCreate={true}
                    hasShow={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={CatalogProductPrices}
                    options={{ label: 'Prices' }}
                    icon={ LocalOfferIcon }
                />
                <Resource
                    name="catalog_product_images"
                    hasCreate={true}
                    hasShow={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={CatalogProductImages}
                    options={{ label: 'Images' }}
                    icon={ ImageIcon }
                />
                <Resource
                    name="catalog_product_plans"
                    hasCreate={true}
                    hasShow={true}
                    hasEdit={true}
                    edit={EditGuesser}
                    list={CatalogProductPlans}
                    options={{ label: 'Plans' }}
                    icon={ PendingActionsIcon }
                />

                <Resource
                    name="message_messengers"
                    list={MessengerMessages}
                    icon={ SummarizeIcon }
                    />

                <Resource
                    name="activity_monitors"
                    list={ ActivityMonitor }
                    icon={ MonitorIcon }
                    />

                <Resource name="admin_users"
                    list={ AdminUserList }
                          edit={AdminUserEdit}
                          create={AdminUserCreate}
                      icon={ AccessibilityNewIcon }
                />

                <Resource name="settings"
                    create={SettingsCreate}
                          list={SettingsList}
                />

                <Resource name="error_logs"
                          list={LogList}
                          icen={ CrisisAlertIcon }
                          options={ { label: 'Error Logs' } }
                />

                <Resource name="survey_forms"
                            list={SurveyList}
                          edit={ SurveyEdit }
                          icon={ PollIcon }
                          options={ { label: 'Surveys Results' } }
                />

                <Resource name="survey_forms_archives"
                          list={SurveyArchiveList}
                          edit={ SurveyArchiveEdit }
                          icon={ PollIcon }
                          options={ { label: 'Surveys Archive' } }
                />

                <Resource name="surveys"
                    list={ SurveysList}
                          create={ SurveyCreate }
                          edit={ SurveysEdit }
                />

                <Resource name="credit_memos" />

                <CustomRoutes >
                    <Route path="/my-profile/" element={<AdminUserProfileEdit />} />
                    <Route path="/survey_analytics" element={<SurveyAnalytics />} />
                </CustomRoutes>
                <Resource name="profile" />
                <Resource name="survey_analytics" />
            </Admin>
            <ActionOverlay />
        </AdminUserContextProvider>
    );
}