import React, {  useState, useEffect } from "react";
import {
    Button,
    Box,
    LinearProgress,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    TableBody
} from "@mui/material";
import { useCustomerGetPaymentMethods } from "../hooks/CustomerEditHooks";

export const CustomerPaymentMethods = (props) => {
    const [loading, setLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const record = props.record;
    if (!record) {
        return null;
    }
    useEffect(() => {
        const loadPaymentMethods = async () => {
            return await useCustomerGetPaymentMethods({ id: record.id })
        }
        if (loading){
            loadPaymentMethods().
                then(result => {
                    if ( typeof result !== "undefined" && result.status === 'success') {
                        setPaymentMethods(result.data);
                    }
                    setLoading(false);
            })
        }
    }, []);

    return (
        <Box sx={ { minHeight: '100px' } }>
            { loading &&
                <Box sx={
                    {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center' ,
                        flexDirection: 'column'
                    }
                }>
                    <span style={ { marginBottom: '20px' } } >{ "Loading Payment Methods..." }</span>
                    <LinearProgress sx={ { width: '90%' } } />
                </Box>
            }
            { !loading &&
                <Box>
                    { (paymentMethods.length <= 0) &&
                        <Box>
                            <span>{ "No payment methods found" }</span>
                        </Box>
                    }
                    { (paymentMethods.length > 0) &&
                        <TableContainer sx={{ maxHeight: 300 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={ { fontWeight: 'bold' } } >{ "Number" }</TableCell>
                                        <TableCell sx={ { fontWeight: 'bold' } } >{ 'Expires' }</TableCell>
                                        <TableCell sx={ { fontWeight: 'bold' } } >{ "Type" }</TableCell>
                                        <TableCell sx={ { fontWeight: 'bold' } } >{ "Modify" }</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { paymentMethods. map((method, index) => {

                                        return (
                                            <TableRow key={ "payment_method_table_row_" + index }>
                                                <TableCell> { "XXXX XXXX XXXX " + method.last } </TableCell>
                                                <TableCell>{ method.expires }</TableCell>
                                                <TableCell sx={ { textTransform: 'capitalize' } } >{ method.type }</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled
                                                    >
                                                        { "Change Payment Details" }
                                                    </Button>
                                                </TableCell>
                                            </TableRow>

                                        );
                                    }) }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Box>
            }
        </Box>
    );
}