import React from 'react';
import {
    ReferenceManyField,
    Datagrid,
    TextField,
    Show,
    DateField,
    ShowButton,
    Pagination,
} from 'react-admin';

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

export const CustomerSurvey = (props) => {

    return (
        <Show title=" " actions={ false }>
            <span style={ sectionTitleStyles }>{"Survey Data"}</span>
            <ReferenceManyField
                source="surveyForms"
                reference="survey_forms"
                target="id"
                label="Survey Results"
                pagination={ <Pagination /> }
                perPage={ 10 }
            >
                <Datagrid
                    bulkActionButtons={ false }
                >
                    <TextField source="id"/>
                    <TextField source="surveyType" sx={ { textTransform: 'capitalize' } }/>
                    <DateField source="createdAt" showTime={ true }/>
                    <ShowButton target="_blank"/>
                </Datagrid>
            </ReferenceManyField>
        </Show>
    );
}