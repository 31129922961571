import React, { useContext } from 'react';
import {
    Edit,
} from 'react-admin';
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { usePermissionsManager } from "../utils/permissionsManager";

export const SurveyArchiveEdit = (props) => {
    const hideForUser = usePermissionsManager({  accessToRoles: ['ROLE_COMMERCE'] })

    return (
        <Edit>
            <JsonSchemaForm schema={{
                type: 'object',
                properties: {
                    email_address: { type: 'string', format: 'email' },
                    reference_id: { type: 'string' },
                    store_view: { type: 'string' },
                    ip_address: { type: 'string' },
                    survey_json: {
                        type: 'object',
                        title: 'Form Data',
                        properties: {
                            approved: { type: 'number' },
                            country: { type: 'string' },
                            date: { type: 'string', format: 'date-time' },
                            deleted: { type: 'number' },
                            entry_id: { type: 'number' },
                            form_id: { type: 'number' },
                            payed: { type: 'number' },
                            read: { type: 'boolean' },
                            status: { type: 'number' },
                            content: {
                                type: 'object',
                                properties: {
                                    browser: { type: 'string' },
                                    cc: { type: 'string' },
                                    'couponcode': { type: 'string' },
                                    date: { type: 'string', format: 'date-time' },
                                    'dateTimeUnix': { type: 'string' },
                                    'datestart': { type: 'string', format: 'date-time' },
                                    'formhost': { type: 'string', format: 'uri' },
                                    ip: { type: 'string' },
                                    lang: { type: 'string' },
                                    paymcur: { type: 'string' },
                                    paymsel: { type: 'string' },
                                    paymsum: { type: 'string' },
                                    paymtype: { type: 'string' },
                                    'quizScore': { type: 'string' },
                                    referer: { type: 'string' },
                                    'refid':  { type: 'string' },
                                    'sentimentMagnitude': { type: 'string' },
                                    'sentimentMark': { type: 'string' },
                                    'sentimentScore': { type: 'string' },
                                    'useragent': { type: 'string' },
                                    fields: {
                                        type: 'object',
                                        title: "",
                                        properties: {
                                            field: {
                                                type: 'array',
                                                title: "Fields",
                                                items: {
                                                    type: 'object',
                                                    properties: {
                                                        'fieldvalue': { type: ['string', 'array'] },
                                                        'fieldid': { type: 'string' },
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }}
            uiSchema={{
                "ui:submitButtonOptions": { norender: hideForUser },
            }}

            />
        </Edit>
    );
}