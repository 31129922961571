import React, { useContext } from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    SelectField,
    useRecordContext,
    useResourceContext,
    DateField,
    WithRecord,
    Show,
    ReferenceManyField,
    SearchInput,
    TextInput
} from 'react-admin';
import {
    Box,
    Stack,
    TextField as MUITextField,
    Link
} from "@mui/material";

const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];


export const LogList = () => {

    return (
        <List
            title="Error Logs"
            perPage={25}
            actions={ false }
            filters={ customerFilters }
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid bulkActionButtons={ false }>
                <TextField source="id" />
                <DateField source="createdAt" showTime />
                <TextField source="type" />
                <ReferenceField source="customer" reference="customer_entities">
                    <TextField source="full_name" />
                </ReferenceField>
                <ReferenceField source="subscription" reference="sales_subscriptions" link={false}>
                    <WithRecord label="" render={
                        record => {
                            return (
                                <Link href={"/#/customer_entities/" + record.customer}>
                                    { record.stripe_subscription_id }
                                </Link>
                            );
                        }
                    } />
                </ReferenceField>
                <WithRecord label="Message" render={
                    record => {
                        return (
                            <textarea readOnly={true}
                                      value={ record.message }
                                      style={ { width: '100%', minHeight: '200px', height: 'auto' } } />
                        );
                    }
                } />
            </Datagrid>
        </List>
    );
}