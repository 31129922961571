import React from "react";
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    TextField, useRecordContext,
    WithRecord,
    usePermissions
} from "react-admin";
import { SubscriptionListProductColumn } from "../../../../../../Lists/components/SubscriptionListProductColumn";
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import {
    Box,
    Grid,
    Stack
} from "@mui/material";

const fieldItemStyles = {
    display: "flex",
    justifyContent: "space-between",
};

const PreStyles = {
    minHeight: '40px',
    border: 'none',
    maxWidth: '100%'
};

export const SubscriptionActivityTab = (props) => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    if (!record) return null;

    return(
        <List
            title=" "
            actions={ false }
            storeKey={"subscription_edit_modal_activity_monitor_events"}
            perPage={ 5 }
            resource="activity_monitors"
            filter={ { "subscription.id": record.id } }
            disableSyncWithLocation
            sort={ { field: 'id', order: 'DESC' } } >
            <Datagrid
                bulkActionButtons={false}
            >
                <TextField source="id" />
                <DateField showTime={true} label="Date" source="createdAt"/>
                <TextField label="Action" source="eventType"/>
                <ReferenceField
                    label="Details"
                    source="subscription"
                    reference="sales_subscriptions"
                    link={false}
                >
                    <WithRecord label=" " render={
                        rec => {
                            return (
                                <SubscriptionListProductColumn record={rec}/>
                            );
                        }
                    }/>
                </ReferenceField>
                <ReferenceField
                    label="Frequency"
                    source="subscription"
                    reference="sales_subscriptions"
                    link={false}
                >
                    <TextField source="stripeFrequency" />
                </ReferenceField>
               <WithRecord label="Changed By" render={
                   record => {
                       if (record.adminUser && record.adminUser !== '') {
                           return (
                               <ReferenceField
                                   source="adminUser"
                                   reference="admin_users"
                                   link={ (permissions && permissions.includes('ROLE_SUPER_ADMIN') ? undefined : false) }
                               >
                                   <TextField source="name" />
                               </ReferenceField>
                           );
                       }
                       if (record.customer && record.customer !== '') {

                            return (
                                <ReferenceField source="customer" reference="customer_entities">
                                    <TextField source="full_name" />
                                </ReferenceField>
                            );
                       }

                       if (record.systemChange){
                           return <span>{ "System" }</span>
                       }

                   }
               } />
                <ShowInDialogButton
                    title=" "
                    label="View More"
                    fullWidth={true}
                    maxWidth="xl"
                >
                    <Grid container spacing={2} sx={ { padding: '16px' } }>
                        <Grid item xs={6}>
                            <Stack spacing={2}>
                                <Box sx={ fieldItemStyles }>
                                    <span>Id:</span>
                                    <TextField label="Id" source="id" />
                                </Box>
                                <Box sx={ fieldItemStyles }>
                                    <span>Date:</span>
                                    <DateField label="Date" source="createdAt"/>
                                </Box>
                                <Box sx={ fieldItemStyles }>
                                    <span>Action:</span>
                                    <TextField label="Action" source="eventType"/>
                                </Box>
                                <Box sx={ fieldItemStyles }>
                                    <span>Message:</span>
                                    <TextField label="Message" source="message"/>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={2}>
                                <Box sx={ fieldItemStyles }>
                                    <span>Data:</span>
                                    <WithRecord label=" " render={
                                        record => {
                                            let val = record.data ?? record.message;
                                            val = JSON.stringify(val, null, 2);
                                            return(
                                                <pre
                                                    style={ PreStyles }
                                                >
                                                { val }
                                            </pre>
                                            );
                                        }
                                    } />
                                </Box>

                            </Stack>
                        </Grid>
                    </Grid>
                </ShowInDialogButton>
            </Datagrid>
        </List>
    );
}