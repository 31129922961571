import React, { useMemo, useState, useContext } from 'react';
import {
    ImageField,
    ImageInput,
    PasswordInput,
    required,
    SaveContextProvider,
    TabbedForm,
    TextInput,
    useDataProvider,
    useEditController,
    useGetIdentity,
    useNotify,
    useUpdate,
    usePermissions,
    Title,
    SelectArrayInput,
    Edit,
    useAuthProvider
} from 'react-admin';

import { AdminUserContext } from "../contexts/AdminUserContextProvider";


export const AdminUserProfileEdit = () => {
    const adminUserContext = useContext(AdminUserContext);
    const notify = useNotify();
    const [saving, setSaving] = useState(false);
    const auth = useAuthProvider();
    // if (auth) return null;
    const { id, fullName, avatar } = auth.getIdentity();
    const { record, save, isLoading, refetch } = useEditController({ resource: 'admin_users', id });
    const [update] = useUpdate();
    const idCard = useGetIdentity();
    const { permissions } = usePermissions();

    const handleSave = (values) => {
        setSaving(true);
        if (typeof values['new_password'] !== undefined &&
            typeof values['repeat_new_password'] !== undefined &&
            typeof values['old_password'] !== undefined &&
            values['new_password'] !== values['repeat_new_password']) {
            notify("Your new passwords doesn't match, please make sure they match", { type: 'error' });
        } else {
            update(
                'admin_users',
                { id: record.id, data: values, previousData: record },
                {
                    onSuccess: (data) => {
                        idCard.identity.avatar = data['avatar'];
                        idCard.identity.fullName = data['name'];
                        idCard.identity.email = data['email'];
                        idCard.identity.name = data['name'];
                        // refetch(); //reload the record
                        idCard.refetch(); //reload the identity
                        refetch();
                        notify("Your Profile has been updated");
                    },
                    onError: (error) => {
                        notify(`Failed to update error: ${error.message}`, {type: 'error'});
                    },
                }
            );
        }
    }

    if (isLoading) {
        return <h1>Loading...</h1>;
    }

    return (
        <Edit
            redirect={false}
            title=" "
            id={ record.id }
            resource="admin_users" >
            <Title title={ (record.name ?? 'Un-named') + "'s Profile" } />
            <TabbedForm onSubmit={ handleSave } syncWithLocation={false}>
                <TabbedForm.Tab label="Profile">
                    <TextInput disabled name="profile_edit" source="profile_edit" defaultValue="1" sx={{ display: 'none' }} />
                    <TextInput name="name" source="name"fullWidth />
                    <TextInput name="email" source="email" validate={[required()]} fullWidth autoComplete="email" />
                    <ImageInput name="avatar" source="avatar" label="Avatar" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </TabbedForm.Tab>
                {/*{ (permissions && permissions.includes('ROLE_SUPER_ADMIN'))  &&*/}
                {/*    <TabbedForm.Tab label="Roles">*/}
                {/*        <SelectArrayInput name="roles" source="roles" choices={ adminUserContext.adminUserRoles } optionValue="id" />*/}
                {/*    </TabbedForm.Tab>*/}
                {/*}*/}
                <TabbedForm.Tab label="Password">
                    <PasswordInput name="new_password" source="new_password" fullWidth autoComplete="new-password" />
                    <PasswordInput name="repeat_new_password" source="repeat_new_password" fullWidth autoComplete="new-password" />
                    <PasswordInput name="old_password" source="old_password" fullWidth autoComplete="current-password" />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
}