import { hostUrl } from "../../config";
import { useUrlManagerCleanUrl } from "../../utils/UrlManager";

export const useStripeDataSync = async (data) => {
    const token = localStorage.getItem("auth");
    let url = hostUrl + 'barber/subscription/sync';
    url = useUrlManagerCleanUrl({ url: url });
    const request = new Request(url, {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin',
        })
    });
    try {
        const response = await fetch(request);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}