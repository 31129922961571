import React, { useContext } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    PasswordInput,
    required,
    SelectArrayInput,
} from 'react-admin';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

export const AdminUserCreate = () => {
    const adminUserContext = useContext(AdminUserContext);

    return (
        <Create>
            <SimpleForm>
                <TextInput name="name" source="name" validate={[required()]} fullWidth />
                <TextInput name="email" source="email" validate={[required()]} fullWidth />
                <SelectArrayInput name="roles" source="roles" choices={ adminUserContext.adminUserRoles } optionValue="id" />
                <PasswordInput name="password" source="password" validate={[required()]} fullWidth />
            </SimpleForm>
        </Create>

    );
}