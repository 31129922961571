import React from 'react';
import {
    TextField,
    DateField,
    ReferenceManyField,
    Datagrid,
    Pagination, EditButton
} from 'react-admin';

export const OrderHistoryTab = (props) => {

    return (
        <ReferenceManyField
            perPage={10}
            source="orders"
            reference="sales_orders"
            target="subscription"
            pagination={ <Pagination /> }
            sort={ { field: 'id', order: 'DESC' } }
        >
            <Datagrid bulkActionButtons={ false }>
                <TextField source="id" />
                <TextField source="increment_id" label="Order Number"/>
                <DateField source="created_at"/>
                <TextField source="grand_total" label="Price" />
                <TextField source="refundAmount" label="Refund Amount" />
                <EditButton label="View" target="_blank" />
            </Datagrid>
        </ReferenceManyField>
    );
}