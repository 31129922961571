import { apiBase, baseUrl, hostUrl } from '../../config.js'
import { useUrlManagerCleanUrl } from "../../utils/UrlManager";

/**
 * Admin User Data Provider
 * @type {{deleteMany: (function(*, *): Promise<never>), updateMany: (function(*, *): Promise<never>), updateUserProfile: adminUserDataProvider.updateUserProfile, getList: (function(*, *): Promise<{total: number, data: *[]}>), getMany: (function(*, *): Promise<never>), getManyReference: (function(*, *): Promise<never>), getOne: (function(*, *): Promise<{data: {}}>), create: (function(*, *): Promise<{data: {}}>), update: (function(*, *): Promise<*>), getProfileData: ((function(*): (Promise<Awaited<{data: any}>>))|*), delete: (function(*, *): Promise<never>)}}
 */
const adminUserDataProvider  = {
    // get a list of records based on sort, filter, and pagination
getList:    async (resource, params) => {
    let token = localStorage.getItem('auth');
    let url = baseUrl + resource;
    if (params.pagination?.page) {
        if (url.includes('?')) {
            url += '&page=' + params.pagination.page;
        } else {
            url += '?page=' + params.pagination.page;
        }
    }
    const request = new Request(url, {
        method: 'GET',
        headers: new Headers({
            'Content-Type' : 'application/json',
            // 'Authorization': 'Bearer ' + token
            'Token':  token,
            'Barbershop' : 'admin'
        })
    });
    return fetch(request)
        .then(response => {
            console.log(response)
            if (response.status < 200 || response.status >= 300 && response.status < 500) {
                localStorage.removeItem('auth');
                localStorage.removeItem('profile');
                return Promise.reject( { redirectTo: '/login' });
            }
            return response.json();
        })
        .then(data => {
            // debugger;
            let responseData= {
                data: [],
                total: 0
            };
            if (Array.isArray(data['hydra:member'])) {
                let users = data['hydra:member'];
                let totalItems = data['hydra:totalItems'];

                for (let i = 0; i < users.length; i++) {
                    let user = users[i];
                    let roles = '';
                    for (var j = 0; j < user['roles'].length; j++) {
                        let role = user['roles'][j];
                        roles += role;
                    }
                    let datum = {
                        id: user['id'],
                        name: user['name'],
                        email: user['email'],
                        roles: roles
                    };
                    responseData.data.push(datum);
                }
                responseData.total = totalItems;
            }
            return responseData;
        })
        .catch(error => {
            return Promise.reject('login' );
        });
    //
},
// get a single record by id
getOne:     async (resource, params) => {
    let token = localStorage.getItem('auth');
    const request = new Request(baseUrl +  resource + '/' + params.id, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Token':  token,
            'Barbershop' : 'admin'
        })
    });
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300 && response.status < 500) {
                localStorage.removeItem('auth');
                localStorage.removeItem('profile');
                return Promise.reject( { redirectTo: '/login' });
            }
            return response.json();
        })
        .then(data => {
            let responseData = {
                data: {}
            };
            if (data['id']) {
                console.log(data);
                let roles = '';
                for (var i = 0; i < data['roles'].length; i++) {
                    roles += data['roles'][i];
                }
                let avatar = data['avatar'];
                if (avatar) {
                    avatar = hostUrl + avatar;
                }
                responseData.data = {
                    id: data['id'],
                    name: data['name'],
                    email: data['email'],
                    avatar: {
                        'src': avatar, 'title': data['username'] + ' Avatar'
                    },
                    roles: data['roles']
                };
            }
            return responseData;
        })
        .catch(error => {
            return Promise.reject('login' );
        });
},
// get a list of records based on an array of ids
getMany:    (resource, params) => {
    let token = localStorage.getItem('auth');
    if (!token) {
        return Promise.reject('login');
    }

    let url = baseUrl + resource;
    if (params.pagination?.page) {
        if (url.includes('?')) {
            url += '&page=' + params.pagination.page;
        } else {
            url += '?page=' + params.pagination.page;
        }
    }
    if (params.pagination?.perPage) {
        if (url.includes('?')) {
            url += '&itemsPerPage=' + params.pagination.perPage;
        } else {
            url += '?itemsPerPage=' + params.pagination.perPage;
        }
    } else {
        if (url.includes('?')) {
            url += '&itemsPerPage=50';
        } else {
            url += '?itemsPerPage=50';
        }
    }
    url = processGetManyFilter(url, params);
    const request = new Request(url, {
        method: 'GET',
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin'
        })
    })
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300 && response.status < 500 && response.status !== 404) {
                localStorage.removeItem('profile');
                localStorage.removeItem('auth');
                return Promise.reject( );
            }
            return response.json();
        })
        .then(data => {
            let responseData= {
                data: [],
                total: 0
            };
            if (Array.isArray(data['hydra:member'])) {
                let users = data['hydra:member'];
                let totalItems = data['hydra:totalItems'];

                for (let i = 0; i < users.length; i++) {
                    let user = users[i];
                    let roles = '';
                    for (var j = 0; j < user['roles'].length; j++) {
                        let role = user['roles'][j];
                        roles += role;
                    }
                    let datum = {
                        id: user['id'],
                        name: user['name'],
                        email: user['email'],
                        roles: roles
                    };
                    responseData.data.push(datum);
                }
                responseData.total = totalItems;
            }
            return responseData;
        })
        .catch(error => {
            // console.log(error);
            return Promise.reject('login' );
        })
},
    // get the records referenced to another record, e.g. comments for a post
getManyReference: (resource, params) => {
    return Promise.reject();
},
    // create a record
create:  async   (resource, params) => {
    let token = localStorage.getItem('auth');
    // let roles = params.data.roles;
    // params.data.roles = [roles];

    let roles = params.data.roles;
    if (!roles.includes('ROLE_ADMIN')) {
        roles.push('ROLE_ADMIN');
        params.data.roles = roles;
    }

    const request = new Request(baseUrl +  resource, {
        method: 'POST',
        body: JSON.stringify(params['data']),
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin'
        })
    });
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300 && response.status < 500) {
                localStorage.removeItem('auth');
                localStorage.removeItem('profile');
                return Promise.reject( { redirectTo: '/login' });
            }
            return response.json()
        })
        .then(data => {
            let responseData = {
                data: {}
            };
            if (data['id']) {
                responseData.data = {
                    id: data['id'],
                    username: data['username'],
                };
            }
            return responseData;
        })
        .catch( error => {
            return Promise.reject('login' );
        });

},

// update a record based on a patch
update: async (resource, params) => {
    try {
        const image = await convertFileToBase64(params.data.avatar);
        params.data.file_name = params.data.avatar.title;
        params.data.avatar = image ?? '';

        return await updateUser(resource, params);
    } catch (error) {
        console.log(error);
        params.data.file_name = '';
        params.data.avatar = '';
        return await updateUser(resource, params);
    }
},
// update a list of records based on an array of ids and a common patch
updateMany: async (resource, params) => {
    return Promise.reject();
},
// delete a record by id
delete:   async  (resource, params) => {
    let token = localStorage.getItem('auth');
    let url = baseUrl + resource + '/' + params.id;
    const request= new Request(url, {
        method: 'DELETE',
        headers: new Headers({
            'Content-Type' : 'application/json',
            'Token':  token,
            'Barbershop' : 'admin'
        })
    });
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300 && response.status < 500) {
                localStorage.removeItem('auth');
                localStorage.removeItem('profile');
                return Promise.reject( { redirectTo: '/login' });
            }
            return Promise.resolve();
        })
        .catch( error => {
            return Promise.reject('login' );
        });
},
    // delete a list of records based on an array of ids
    deleteMany: async (resource, params) => {
    return Promise.reject();
},
    getProfileData: async (params) => {
    const storedProfile = localStorage.getItem("profile");

    if (storedProfile) {
        return Promise.resolve({
            data: JSON.parse(storedProfile),
        });
    }
    return Promise.reject({
        redirect: 'admin_users'
    });
}
}

/**
 * Update the user
 * @param resource
 * @param params
 * @returns {Promise<any>}
 */
const updateUser = (resource, params) => {
    let roles = params.data.roles;
    // debugger;
    // params.dadta.roles = [roles];
    let token = localStorage.getItem('auth');
    let url = baseUrl +  resource + '/' + params.id;
    url = useUrlManagerCleanUrl({ url: url });
    const request= new Request(url, {
        method: 'PATCH',
        body: JSON.stringify(params['data']),
        headers: new Headers({
            'Content-Type' : 'application/merge-patch+json',
            'Token':  token,
            'Barbershop' : 'admin'
        })

    });
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300 && response.status < 500) {
                localStorage.removeItem('auth');
                localStorage.removeItem('profile');
                return Promise.reject( { redirectTo: '/login' });
            }
            return response.json();
        })
        .then(data => {
            let profile = JSON.parse(localStorage.getItem('profile'));
            let responseData = {
                data: {}
            };

            if (data['id']) {

                if (data['id'] === profile.id) {
                    profile['avatar'] = data['avatar'] ? hostUrl + data['avatar'] : '';
                    profile['fullName'] = data['name'];
                    localStorage.setItem('profile', JSON.stringify(profile));
                }
                responseData.data = {
                    id: data['id'],
                    username: data['username'],
                    name: data['name'],
                    email: data['email'],
                    avatar: {
                        'src': data['avatar'], 'title': data['username'] + ' Avatar'
                    }
                };
            }
            if (responseData.data['id']) {
                return responseData;
            } else {
                return Promise.reject();
            }
        })
        .catch(error => {
            return Promise.reject('login' );
        });
};

/**
 * Convert image blob to base64 for send to API
 * @param file
 * @returns {Promise<unknown>}
 */
const convertFileToBase64 = file => {
    if (file == undefined || file == null) {
        return '';
    }
    if (typeof file.src === "undefined" || file === "" || file.src === "") {
        return new Promise((resolve, reject) => {
            resolve('');
        });
    } else if (file.src.includes('/images/admin/avatar')) {
        if (file.src.startsWith(hostUrl)) {
            file.src = file.src.replace(hostUrl, '');
        }
        return new Promise((resolve, reject) => {
            resolve(file.src);
        });
    } else {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file.rawFile);
        });
    }
}

const processGetManyFilter = (url, params, resource) => {
    // debugger;
    let string = '';
    for (let i = 0; i < params['ids'].length; i++) {

        let param = params['ids'][i];
        if (param.length <= 0) {
            continue;
        }
        if (typeof parseInt(param) === "number") {
            if (string === '') {
                string += 'id[]=' + param;
            } else {
                string += '&id[]=' + param;
            }
        } else {
            let temp = param.replace('/' + resource + '/', '');
            let values = temp.split('/');
            if (values.length === 2) {
                if (string === '') {
                    string += values[0] + '[]=' + values[1];
                } else {
                    string += '&' + values[0] + '[]=' + values[1];
                }
            }
        }
    }
    if (string.length > 0) {
        if (url.indexOf('?') > -1) {
            url += '&' + string;
        } else {
            url += '?' + string;
        }
    }
    return url;
}

export default adminUserDataProvider;