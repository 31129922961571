import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, {useContext, useEffect, useState} from "react";
import {
    List,
    TextField,
    ReferenceField,
    DateField,
    SearchInput,
    WithRecord,
    Labeled,
    Datagrid,
} from 'react-admin';
import {
    Button,
} from "@mui/material";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import { SubscriptionListProductColumn } from "./components/SubscriptionListProductColumn";
import { SubscriptionMainDialogEdit } from "../Edits/Dialog/SubscriptionMainDialogEdit";
import {useGetProductPlanOptions} from "../Edits/components/hooks/EditSubscriptionModalHooks";
import {useGetProductPlan} from "./components/hooks/SubscriptionListColumnHooks";

const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];

export const DashboardSubscriptionList = (prop) => {
    const context = useContext(AdminUserContext);
    const [productPlans, setProductPlans] = useState([]);

    useEffect(() => {
        (async () => {
                let plans =  await useGetProductPlanOptions();
                if (plans && plans.status === 'success') {
                    setProductPlans(plans)
                }
            }

        )();
    }, []);

    return (
        <List
            title=" "
            perPage={25}
            actions={ false }
            resource="sales_subscriptions"
            filters={ customerFilters }
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid
                bulkActionButtons={false}
            >
                <TextField source="id" />
                <ReferenceField label="Customer" source="customer" reference="customer_entities" sortable={false}>
                    <TextField source="full_name" />
                </ReferenceField>
                <ReferenceField
                    label="Customer Email"
                    source="customer"
                    reference="customer_entities"
                    link={ false }
                    sortable={false}>
                    <TextField source="email_address" />
                </ReferenceField>
                <DateField source="next_cycle_date"  label="Next Order Date"/>
                <WithRecord label="Plan" render={
                    record => {
                        return (
                            <SubscriptionListProductColumn record={record} />
                        );
                    }
                } />
                <TextField source="stripeFrequency" label="Frequency" />
                <WithRecord label="Subscription Status" render={
                    record => {
                        let colour = '#000';
                        if (record.state === 'paused') {
                            colour = '#f36907';
                        }
                        if (record.state === 'active') {
                            colour = '#419d06';
                        }
                        if (record.resumes && record.state == 'paused') {
                            return <span style={ {
                                fontsize: '20px',
                                fontWeight: 'bold',
                                background: '#74ee15',
                                padding: '10px',
                                color: '#000',
                                borderRadius: '10px'
                            } } >{ "Processing...." }</span>
                        }

                        return (
                            <TextField label="Subscription Status"
                                       source="state"
                                       sx={ { textTransform: 'capitalize', color: colour } } />
                        );
                    }
                } />

                <ReferenceField label="Discount" source="coupons" reference="sales_coupons" perPage={ 50 }>
                    <TextField source="coupon_code" />
                </ReferenceField>
                <Labeled
                    label="Retry Failed Payment"
                    sx={
                        {
                        '& .RaLabeled-label': { display: 'none' },
                        }
                    } >
                    <Button
                        variant="contained"
                        disabled>
                        <span>{'Retry Payment'}</span>
                    </Button>
                </Labeled>
                <Labeled
                    label="More Info"
                    sx={
                        {
                            '& .RaLabeled-label': { display: 'none' },
                        }
                    } >
                    {/*<EditButton label="View / Edit" />*/}
                    <SubscriptionMainDialogEdit plans={productPlans} />
                </Labeled>
            </Datagrid>
        </List>
    );
};