import React, { useState } from 'react';
import {
   WithRecord,
    Datagrid,
    TextField,
    ReferenceField,
    List,
    Show,
    DateField,
    Labeled,
    SearchInput,
    ReferenceManyField,
    Pagination
} from 'react-admin';
import { SubscriptionListProductColumn } from "../../../Lists/components/SubscriptionListProductColumn";
import { Button } from "@mui/material";
import { SubscriptionMainDialogEdit } from "../../Dialog/SubscriptionMainDialogEdit";
import EditIcon from "@mui/icons-material/Edit";

const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];

const sectionTitleStyles = {
    paddingLeft: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '16px',
    display: 'block'
};

export const CustomerSubscriptions = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [activeRecord, setActiveRecord] = useState();

    function handleDialogOpen(data) {
        setActiveRecord(data);
        setOpenDialog(true);
    }

    function handleDialogClose() {
        setActiveRecord(undefined);
        setOpenDialog(false);
    }

    return (
        <Show title=" " actions={false}>
            <span style={ sectionTitleStyles }>{"Subscriptions"}</span>
            <ReferenceManyField
                sx={{padding: "0 16px"}}
                title=" "
                actions={false}
                perPage={5}
                disableSyncWithLocation
                reference="sales_subscriptions"
                target="id"
                source="subscriptions"
                pagination={ <Pagination /> }
            >
                <Datagrid
                    bulkActionButtons={false}
                >
                    <TextField source="id"/>

                    <ReferenceField label="Customer"
                                    source="customer"
                                    reference="customer_entities"
                                    sortable={false}
                                    link={false}
                    >
                        <TextField source="full_name"/>
                    </ReferenceField>

                    <ReferenceField
                        label="Customer Email"
                        source="customer"
                        reference="customer_entities"
                        link={false}
                        sortable={false}>
                        <TextField source="email_address"/>
                    </ReferenceField>

                    <DateField source="next_cycle_date" label="Next Order Date"/>

                    <WithRecord label="Plan" render={
                        record => {
                            return (
                                <SubscriptionListProductColumn record={ record } />
                            );
                        }
                    }/>

                    <WithRecord label="Frequency" render={
                        record => {
                            let frequency = record.stripeIntervalCount + ' ' + record.stripeInterval;

                            return (
                                <span>{frequency}</span>
                            );
                        }
                    }/>

                    <WithRecord label="Subscription Status" render={
                        record => {
                            let colour = '#000';
                            if (record.state === 'paused') {
                                colour = '#f36907';
                            }
                            if (record.state === 'active') {
                                colour = '#419d06';
                            }
                            if (record.state === 'cancelled') {
                                colour = '#ef1b04';
                            }

                            return (
                                <TextField label="Subscription Status" source="state"
                                           sx={ { textTransform: 'capitalize', color: colour } } />
                            );
                        }
                    }/>

                    <ReferenceField label="Discount" source="coupons" reference="sales_coupons"
                                    perPage={50}>
                        <TextField source="coupon_code"/>
                    </ReferenceField>

                    <Labeled
                        label="Retry Failed Payment"
                        sx={
                            {
                                '& .RaLabeled-label': {display: 'none'},
                            }
                        }>
                        <Button
                            variant="contained"
                            disabled>
                            <span>{'Retry'}</span>
                        </Button>
                    </Labeled>

                    <Labeled
                        label="Edit"
                        sx={
                            {
                                '& .RaLabeled-label': {display: 'none'},
                            }
                        }>
                        <WithRecord label=" " render={
                            record => {
                                return (
                                    <Button onClick={ () => handleDialogOpen(record) }>
                                        <EditIcon />
                                        { "View" }
                                    </Button>
                                );
                            }
                        } />
                    </Labeled>
                </Datagrid>
            </ReferenceManyField>

            { (openDialog === true && activeRecord !== undefined) &&
                <SubscriptionMainDialogEdit
                    record={ activeRecord }
                    openDialog={ openDialog }
                    handleDialogClose={ handleDialogClose } />
            }
        </Show>
    );
}