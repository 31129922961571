import React, { useState } from 'react';
import {
    AutocompleteArrayInput,
    BooleanField,
    Confirm,
    DateField,
    ReferenceField,
    SaveButton,
    SelectField,
    SelectInput,
    SimpleForm,
    TextField,
    Toolbar,
    useDelete,
    usePermissions,
    useRecordContext,
    useUpdate,
    WithRecord,
    useRefresh
} from 'react-admin';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    Stack,
    Switch
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useStripeDataSync } from "./hooks/DeveloperActionHooks";

const updateControlStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #eee'
};

const rotateIconStyles = {
    transform: 'rotate(360deg)',
    transition: 'rotate 1s linear infinite',
};

export const SubscriptionModalUpdateControl = (props) => {
    const record = useRecordContext();
    const [updateAll, setUpdateAll] = useState(false);
    const [updateNextCycleDate, setUpdateNextCycleDate] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [updateFrequency, setUpdateFrequency] = useState(false);
    const [updateProducts, setUpdateProducts] = useState(false);
    const [updateDiscount, setUpdateDiscount] = useState(false);
    const [updateSchedulerStatus, setUpdateSchedulerStatus] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const refresh = useRefresh();

    function handleUpdateAll() {
        setUpdateAll(!updateAll);
    }

    function handleUpdateNextCycleDate() {
        setUpdateNextCycleDate(!updateNextCycleDate);
    }

    function handleUpdateStatus() {
        setUpdateStatus(!updateStatus);
    }

    function handleUpdateFrequency() {
        setUpdateFrequency(!updateFrequency);
    }

    function handleUpdateProducts() {
        setUpdateProducts(!updateProducts);
    }

    function handleUpdateDiscount() {
        setUpdateDiscount(!updateDiscount);
    }

    function handleUpdateSchedulerStatus() {
        setUpdateSchedulerStatus(!updateSchedulerStatus);
    }

    function handleSubscriptionSyncClick() {
        setConfirmOpen(true);
    }

    const handleConfirm = async () => {
        setSyncing(true);
        setConfirmOpen(false);
        if (!checkValid()) {
            setSyncing(false);
            return;
        }
        let data = {
            all: updateAll,
            cycle: updateNextCycleDate,
            status: updateStatus,
            frequency: updateFrequency,
            products: updateProducts,
            discount: updateDiscount,
            scheduler: updateSchedulerStatus,
            subscription: record.id
        }

        const response = await useStripeDataSync( { data: data } );
        if (response.status === 'success') {
            setSuccessMessage('Subscription Sync Completed Successfully')
        } else {
            setErrorMessage(response.message);
        }
        setSyncing(false);
        refresh();
    }

    function checkValid() {
        let valid = true;
        if (updateAll === false &&
            updateNextCycleDate === false &&
            updateStatus === false &&
            updateFrequency === false &&
            updateProducts === false &&
            updateDiscount === false &&
            updateSchedulerStatus === false
        ) {
            valid = false;
            setErrorMessage('Please select an option');
        }
        return valid;
    }

    function handleDialogClose() {
        setConfirmOpen(false);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Stack spacing={2}>
                    <Box>
                        <span>{ "Sync Subscription Data" }</span>
                    </Box>
                    <Box>
                        <Stack spacing={2}>
                            <Box sx={ {
                                ...updateControlStyles,
                                border: '1px solid #ed6c02'
                            } } >
                                <span>{ "Update All" }</span>
                                <Switch
                                    label="All"
                                    disabled={ syncing }
                                    onChange={handleUpdateAll}
                                />
                            </Box>
                            <Stack spacing={2}>
                                <Box sx={ updateControlStyles }>
                                    <span>{ "Next Cycle Date" }</span>
                                    <Switch
                                        disabled={ (updateAll || syncing) }
                                        onChange={handleUpdateNextCycleDate}
                                    />
                                </Box>
                                <Box sx={ updateControlStyles }>
                                    <span>{ "Status" }</span>
                                    <Switch
                                        disabled={ (updateAll || syncing) }
                                        onChange={handleUpdateStatus}
                                    />
                                </Box>
                                <Box sx={ updateControlStyles }>
                                    <span>{ "Frequency" }</span>
                                    <Switch
                                        disabled={ (updateAll || syncing) }
                                        onChange={handleUpdateFrequency}
                                    />
                                </Box>
                                <Box sx={ updateControlStyles }>
                                    <span>{ "Products" }</span>
                                    <Switch
                                        disabled={ (updateAll || syncing) }
                                        onChange={handleUpdateProducts}
                                    />
                                </Box>
                                <Box sx={ updateControlStyles }>
                                    <span>{ "Discounts" }</span>
                                    <Switch
                                        disabled={ (updateAll || syncing) }
                                        onChange={handleUpdateDiscount}
                                    />
                                </Box>
                                <Box sx={ updateControlStyles }>
                                    <span>{ "Scheduler Status" }</span>
                                    <Switch
                                        disabled={ (updateAll || syncing) }
                                        onChange={handleUpdateSchedulerStatus}
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box display="flex">
                        <Button title="Sync"
                                onClick={ handleSubscriptionSyncClick }
                                variant="contained"
                                color="success"
                                disabled={ syncing }
                                >
                                { !syncing &&
                                    <SyncIcon />
                                }
                                { syncing &&

                                    <CircularProgress sx={ { width: '24px !important', height: '24px !important' } } />
                                }

                            <span style={ { marginLeft: '10px' } }>{ "Sync" }</span>
                        </Button>
                        <Confirm isOpen={confirmOpen}
                                 title="Confirm Data Sync"
                                 content="Syncing data will permenantly update the Barbershop database, are you sure?"
                                 onConfirm={ handleConfirm }
                                 onClose={ handleDialogClose }
                        />
                        { errorMessage !== null &&
                            <span style={ {
                                color: 'red',
                                marginLeft: '10px',
                                paddingTop: '10px'
                            } } >{ errorMessage }</span>
                        }
                        { successMessage !== null &&
                            <span style={{
                                color: 'green',
                                marginLeft: '10px',
                                paddingTop: '10px'
                            }}>{ successMessage }</span>
                        }
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={6}>

            </Grid>
        </Grid>
    );
}